import { Component } from '@angular/core';

@Component({
  selector: 'app-download-pdfbutton',
  templateUrl: './download-pdfbutton.component.html',
  styleUrls: ['./download-pdfbutton.component.scss']
})
export class DownloadPDFButtonComponent {

}
