<!-- <app-edite-user-details></app-edite-user-details> -->
<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Ticket'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Ticket'"></app-sidebar>
    <!-- end sidebar  -->

    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
        <div class="d-md-flex justify-content-between dashboard mt-2">
            <!-- <p class="fw-bold ms-1" style="color: #6c6a6a;">workers : {{numberworkers}}</p> -->
            <div class="d-flex justify-content-lg-between ">
                <div class="d-flex justify-content-between ">
                    <!-- <button class="month rounded-start px-3 py-1 outline-none">This Month</button>
              <button class="week rounded-end px-3 py-1 outline-none">Last Week</button> -->
                </div>
            </div>
        </div>

        <p-toast></p-toast>
        <div class="card mt-2">
            <p class="fw-bold ms-1" style="color: #000000;margin-top: 22px;margin-left: 25px !important;font-size: 18px;">Assign ticket to employee</p>

            <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="workers">
                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData">
                        <ng-container>


                            <th class="td-name">
                                Name

                            </th>
                            <th class="td-name">
                                Role

                            </th>
                            <th class="td-name">
                                Email

                            </th>
                            <th class="td-name">
                                Phone number

                            </th>
                            <th class="td-name">
                                WhatsApp

                            </th>



                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-workers>

                    <tr style="cursor: pointer;" (click)="hidecard()">


                        <td>
                            <label>
                          <input type="radio" name="color" (change)="handleChange($event,workers.user_ID)"    >

                        </label> {{workers?.admin_Name}}
                        </td>
                        <td>
                            {{workers?.admin_Role}}
                        </td>
                        <td>
                            {{workers?.admin_Email}}
                        </td>

                        <td>
                            {{workers?.admin_Phone}}
                        </td>
                        <td>
                            {{workers?.admin_WA}}
                        </td>



                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>

            </p-table>
            <p-paginator (onPageChange)="tiggerPageChange($event)" [rows]="rows"
            [totalRecords]="totalRecords" [first]="first"></p-paginator>


            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between" style="margin: 20px;">
                        <a (click)="gotopage() " style="border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
              Cancel
</a>
                        <button (click)="AssignWorker() " class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
              confirm
</button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>
        </div>
    </main>
    <!-- end content page  -->
</div>
