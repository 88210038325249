<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'inquiries'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'inquiries'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
        <div class="fw-inquiries d-flex justify-content-between" style="color: #6c6a6a;margin-top: 8px; margin-bottom: 16px;">
            <p class="fw-bold ms-1" style="color: #6c6a6a;">inquiries</p>
            <div class="d-flex justify-content-lg-between  ">
                <!-- <app-dropdown class="ms-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown> -->

                <app-dropdown class="me-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown>
                <div class="d-flex mb-2  ">
                    <button class="week rounded-start px-3 py-2 bg-w outline-none" (click)="FilterButtons('This Month')"
                        [ngClass]="{'activeButton': monthButton == true}">This Month</button>
                    <button class="week px-3 py-2 outline-none rounded-end" (click)="FilterButtons('Last Week')"
                        [ngClass]="{'activeButton': weekButton == true}">Last Week</button>
                </div>
            </div>
            <!-- start add button (add new apartment) in page  -->
            <!-- end add button (add new apartment) in page  -->

        </div>

        <!-- start part 1 in page  -->

        <p-toast></p-toast>

        <!-- end part 1 in page  -->
        <!-- start part 2 in page  -->

        <div class="card">
            <div class="row d-block d-md-flex justify-content-between align-items-center my-2  p-3">
                <!-- start apartmentLists button  -->
                <!-- <div class="d-flex align-items-center " style="overflow: auto;    font-size: 15px;">
                    <div *ngFor="let data of InquireFillterLists;let index=index;">
                        <div class="d-flex ">
                            <button class="apartmentList border-0 bg-transparent pb-2 mx-2 buttomstyle" (click)="clickIquires(index)" [class.clickApartmentList]="InquireFillterSelected[index]==true">{{ data }}</button>
                            <div class="none">
                                <img src="assets/images/line.svg" [class.d-none]=" index == InquireFillterLists.length-1">
                            </div>

                        </div>

                    </div>

                </div> -->
                <div class="col-md-4">
                    <div class="d-flex align-items-center" style="font-size: 15px;">
                        <label for="" style="color: #313331;font-weight: 500;font-size: 16px;">Inquiries</label>
                        <img src="assets/images/line.svg" class="mx-3">
                        <select class="form-select" (change)="clickIquires($event)">
                            <option *ngFor="let data of InquireFillterLists; let index=index;" [value]="data.id"
                                [selected]="InquireFillterSelected[index]">{{ data.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- end apartmentLists button  -->
                <!-- start dropdown button  -->
                <div class="col-md-5 col-8">
                    <div class="input">
                        <input type="text" class="form-control py-2" placeholder=" Search by Inquires" (keyup)="searchAction($event)"
                            [(ngModel)]="searchText" />
                        <img src="../../../assets/images/search.svg" alt="">
                    </div>
                </div>
            </div>

            <!-- <p-table #dt [value]="Inquires" [(selection)]="Inquires" dataKey="id" [filterDelay]="0" /> -->


            <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}"
                [value]="Inquires">
                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData">
                        <ng-container>

                            <!-- <th class="td-name">
                                Request Code

                            </th> -->
                            <th class="td-name">
                                Inquiry Id

                            </th>
                            <th class="td-name">
                                User name

                            </th>

                            <th class="td-name">
                                Requested Appartment

                            </th>
                            <th class="td-name">
                                Contact number

                            </th>
                            <th class="td-name">
                                Time period
                                <img src="./assets/images/arrow_downward.svg" alt="">

                            </th>
                            <th class="td-name">
                                Status
                                <img src="./assets/images/arrow_downward.svg" alt="">

                            </th>

                            <th class="td-name">

                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-inquire>

                    <tr style="cursor: pointer;" (click)="hidecard(inquire.request_ID)">
                        <!-- <td routerLink="/view-inquire/{{ inquire.request_ID }}">

                            {{ inquire?.request_Code }}
                        </td> -->
                        <td routerLink="/inquiries/view-inquire/{{ inquire.booking_ID }}">
                            {{ inquire?.booking_Code }}
                        </td>
                        <td class="d-flex align-items-center">

                            <img *ngIf=" inquire.profileImg  " routerLink="/users/app-edite-user-details/{{ inquire.user_ID }}"
                                [src]="inquire?.profileImg"
                                style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px;cursor: pointer;">


                            <img *ngIf="!inquire.profileImg" routerLink="/users/app-edite-user-details/{{ inquire.user_ID }}"
                                src="../../../assets/images/Frame 1261154899.svg"
                                style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px;cursor: pointer;">
                            {{ inquire?.requested_By_Name }}

                        </td>

                        <td routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">
                            {{ inquire?.request_Apartment }}
                        </td>
                        <td routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">
                            {{ inquire?.contact_No }}
                        </td>
                        <td routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">
                            {{ inquire?.rent_Duration }}
                        </td>

                        <td routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">
                            <div>
                                <span class="rounded-5 px-3 badge Completed" *ngIf="inquire?.status=='Completed'">
                                    <span>
                                        <img src="assets/images/greenDot.svg" alt="">
                                    </span>
                                    {{ inquire?.status }}
                                </span>
                                <span class="rounded-5 px-3 badge Approved" *ngIf="inquire?.status=='Approved'">
                                    <span>
                                        <img src="assets/images/greenDot.svg" alt="">
                                    </span>
                                    {{ inquire?.status }}
                                </span>
                                <span class="rounded-5 px-3 badge Cancelled" *ngIf="inquire?.status=='Cancelled'">
                                    <span>
                                        <img src="assets/images/redDot.svg" alt="">
                                    </span>
                                    {{ inquire?.status }}
                                </span>
                                <span class="rounded-5 px-3 badge Pending" *ngIf="inquire?.status=='Pending'">
                                    <span>
                                        <img src="assets/images/yallowDot.svg" alt="">
                                    </span>
                                    {{ inquire?.status }}
                                </span>
                                <span class="rounded-5 px-3  badge Rejected" *ngIf="inquire?.status=='Rejected'">
                                    <span>
                                        <img src="assets/images/purbleDot.svg" alt="">
                                    </span>
                                    {{ inquire?.status }}
                                </span>
                                <span class="rounded-5 px-3  badge Waiting" *ngIf="inquire?.status=='Waiting'">
                                    <span>
                                        <img src="assets/images/blueDot.svg" alt="">
                                    </span>
                                    {{ inquire?.status }}
                                </span>
                            </div>
                        </td>
                        <td class="cursor position-relative" style="text-align: center;">
                            <button class="border-0 outlin-0 bg-transparent" (click)="detailperson( $event,inquire.request_ID)">
                                <img src="assets/images/edit.svg">
                            </button>
                            <div *ngIf="showEdit[inquire.request_ID] &&checkindex!=1 && inquire?.status!='Approved' && inquire?.status!='Cancelled' && inquire?.status!='Waiting' && inquire?.status!='Rejected'"
                                class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100"
                                    routerLink="/inquiries/inquire-offer/{{ inquire.request_ID }}">Send Offer </button>
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100"
                                    routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">View inquire</button>

                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                                    (click)="AddWaitingList(inquire.request_ID)">Add to waiting list </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                                    (click)="onOpenModal1(inquire.request_ID)">Reject </button>

                            </div>
                            <div *ngIf="showEdit[inquire.request_ID] &&checkindex!=1  && inquire?.status=='Waiting'"
                                class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100"
                                    routerLink="/inquiries/inquire-offer/{{ inquire.request_ID }}">Send Offer </button>
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100"
                                    routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">View inquire</button>

                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                                    (click)="onOpenModal1(inquire.request_ID)">Reject </button>

                            </div>
                            <div *ngIf="showEdit[inquire.request_ID] &&checkindex!=1 && inquire?.status=='Approved'  "
                                class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100"
                                    routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">View inquire</button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                                    (click)="onOpenModal1(inquire.request_ID)">Reject </button>


                            </div>
                            <div *ngIf="showEdit[inquire.request_ID] &&checkindex!=1   && inquire?.status=='Cancelled'"
                                class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100"
                                    routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">View inquire</button>


                            </div>
                            <div *ngIf="showEdit[inquire.request_ID] &&checkindex!=1  && inquire?.status=='Rejected'"
                                class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100"
                                    routerLink="/inquiries/view-inquire/{{ inquire.request_ID }}">View inquire</button>

                            </div>
                        </td>

                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>

            </p-table>
            <p-paginator *ngIf="Inquires.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
              [totalRecords]="totalRecords" [first]="first"></p-paginator>

            <!-- <div style="width: 100%; height: 72px; padding-top: 12px; padding-bottom: 16px; padding-left: 24px; padding-right: 24px;   justify-content: space-between; align-items: center; display: inline-flex">
                <div style="border-radius: 4px; justify-content: flex-start; align-items: flex-start; gap: 10px; display: flex">

                    <button (click)="decreamentPage( )" [disabled]="disableperv" class="  cvxs" style="text-align: center; color: #1F4068; font-size: 16px; font-family: 'Montserrat'; font-weight: 500; line-height: 20px; word-wrap: break-word">
                      Previous
        </button>
                </div>
                <div style="color: #484649; font-size: 14px; font-family: 'Montserrat'; font-weight: 400; line-height: 20px; word-wrap: break-word">Page {{ pageNumber }} of {{ totalofPages }}</div>
                <div style="border-radius: 4px; justify-content: flex-start; align-items: flex-start; gap: 10px; display: flex">

                    <button [disabled]="disablenext" (click)="   incrementpage( )" class="  cvxs" style="text-align: center; color: #1F4068; font-size: 16px; font-family: 'Montserrat'; font-weight: 500; line-height: 20px; word-wrap: break-word">
                          Next
            </button>
                </div>
            </div> -->

        </div>
    </main>
</div>

<div class="modal " tabindex="-1 " role="dialog " [ngStyle]="{ 'display':display1} " style=" background-color: rgb(23 22 22 / 50%);; ">
    <div class="modal-dialog " role="document ">
        <div class="modal-content " style=" margin-top: 150px; ">
            <div class="modal-header " style="position: relative;background-color:#ffffff; border-bottom: none !important; ">
                <p class="modal-title "
                    style="color: #1C1B1F; font-size: 20px; font-weight: 600; line-height: 24px; word-wrap: break-word ">
                    <span class="form-label gray fw-bold " style="vertical-align: middle; padding-left:8px ">
                        Reject inquire
                    </span>
                </p>
                <button type="button " style="border:none;background-color: #ffffff;width: 68px;height: 50px; " class="close "
                    aria-label="Close " (click)="onCloseModal1() ">
                    <span aria-hidden="true " style="color: #000000;font-size: 35px; ">&times;</span>
                </button>


            </div>

            <div class="modal-body ">


                <div class="row ">
                    <div class="mb-3 col-12 col-md-12 ">
                        <label for="exampleInputPricepermonth " class="form-label gray fw-bold ">Reason</label>
                        <textarea class="border p-3 pt-3 rounded-2 w-100 " rows="4 " style="resize: inherit "
                            placeholder="please enter reason of rejection " [(ngModel)]="Reason "></textarea>

                    </div>
                    <!-- <div class="mb-3 col-12 col-md-12 ">
                      <label for="exampleInputSecuritydeposit " class="form-label gray fw-bold ">Role discerption</label>
                      <textarea class="border p-3 pt-3 rounded-2 w-100 " rows="5 " style="resize: inherit "></textarea>

                  </div> -->
                </div>

            </div>


            <div class="modal-footer ">

                <div class="d-flex justify-content-between ">
                    <div class="py-2 px-3 fw-bold "></div>
                    <div class="d-flex justify-content-between ">
                        <a (click)="onCloseModal1() " style=" border: 1px solid; "
                            class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd ">
                            Cancel
                        </a>
                        <button (click)=" CancelRequest( ) " class="rounded-2 text-white rounded-2 border-0 py-2 px-4 "
                            style="background-color: #1f4068 !important ">
                            confirm
                        </button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
