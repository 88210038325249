<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Owners'"></app-head>
</header>
<!-- end header  -->

<p-toast></p-toast>

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'owners'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10">
        <div class="d-md-flex justify-content-between">
            <div class="d-flex">

                <p class="fw-bold me-2" style="color: #6c6a6a"><a style="color: black" class="removeUnderLine" href="./owners">Owners </a>
                </p>
                <p class="fw-bold me-2">></p>
                <p class="fw-bold" style="color: black">{{ param }}</p>
            </div>
            <div *ngIf="param != 'edit owner name details'">
                <!-- <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold" style="background-color: #EBEBEB; color:#1F4068">
          <img src="assets/images/user/share.png"> Share</button> -->
                <!-- <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold" style="background-color: #EBEBEB; color:#1F4068">
          <img src="assets/images/user/delete.svg"> Delete</button>
                <button class="outline-0 border-0 rounded-3 py-2 px-3 text-white fw-bold" style="background-color:#1F4068"><img
            src="assets/images/user/edit.svg"> Edit</button> -->
            </div>
        </div>

        <div class="mt-3 row border bg-white rounded-3 py-3 ">
            <aside class="col-2 p-0 ps-1 position-fixed d-none d-md-block">
                <a class="d-block text-decoration-none fw-bold px-2 py-3 text-black mb-1 active"
                    *ngFor="let listAnchor of listAnchors ; let index=index" style="color: #605D62 !important; cursor: pointer;"
                    (click)="onClick(listAnchor.id)" [class.active]="link[index]==true"
                    (click)="changeAnchor(index)">{{ listAnchor.link }}</a>
            </aside>

            <form class="col-md-10 content-details " [formGroup]="createOwner" (ngSubmit)="CreateOwner(createOwner)">

                <!--General info-->
                <app-general-info-user [title]="subTitle" [titlePage]="param" [imageUrl]="createOwner.value.owner_Photo"
                    (changeImageUrl)="updateUserImage($event)"></app-general-info-user>
                <!--start General info form-->
                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">* First name</label>
                        <input formControlName='owner_FirstName' class="form-control w-100" type="text">
                        <div class="text-danger mt-3 error d-flex align-items-center"
                        *ngIf="createOwner.get('owner_FirstName')?.getError('pattern')"  >
                        <i class="fa-solid fa-ban text-danger mx-2"></i>
                        <p class="mb-0 text-danger">First name is not valid</p>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fw-bold" style="color: #605D62">* Last name</label>
                        <input formControlName='owner_LastName' class="form-control w-100" type="text">
                        <div class="text-danger mt-3 error d-flex align-items-center"
                        *ngIf="createOwner.get('owner_LastName')?.getError('pattern')"  >
                        <i class="fa-solid fa-ban text-danger mx-2"></i>
                        <p class="mb-0 text-danger"> Last name is not valid</p>
                      </div>
                    </div>

                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">* Email</label>
                    <img src="assets/images/user/mail.svg" class="position-relative top-icon-input" style="right: 50px;">
                    <input formControlName='owner_Mail' class="form-control w-100" type="email" style="padding-left: 30px;">
                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">* Address</label>
                    <input formControlName='owner_Address' class="form-control w-100" type="text">
                    <div class="text-danger mt-3 error d-flex align-items-center"
                    *ngIf="createOwner.get('owner_Address')?.getError('pattern')"  >
                    <i class="fa-solid fa-ban text-danger mx-2"></i>
                    <p class="mb-0 text-danger">Address is not valid</p>
                  </div>
                </div>
                <div>
                    <label class="fw-bold" style="color: #605D62;">* About</label>
                    <textarea formControlName='owner_About' class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"></textarea>
                    <div class="text-danger mt-3 error d-flex align-items-center"
                    *ngIf="createOwner.get('owner_About')?.getError('pattern')"  >
                    <i class="fa-solid fa-ban text-danger mx-2"></i>
                    <p class="mb-0 text-danger">About is not valid</p>
                  </div>
                </div>

                <!--end General info form-->
                <hr>
                <!--Other Details-->
                <div id="OtherDetails">
                    <p class="fw-bold mb-0">Other Details</p>
                    <p style="color: #605D62 !important;">Enter other details.</p>
                </div>
                <!--start Other Details form-->
                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">* Date of birth</label>

                        <input formControlName='owner_DOB' class="form-control w-100 " type="date" [value]="formattedDate"
                            style="cursor: pointer;" [max]="getTodayDate()">

                        <input formControlName='owner_DOB' class="form-control w-100 " type="date"  [value]="formattedDate" style="cursor: pointer;"  [max]="getTodayDate()">
                       <!-- <div class="input-group">
                        <input class="form-control" placeholder="dd/mm/yyyy" ngbDatepicker #d="ngbDatepicker"
                          formControlName='owner_DOB' [value]="formattedDate" style="cursor: pointer;"  [max]="getTodayDate()"/>
                        <button  (click)="d.toggle()"
                          class="bi bi-calendar3 btn-date" type="button">
                        <img src="../../../../assets/images/date-picker.png" alt="" />
                        </button>
                      </div> -->

                    </div>

                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">* Country</label>

                        <div class="w-100">
                            <p-dropdown (onChange)="onCountrySelected($event)"

                                [options]="Countries"
                                [(ngModel)]="selectedCountry"
                                optionLabel="description"
                                [filter]="true"
                                filterBy="description"
                                [showClear]="true"
                                placeholder="Select a Country">
                                <ng-template pTemplate="selectedItem" let-selectedOption>
                                    <div class="flex align-items-center gap-2">

                                        <div>{{ selectedOption.name }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-country pTemplate="item">
                                    <div class="flex align-items-center gap-2">

                                        <div>{{ country.description }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>


                        <!--
                       <div class="w-100">
                          <p-dropdown [options]="cities" [(ngModel)]="selectedCity" [ngModelOptions]="{standalone: true}"
                              optionLabel="country"></p-dropdown>
                      </div>
                     -->
                    </div>

                </div>
                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">* Phone number</label>
                        <input formControlName='owner_Phone' class="form-control w-100" type="text">

                     <div class="text-danger mt-3 error d-flex align-items-center"
                     *ngIf="createOwner.get('owner_Phone')?.getError('pattern')"  >
                     <i class="fa-solid fa-ban text-danger mx-2"></i>
                     <p class="mb-0 text-danger"> Phone number is invalid </p>
                   </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fw-bold" style="color: #605D62">whatsapp number</label>
                        <input formControlName='owner_WA_Number' class="form-control w-100" type="number">
                    </div>

                </div>

                <!--end Other Details form-->
                <hr>
                <!--Owned apartments-->
                <ng-container *ngIf="param =='Owner details'">
                    <div>
                        <p class="fw-bold mb-0" id="Ownedapartments">Owned apartments</p>
                        <p style="color: #605D62 !important;">These apartment are owned by this owner</p>
                    </div>
                    <!--Cards of Owned apartments-->
                    <div class="row paroductApartment">

                        <div class="col-12 col-md-6 col-lg-4 mb-4 ">
                            <div class="position-relative">
                                <img src="assets/images/apartmentImages/img1.svg" class="w-100 h-100 ">
                                <p class="fw-bold position-absolute rentedORavailable rounded-pill top-0 m-3 py-1 px-3 "
                                    [class.available]="available">Available</p>
                            </div>
                            <div class="bg-white px-2 py-3 details border rounded-bottom">
                                <div class="fw-bold">Salt Lake City, Apartment Salt Lake City, Apartment </div>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-between">
                                        <img src="assets/images/apartmentImages/location.svg">
                                        <p class="fw-bold mt-3 ms-2">Alt-Moabit</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <img src="assets/images/apartmentImages/room_bed.svg">
                                        <p class="fw-bold mt-3 ms-2">3</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <img src="assets/images/apartmentImages/user.svg">
                                        <p class="fw-bold mt-3 ms-2">2</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div><img src="assets/images/Price.svg"></div>
                                    <div><img class="pointer" src="assets/images/more_vert_FILL.svg"></div>
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="position-relative">
                                <img src="assets/images/apartmentImages/img1.svg" class="w-100 h-100 ">
                                <p class="fw-bold position-absolute rentedORavailable rounded-pill top-0 m-3 py-1 px-3 rented">rented
                                </p>
                            </div>
                            <div class="bg-white px-2 py-3 details border rounded-bottom">
                                <div class="fw-bold">Salt Lake City, Apartment Salt Lake City, Apartment </div>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-between">
                                        <img src="assets/images/apartmentImages/location.svg">
                                        <p class="fw-bold mt-3 ms-2">Alt-Moabit</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <img src="assets/images/apartmentImages/room_bed.svg">
                                        <p class="fw-bold mt-3 ms-2">3</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <img src="assets/images/apartmentImages/user.svg">
                                        <p class="fw-bold mt-3 ms-2">2</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div><img src="assets/images/Price.svg"></div>
                                    <div><img class="pointer" src="assets/images/more_vert_FILL.svg"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </ng-container>

                <!--Bank details-->
                <div>
                    <div>

                        <p class="fw-bold mb-0" id="Bankdetails" *ngIf="param=='Owner details'">Bank details</p>
                        <p class="fw-bold mb-0" id="Bankdetails" *ngIf="param!='Owner details'">Bank account information</p>
                        <p style="color: #605D62 !important;">Enter the bank details.</p>
                    </div>
                    <div class="row ">
                        <div class="col-12 col-md-6 mb-4">
                            <label class="fw-bold" style="color: #605D62;">* Bank Name</label>
                            <input formControlName='owner_Bank' class="form-control w-100" type="text">
                            <div class="text-danger mt-3 error d-flex align-items-center"
                            *ngIf="createOwner.get('owner_Bank')?.getError('pattern')"  >
                            <i class="fa-solid fa-ban text-danger mx-2"></i>
                            <p class="mb-0 text-danger"> Bank Name is not valid </p>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="fw-bold" style="color: #605D62">* Account Number</label>
                            <input formControlName='owner_BankAccount' class="form-control w-100" type="text">
                            <div class="text-danger mt-3 error d-flex align-items-center"
                            *ngIf="createOwner.get('owner_BankAccount')?.getError('pattern')"  >
                            <i class="fa-solid fa-ban text-danger mx-2"></i>
                            <p class="mb-0 text-danger"> Account Number is not valid </p>
                          </div>
                        </div>

                    </div>
                    <div class="mb-4">
                        <label class="fw-bold" style="color: #605D62;">* Swift Number</label>
                        <input formControlName='owner_BankSwift' class="form-control w-100" type="text">
                        <div class="text-danger mt-3 error d-flex align-items-center"
                        *ngIf="createOwner.get('owner_BankSwift')?.getError('pattern')"  >
                        <i class="fa-solid fa-ban text-danger mx-2"></i>
                        <p class="mb-0 text-danger"> Swift Number is not valid </p>
                      </div>
                    </div>
                </div>

                <!--Payment history-->
                <div *ngIf="param=='Owner details'">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="fw-bold mb-0" id="Paymenthistory">Payment history</p>
                            <p style="color: #605D62 !important;">this section display for admins only</p>
                        </div>
                        <button class="outlet-0 bg-transparent downloadAll px-3 py-0 rounded-3"><img
                                src="assets/images/download.svg" alt=""> Download all</button>
                    </div>

                </div>

                <div class="d-flex justify-content-between" *ngIf="param!='Owner details'">

                    <button (click)=" gotopage2( )" class="outlet-0 bg-transparent rounded-3 px-4 py-2 fw-bold downloadAll">Cancel</button>
                    <p-button class="outlet-0 text-white border-0 px-4 py-2 rounded-3 sendDataPButton" label="Save"
                        [loading]="loadingButton" type="submit"></p-button>

                    <button (click)="cancleModal()" type="button" class="outlet-0 bg-transparent rounded-3 px-4 py-2 fw-bold downloadAll">Cancel</button>
                    <p-button class="outlet-0 text-white border-0 px-4 py-2 rounded-3 sendDataPButton" label="Save" [loading]="loadingButton" type="submit"></p-button>


                    <!-- <button class="outlet-0 text-white border-0 px-4 py-2 rounded-3" style="background-color: #1f4068;"
            type="submit">Save</button> -->
                </div>
                <!-- end user information form-->
                <!-- user images
          <div>
            <p class="fw-bold mb-0" style="color: #605D62;">user images</p>
            <div class="accordion-body mb-3">
              <img src="assets/images/user/File upload base.svg" class="me-2">
              <img src="assets/images/user/File upload base.svg" class="me-2">
              <img src="assets/images/user/File upload base.svg" class="me-2">
              <img src="assets/images/user/File upload base.svg" class="me-2">
            </div>
          </div>
   -->
            </form>
        </div>
    </main>
    <!-- end content page  -->
</div>


<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display4}">
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 400px;">
        <div class="modal-content">
              <div class="modal-header pb-0 mb-0 d-flex justify-content-end">
                <button type="button" class="close border-0" aria-label="Close" #closebutton (click)="onCloseHandled()">
                  <img src="../../../assets/images/close2.svg" alt="">
                </button>
                  </div>
           <div class="modal-body text-center my-0 py-0">
                  <span><img src="../../../assets/images/cancle.svg" alt=""></span>
                  <h5>Cancelation</h5>
                  <p class="text-muted ">Are you sure to Cancel  Owner creation?</p>
            </div>
                  <div class="modal-footer text-center mx-auto mt-0 pt-0 mb-2">
                    <button type="button" class="btn btn-delete mx-2" data-bs-dismiss="modal" (click)="onCloseHandled()">No</button>
                    <button type="button" class="btn btn-color mx-2" id="closeModal" (click)="gotopage2( )" >Yes</button>
                  </div>
     </div>
      </div>
</div>
