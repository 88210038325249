<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Issue Reports'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Issue Reports'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10">
        <p-toast></p-toast>

        <div class="d-md-flex justify-content-between">
            <div class="d-flex">
                <a class="fw-bold me-2" routerLink="/Issue_Reports" style="color: #6c6a6ac7;text-decoration: none;">Issue Reports</a>
                <p class="fw-bold me-2">></p>
                <p class="fw-bold" style="color: #6c6a6a">Reports View</p>
            </div>
            <div>
                <!-- <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold" style="background-color: #EBEBEB; color:#1F4068"><img src="assets/images/user/share.png"> Share</button> -->
                <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold " *ngIf="param != 'Edit Reports View'" style="background-color: #EBEBEB; color:#1F4068" routerLink="/Report-print/{{paramid}}"> <img src="assets/images/print.svg"> Print </button>
                <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold" style="background-color: #EBEBEB; color:#1F4068" *ngIf="param == 'Edit Reports View'"> <img src="assets/images/report.svg"> Edit </button>

                <button class="outline-0 border-0 rounded-3 py-2 px-3 text-white fw-bold" *ngIf="param == 'Edit Reports View'" style="background-color:#1F4068">
            <img src="assets/images/pdf.svg">  Generate PDF </button>
                <button class="outline-0 border-0 rounded-3 py-2 px-3 text-white fw-bold" *ngIf="param == 'Report_Details'" style="background-color:#1F4068">
            <img src="assets/images/Vector.svg">  Download PDF </button>
            </div>
        </div>

        <div class="mt-3 pt-4 row border bg-white rounded-3 py-3 ">
            <!-- <form [formGroup]="createissue" (ngSubmit)="createissuepost(createissue)"> -->

            <div class="col-md-12 content-details ">

                <div class="d-flex justify-content-between mb-4" *ngIf="param == 'Report_Details'">
                    <img src="assets/images/logo.svg" alt="">
                    <div>
                        <p class="mb-0">Customer ID: 00123456 </p>
                        <p class="mb-0">Prepared Date: 04/05/2023</p>
                        <p class="mb-0">Prepared Date: +123 236 236</p>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="image">
                    <img style="width: 169.17px; height: 72px" src="./assets/images/logo.svg" />
                  </div>
                   <div class="date">
                        <div class="phonein" style="color: #484649;">Prepared Date: {{detialIssue?.date_Reported|date: 'dd/MM/yyyy' }}</div>
                  <div>
                  </div>
                </div>
                </div>

                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Issue reports ID</label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.issue_Code" type="text">
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fw-bold" style="color: #605D62">Requested by</label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.requsted_By" type="text">
                    </div>

                </div>
                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Apartment number</label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.apartment_Number" type="text">
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fw-bold" style="color: #605D62">Name on the Ringbell</label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.name_RingBell" type="text">
                    </div>

                </div>
                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Phone Number</label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.phone_Number" type="number">
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fw-bold" style="color: #605D62">Alternative Phone Number</label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.phone_Number2" type="number">
                    </div>

                </div>
                <div class="col-12 mb-4">
                    <label class="fw-bold" style="color: #605D62;">Apartment address</label>
                    <input class="form-control w-100" [(ngModel)]="detialIssue.apt_Address" type="text">
                </div>
                <div class="row ">
                    <div class="col-12 col-md-4 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Floor no.</label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.apt_FloorNo" type="number">
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="fw-bold" style="color: #605D62">The location of the apartment on the floor</label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.side" type="text">
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="fw-bold" style="color: #605D62">Assigned To</label>
                      <input class="form-control w-100" [(ngModel)]="detialIssue.worker_name" type="text">
                  </div>
                </div>
                <!-- <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Worker Name</label>
                        <input readonly class="form-control w-100" [(ngModel)]="detialIssue.worker_name" type="text">

                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fw-bold" style="color: #605D62">cost by</label>
                        <input readonly class="form-control w-100" [(ngModel)]="detialIssue.costby" type="text">

                    </div>

                </div> -->
                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold form-label d-block mb-0" style="color: #605D62;">Date reported</label>
                        <!-- <p-calendar [(ngModel)]="detialIssue.date_Reported"></p-calendar> -->
                        <input class="form-control w-100" [(ngModel)]="detialIssue.date_Reported" type="text">

                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fw-bold" style="color: #605D62">Status</label>
                        <select style="font-size: 16PX;" class="s-cont form-control   form-select form-select-sm mb-3" name="issue_Status" id="issue_Status" [(ngModel)]="detialIssue.issue_Status">

                         <option   value="InProgress"> InProgress</option>
                         <option  value="Pending">Pending</option>
                         <option  value="Completed">Completed</option>
                         <option  value="Cancelled">Cancelled</option>

                    </select>
                        <!-- <input class="form-control w-100" [(ngModel)]="detialIssue.issue_Status" type="text"> -->
                    </div>

                </div>
                <div class="mb-3">
                    <label class="fw-bold" style="color: #605D62;">Descriptions</label>
                    <textarea class="border rounded-3 w-100 p-2" rows="5" [(ngModel)]="detialIssue.desc" style="resize: unset;" placeholder="Enter a description"></textarea>
                </div>
                <!-- <div class="mb-3">
                    <label class="fw-bold" style="color: #605D62;">Notes</label>
                    <textarea class="border rounded-3 w-100 p-2" rows="5" [(ngModel)]="detialIssue.notes" style="resize: unset;" placeholder="Enter a Note"></textarea>
                </div> -->

                <div>
                    <p class="fw-bold mb-0" style="color: #605D62;">Pictures</p>
                    <div class="accordion-body mb-3">
                        <img *ngFor="let item of detialIssue.issue_Images;let i=index" (click)="openmodel(item.img_Url)" style="width:270px;height: 200px;cursor: pointer;" [src]="item.img_Url" class="me-2">

                    </div>
                </div>
                <div id="OtherDetails" style="margin-top:20px">
                    <p class="fw-bold mb-0">Suggested dates</p>
                    <p style="color: #605D62 !important;">view all available appointments </p>
                </div>
                <div class="row ">

                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;"> selected Appointment    </label>
                        <input class="form-control w-100" [(ngModel)]="detialIssue.selected_Appointment" type="text">
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <button (click)="openmodel3()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important;margin-top: 21px;">
  Add New Appointement
</button>
                    </div>

                </div>
                <div class="row ">

                    <div class="col-12 col-md-6 mb-4" *ngFor="let item of detialIssue.appointement;let i=index">
                        <label class="fw-bold" style="color: #605D62;">appointement ( {{i+1}} ) </label>
                        <input class="form-control w-100" [ngModel]="item.appo_Date+' ' +item.appo_Time " type="text">
                    </div>


                </div>
                <div class="d-flex justify-content-end">
                    <div class="d-flex justify-content-between">
                        <button (click)="gotopage2()" class="backcancel bg-white rounded-2 py-2 px-3 fw-bold mx-2">
                  Cancel
                </button>
                        <button routerLink="/Issue_Reports/assgin-issue/{{detialIssue.issue_ID}}" class="backcancel bg-white rounded-2 py-2 px-3 fw-bold mx-2">
                  assign worker

                 </button>
                 <button routerLink="/Issue_Reports/assgin-issue-owner/{{detialIssue.issue_ID}}" class="backcancel bg-white rounded-2 py-2 px-3 fw-bold mx-2">
                  Assign Owner

                 </button>
                 <button (click)="routeToMessage(detialIssue)" class="backcancel bg-white rounded-2 py-2 px-3 fw-bold mx-2">
                    Chat User

                   </button>
                 <button   class="backcancel bg-white rounded-2 py-2 px-3 fw-bold mx-2" (click)="OpenModal1(detialIssue.issue_ID) " *ngIf="detialIssue.worker_name!=''">
                  Mark in Progress

                 </button>
                        <button (click)="submitForm()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4 mx-2" style="background-color: #1f4068 !important">
                  Update
                </button>
                    </div>
                </div>
                <p class="gray text-center" *ngIf="param == 'Report_Details'">
                    his document is confidential and intended for the recipient only. Any unauthorized distribution or reproduction is prohibited. his document is confidential and intended for the recipient only. Any unauthorized distribution or reproduction is prohibited.
                    his document is confidential and intended for the recipient only
                </p>


                <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}" style=" background-color: rgb(23 22 22 / 50%);;">
                  <div class="modal-dialog" role="document">
                      <div class="modal-content" style="    margin-top: 150px;">
                          <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                              <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                                  <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                                  Mark it as In-Progress
                            </span>
                              </p>
                              <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal1()">
                <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
              </button>



                          </div>

                          <div class="modal-body">


                              <div id="Bankdetails">
                                  <!-- <p class="fw-bold mb-0"> Bank account information</p> -->
                                  <p style="color: #605D62 !important;">Please Select an Appointment to go and solve this problem </p>
                              </div>
                              <!--start Other Details form-->

                              <div class="row ">

                                  <div class="col-12 col-md-9 mb-4 cardss" *ngFor="let item of appointement;let i=index">
                                      <input type="radio" name="drone2" (change)="handleChange(item)">

                                      <label class="fw-bold" style="color: #605D62; margin-left: 10px;">appointement ( {{i+1}} )
                                        <br>
                                        {{item.appo_Date}} {{item.appo_Time}} </label>
                                  </div>
                              </div>

                          </div>




                          <div class="modal-footer">

                              <div class="d-flex justify-content-between">
                                  <div class="py-2 px-3 fw-bold"></div>
                                  <div class="d-flex justify-content-between">
                                      <a (click)="onCloseModal1()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                                      Cancel
                        </a>
                                      <button (click)="  MarkasProgress( )" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important" >
                                      confirm
                        </button>
                                  </div>
                              </div>
                              <!-- Confirm -->

                          </div>


                      </div>
                      <!-- /.modal-content -->
                  </div>
                  <!-- /.modal-dialog -->
              </div>
            </div>
        </div>

            <!-- Start Solve Desc-->
          <div class="mt-4 row border bg-white rounded-3 py-3 " *ngIf="detialIssue.issue_Status=='Completed'">
            <div id="OtherDetails" style="margin-top:20px" >
              <span class="fw-bold">Fix Report Results</span>
              <p class="gray">a biref overview about solved the problem</p>

              <div class="row ">
                <div class="col-12 col-md-3 mb-4">
                    <label class="fw-bold text-center" style="color: #605D62;">Who will Pay</label>
                    <input readonly class="form-control w-100 mt-1" [(ngModel)]="detialIssue.costby" type="text">

                    <!-- <input class="form-control w-100" [(ngModel)]="detialIssue.apt_FloorNo" type="number"> -->
                </div>
                <div class="col-12 col-md-3">
                    <label class="fw-bold text-center" style="color: #605D62">Worker Cost</label>
                    <input readonly class="form-control w-100 mt-1" [(ngModel)]="detialIssue.worker_Cost" type="text">

                    <!-- <input class="form-control w-100" [(ngModel)]="detialIssue.side" type="text"> -->
                </div>
                <div class="col-12 col-md-3">
                  <label class="fw-bold text-center" style="color: #605D62">Items Cost</label>
                  <input readonly class="form-control w-100 mt-1" [(ngModel)]="detialIssue.item_Cost" type="text">

                  <!-- <input class="form-control w-100" [(ngModel)]="detialIssue.side" type="text"> -->
              </div>
              <div class="col-12 col-md-3">
                <label class="fw-bold text-center" style="color: #605D62">Total Cost</label>
                <input readonly class="form-control w-100 mt-1" [(ngModel)]="detialIssue.issue_TotalCost" type="text">

                <!-- <input class="form-control w-100" [(ngModel)]="detialIssue.side" type="text"> -->
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label class="fw-bold" style="color: #605D62">Fix Description</label>

                <textarea class="border rounded-3 w-100 mt-1 p-2 " rows="5" [(ngModel)]="detialIssue.solve_Desc" style="resize: unset;" placeholder="Enter a description"></textarea>

              </div>
            <div class="row mt-2" *ngIf="detialIssue.fileType!=null">
              <label class="fw-bold" style="color: #605D62">Attachments</label>
              <div class="accordion-body mb-3">
                <img *ngIf="detialIssue.fileType!='.pdf'" (click)="openmodel(detialIssue.fileUrl)" style="width: 100px;cursor: pointer;" [src]="detialIssue.fileUrl" class="me-2">

            </div>
            <div class="col-4 rounded-3 ms-3 my-2 py-2" style="border: 1px solid #8fa0b4">
              <div class="row">
                <div class="col-2 d-flex justify-content-center">
                  <img width="48px" *ngIf="detialIssue.fileType=='.pdf'" src="assets/images/pdf-svgrepo-com.svg" />
                  <img width="48px" *ngIf="detialIssue.fileType!='.pdf'" src="assets/images/jpg-svgrepo-com.svg" />

                </div>
                <div class="col-8">
                  <h6>{{ detialIssue.fileName }}</h6>
                  <span>{{ detialIssue.fileSize }}</span>
              </div>
              <div class="col-2 d-flex align-items-center justify-content-center">
                  <div class="">
                      <!-- <a class="me-3 cursor" routerLink="/apartments/contract/create-owner-contract"><img src="assets/images/apartmentImages/edit1.svg" /></a> -->
                      <a class="me-3 cursor" *ngIf="detialIssue?.fileUrl" target="_blank" [href]="detialIssue?.fileUrl"><img width="32px" src="assets/images/download-minimalistic-svgrepo-com.svg" /></a>

                  </div>
              </div>
              </div>
            </div>

            </div>
            </div>
            </div>

            </div>

            <!-- End Solve Desc-->
            <!-- </form> -->
        </div>
    </main>
    <!-- end content page  -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display22}" style="background-color: rgba(90,90,90,0.5);">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document" style="width:900px;">
        <div class="modal-content position-relative">
          <div class="modal-header p-0 m-0 d-flex justify-content-start">
            <button type="button" class="close" aria-label="Close" (click)="oncloseModal()">
              <img src="../../../../assets/images/ic_round-close.svg" alt="">
            </button>
              </div>
            <div class="modal-body p-0 m-0">
                <img [src]="imageSize" style="width:100%;height: 500px" />

            </div>

            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-white bbtclose" (click)="oncloseModal()">
close
</button>

            </div> -->


        </div>
    </div>
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display3}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                new appointment
          </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal3()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div id="Bankdetails">
                    <!-- <p class="fw-bold mb-0"> Bank account information</p> -->
                    <p style="color: #605D62 !important;">create new appointment to go and solve this problem </p>
                </div>
                <!--start Other Details form-->

                <div class="row ">

                    <div class="mb-3 col-12 col-md-12">
                        <label class="form-label gray fw-bold"> date</label>
                        <!-- <p-calendar [(ngModel)]="Apointment3"></p-calendar> -->
                        <p-calendar [(ngModel)]="Apointment3" [showTime]="true" dateFormat="dd/mm/yy" [showSeconds]="true"></p-calendar>

                    </div>

                </div>

            </div>




            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between">
                        <a (click)="onCloseModal3()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                    Cancel
      </a>
                        <button (click)="  MarkasProgress2( )" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                    confirm
      </button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>


