<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Apartments'"></app-head>
</header>
<!-- end header  -->


<div class="row m-0 appartmentOverTest">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'apartments'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">

        <!--start navigate add new apartments-->
        <div class="d-flex">
            <p class="me-4 fw-bold gray">
                <!-- <a style="color: black" class="removeUnderLine" href="./apartments">Apartments </a> -->
                <a href="javascript:void(0)" routerLink="/apartments" class="removeUnderLine">
                    <p class="fw-bold ml-8">Apartments</p>
                </a>
            </p>
            <p class="fw-bold me-4">></p>
            <p class="fw-bold"> {{ param }}</p>
        </div>
        <!--end navigate add new apartments-->
        <div class="card p-4 stepper">
           <div class="w-75">
            <p-steps [model]="items" (Responsive)="true" [(activeIndex)]="activeIndex" [readonly]="true" (onChange)="onStepChange($event)"></p-steps>

           </div>



           <!--////////////////////////////////-->


           <!-- <div class="w-75 custom-step">
            <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false">
              <ng-template pTemplate="item" let-item let-i="index">
                <div class="Frame1597884028">
                  <div class="Frame">
                    <div class="custom-icon">
                      <div class="Vector"></div>
                    </div>
                  </div>
                  <div class="Yes" [ngClass]="{'ui-state-active': activeIndex === i, 'No': activeIndex !== i}">
                    {{item.label}}
                  </div>
                </div>
              </ng-template>
            </p-steps>
          </div> -->


        <div class="pt-4">
      <!--start form of general info-->
        <app-first-step *ngIf="activeIndex == 0" (jumbToNextSteb)="changeItem(1)" (getId)="id($event)"
        [addApartment]="param" [id]="UUID"></app-first-step>
    <!--end form of general info-->

    <!--start form of Other Details-->
    <app-second-step [n_ofbedRoom]="n_ofbedRoom" [n_ofLiving]="n_ofLiving" [id]="UUID" [addApartment]="param"
        [n_ofToilets]="apt_Toilets" *ngIf="activeIndex == 1" (jumbToNextSteb)="changeItem(2)"
        (jumbToPrevSteb)="changeItem(0)"></app-second-step>
    <!--end form of Other Details-->
    <app-third-step [id]="UUID" *ngIf="activeIndex == 2" (jumbToNextSteb)="changeItem(3)" (jumbToPrevSteb)="changeItem(1)"
        [addApartment]="param">
      </app-third-step>

    <!--start form of Backup information-->
    <app-forth-step [id]="UUID" *ngIf="activeIndex == 3" (jumbToNextSteb)="changeItem(0)" (jumbToPrevSteb)="changeItem(2)"
        [addApartment]="param">
    </app-forth-step>
    <!--end form of Backup information-->
            </div>
        </div>


    </main>
</div>
