<!-- <app-edite-user-details></app-edite-user-details> -->
<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'checkOut'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'checkOut'"></app-sidebar>
    <!-- end sidebar  -->

    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
        <div class="d-md-flex justify-content-between" style="margin-top: 6px; margin-bottom: 24px;">
            <div class="d-flex" style="align-items: center;">
                <p class="fw-bold me-2" style="color: #6c6a6a;margin-bottom: 0px;"><a style="color: #6c6a6a;" class="removeUnderLine"
                        routerLink="/inquiries">Inquiries </a></p>

                <p class="fw-bold me-2" style="margin-bottom: 0px;color: #605D62;">></p>
                <p class="fw-bold" style="color: #6c6a6a;margin-bottom: 0px;">Ready Checkout </p>
            </div>
            <div class="d-flex justify-content-lg-between ">
                <div class="d-flex justify-content-between ">

                </div>
                <app-dropdown [list]="listDropDown" style="margin-top: 2px;" (selected)="selectedfromDropDown($event)"></app-dropdown>

            </div>
        </div>


        <p-toast></p-toast>
        <div class="card">
            <div class="row d-block d-md-flex justify-content-between my-2 p-3">
                <!-- <div class="d-flex align-items-center " style="overflow: auto;    font-size: 15px;">
              <div *ngFor="let data of InquireFillterLists;let index=index;">
                  <div class="d-flex ">
                      <button class="apartmentList border-0 bg-transparent pb-2 mx-2 buttomstyle" (click)="clickIquires(index)" [class.clickApartmentList]="InquireFillterSelected[index]==true">{{ data }}</button>
                      <div class="none">
                          <img src="assets/images/line.svg" [class.d-none]=" index == InquireFillterLists.length-1">
                      </div>

                  </div>

              </div>

          </div> -->
                <div class="col-md-4">
                    <div class="d-flex align-items-center" style="font-size: 15px;">
                        <label for="" style="color: #313331;font-weight: 500;font-size: 16px;">CheckOut</label>
                        <img src="assets/images/line.svg" class="mx-3">
                        <select class="form-select" (change)="clickIquires($event)">
                            <option *ngFor="let data of InquireFillterLists; let index=index;" [value]="data.id"
                                [selected]="InquireFillterSelected[index]">{{ data.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-5 col-8">
                    <div class="input">
                        <input type="text" class="form-control py-2" placeholder=" Search by Inquires" (keyup)="searchAction($event)"
                            [(ngModel)]="searchText" />
                        <img src="../../../assets/images/search.svg" alt="">
                    </div>
                </div>


            </div>
            <!-- <p-table #dt [value]="partners" [(selection)]="partners" dataKey="id" [filterDelay]="0" /> -->

            <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}"
                [value]="checkOut">
                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData">
                        <ng-container>


                            <th class="td-name">
                                booking ID

                            </th>

                            <th class="td-name">
                                User name

                            </th>

                            <th class="td-name">
                                Requested apartment

                            </th>
                            <th class="td-name">
                                deposit

                            </th>
                            <th class="td-name">
                                start date

                            </th>
                            <th class="td-name">
                                end date

                            </th>
                            <th class="td-name">
                                Status

                            </th>

                            <th class="td-name">
                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-check>

                    <tr (click)="hidecard()" style="cursor: pointer;">
                        <td routerLink="/checkout-inquire/checkout/{{ check.req_ID }}">{{ check.req_Code }}</td>

                        <td routerLink="/checkout-inquire/checkout/{{ check.req_ID }}">
                            <img *ngIf="check.user_Photo" [src]="check?.user_Photo"
                                style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px; cursor: pointer;">
                            <img *ngIf="!check.user_Photo" src="../../../assets/images/Frame 1261154899.svg"
                                style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px;cursor: pointer;">
                            {{ check?.user_Name }}

                        </td>
                        <td routerLink="/checkout-inquire/checkout/{{ check.req_ID }}">
                            {{ check?.rented_Apt }}
                        </td>
                        <td routerLink="/checkout-inquire/checkout/{{ check.req_ID }}">
                            {{ check?.rent_Deposit }}
                        </td>

                        <td routerLink="/checkout-inquire/checkout/{{ check.req_ID }}">
                            {{ check?.start_Date }}
                        </td>
                        <td routerLink="/checkout-inquire/checkout/{{ check.req_ID }}">
                            {{ check?.end_Date }}
                        </td>

                        <td routerLink="/checkout-inquire/checkout/{{ check.req_ID }}">
                            <span class="spanready" *ngIf="check.status=='Ready'">
                                <img src="../../../assets/images/ready.svg">
                                {{ check.status }}</span>
                            <span class="spanready" style="background-color: #ECFDF3;" *ngIf="check.status=='Completed'">
                                <img src="../../../assets/images/comp.svg">
                                {{ check.status }}</span>
                            <span class="spanready" style="background-color: #FFF4ED;" *ngIf="check.status=='End Soon'">
                                <img src="../../../assets/images/end.svg">
                                {{ check.status }}</span>

                        </td>


                        <td class="cursor position-relative" style="text-align: center;">
                            <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,check.req_ID)">
                                <img src="assets/images/edit.svg">
                            </button>
                            <div *ngIf="showEdit[check.req_ID] " style="width: 191px;z-index: 999;right: 98px;"
                                class="position-absolute z-3 rounded-3 bg-white border edit-button">

                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                                    routerLink="/checkout-inquire/checkout/{{ check.req_ID }}"> View Details </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                                    (click)="GetBankDetails(check.req_ID)"> Show Bank Details </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                                    (click)="GetRefundDetails(check.req_ID)"> Refund Process </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                                    [disabled]="check.status!=''" (click)="InsertCheckOut(check.req_ID)"> Send Checkout Sheet </button>


                            </div>

                        </td>

                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>

            </p-table>
            <p-paginator *ngIf="checkOut.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
              [totalRecords]="totalRecords" [first]="first"></p-paginator>

            <!-- <div style="width: 100%; height: 72px; padding-top: 12px; padding-bottom: 16px; padding-left: 24px; padding-right: 24px;   justify-content: space-between; align-items: center; display: inline-flex">
        <div style="border-radius: 4px; justify-content: flex-start; align-items: flex-start; gap: 10px; display: flex">

            <button (click)="decreamentPage( )" [disabled]="disableperv" class="  cvxs" style="text-align: center; color: #1F4068; font-size: 16px; font-family: 'Montserrat'; font-weight: 500; line-height: 20px; word-wrap: break-word">
          Previous
</button>
        </div>
        <div style="color: #484649; font-size: 14px; font-family: 'Montserrat'; font-weight: 400; line-height: 20px; word-wrap: break-word">Page {{ pageNumber }} of {{ totalofPages }}</div>
        <div style="border-radius: 4px; justify-content: flex-start; align-items: flex-start; gap: 10px; display: flex">

            <button [disabled]="disablenext" (click)="   incrementpage( )" class="  cvxs" style="text-align: center; color: #1F4068; font-size: 16px; font-family: 'Montserrat'; font-weight: 500; line-height: 20px; word-wrap: break-word">
              Next
</button>
        </div>
    </div> -->
        </div>
    </main>
    <!-- end content page  -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document" style="max-width: 416px;">
        <div class="modal-content" style="margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;    padding-bottom: 0px;position: relative;">
                <p class="modal-title"
                    style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                    </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close"
                    aria-label="Close" (click)="onCloseModal1()">
                    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;padding-top:0px">
                <img src="../../../assets/images/confirmm.png">


                <p style="margin-bottom: 0px;font-weight: 500;">sent successfully!</p>
                <p style="margin-bottom: 0px;"><b>check out sheet </b>has been sent successfully</p>
                <button (click)="onCloseModal1()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4 " style="background-color: #1f4068 !important ;    margin-top: 15px;
  margin-bottom: 15px;">
                    ← Back
                </button>
            </div>

        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title"
                    style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                        Bank Details of Refunding Process
                    </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close"
                    aria-label="Close" (click)="onCloseModal2()">
                    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
                </button>


            </div>

            <div class="modal-body">


                <div id="Bankdetails">
                    <!-- <p class="fw-bold mb-0"> Bank account information</p> -->
                    <p style="color: #605D62 !important;">This is Bank Details of Tenant , Necessary for Refunding Process</p>
                </div>
                <!--start Other Details form-->

                <div name="bankdetails">
                    <div class="row " *ngFor="let item of BankDetails">

                        <div class="col-12  mb-4">
                            <label class="fw-bold" style="color: #605D62;">Account Name</label>
                            <br>

                            <input readonly disabled value="{{ item.account_Name }}" class="form-control w-100 bg-white" type="text">
                        </div>
                        <div class="col-12  mb-4">
                            <label class="fw-bold" style="color: #605D62;">Account Iban </label>
                            <input readonly disabled value="{{ item.account_Iban }}" class="form-control w-100 bg-white" type="text">
                        </div>
                        <div class="col-12  mb-4">
                            <label class="fw-bold" style="color: #605D62;">Account No</label>
                            <input readonly disabled value="{{ item.account_No }}" class="form-control w-100 bg-white" type="text">
                        </div>
                        <div class="col-12  mb-4">
                            <label class="fw-bold" style="color: #605D62;">Account Swift</label>
                            <input readonly disabled value="{{ item.account_Swift }}" class="form-control w-100 bg-white" type="text">
                        </div>
                    </div>

                </div>
            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display3}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title"
                    style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                        Refunding Process Details
                    </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close"
                    aria-label="Close" (click)="onCloseModal3()">
                    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
                </button>


            </div>

            <div class="modal-body">


                <div id="Bankdetails">
                    <!-- <p class="fw-bold mb-0"> Bank account information</p> -->
                    <p style="color: #605D62 !important;">Information of Refunding Process</p>
                </div>
                <!--start Other Details form-->

                <div name="bankdetails">
                    <div class="row ">

                        <div class="col-6  mb-4">
                            <label class="fw-bold" style="color: #605D62;">Invoice Amount</label>
                            <br>

                            <input readonly disabled value="{{ RefundDetails?.refund_Amount }}" class="form-control w-100 bg-white"
                                type="number">
                        </div>
                        <div class="col-6  mb-4">
                            <label class="fw-bold" style="color: #605D62;">Invoice Refund Status</label>
                            <input readonly disabled value="{{ RefundDetails?.refund_Paid }}" class="form-control w-100 bg-white"
                                type="text">
                        </div>
                        <div class="col-12  mb-4">
                            <label class="fw-bold" style="color: #605D62;">Refund Transaction ID </label>
                            <input [readOnly]="RefundDetails?.refund_Paid=='Paid'"
                                value="{{ RefundDetails?.refund_Transaction_ID }}" class="form-control w-100 bg-white"
                                type="text" (keypress)="ChangeRefund_ID($event)">
                        </div>

                        <div class="mb-3 col-12 col-md-12">
                            <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Attachments</label>
                            <div class="row mt-2" *ngIf="RefundDetails?.refund_Attachment!=null">

                                <div class="accordion-body">
                                    <img *ngIf="Is_PDF==false" (click)="openmodel(RefundDetails?.refund_Attachment)"
                                        style="width: 100px;cursor: pointer;" [src]="RefundDetails?.refund_Attachment" class="me-2">

                                </div>
                                <div class="col-11 rounded-3   py-2 ms-3" style="border: 1px solid #8fa0b4">
                                    <div class="row">
                                        <div class="col-2 d-flex justify-content-center">
                                            <img width="48px" *ngIf="Is_PDF==true" src="assets/images/pdf-svgrepo-com.svg" />
                                            <img width="48px" *ngIf="Is_PDF==false" src="assets/images/jpg-svgrepo-com.svg" />

                                        </div>
                                        <div class="col-8">
                                            <h6>Refund Transaction Receipt</h6>

                                        </div>
                                        <div class="col-2 d-flex align-items-center justify-content-center">
                                            <div class="">
                                                <!-- <a class="me-3 cursor" routerLink="/apartments/contract/create-owner-contract"><img src="assets/images/apartmentImages/edit1.svg" /></a> -->
                                                <a class="me-3 cursor" *ngIf="RefundDetails?.refund_Attachment" target="_blank"
                                                    [href]="RefundDetails?.refund_Attachment"><img width="32px"
                                                        src="assets/images/download-minimalistic-svgrepo-com.svg" /></a>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="image-upload mainimage" *ngIf="RefundDetails?.refund_Attachment==null">
                                <input type="file" multiple="multiple" (change)="onUploadContract($event,'contract_Path')">
                                <div class="image-uploads">
                                    <div class="d-flex align-items-center justify-content-center text-center h-100">
                                        <div *ngIf="!selectedContractImg" class="text-center imageData pt-2">
                                            <div class="icon m-auto">
                                                <img src="/assets/images/upload 1.svg" alt="img">
                                            </div>
                                            <p class="mb-0">Click to upload <span class="text-muted fw-normal"> or drag and drop</span></p>
                                            <p class="text-muted fw-normal">PNG, JPG, GIF, or MP4 (max. 8MB)</p>
                                        </div>
                                        <ng-container *ngIf="selectedContractImg">
                                            <a (click)="opencloseModal(selectedContractImg.url)">
                                                <img *ngIf="selectedContractImg.url" [src]="selectedContractImg.url" alt="img"
                                                    style="height: 124px;width:150px;">
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer" *ngIf="RefundDetails?.refund_Paid!='Paid'">

                <button class="rounded-2 text-white rounded-2 border-0 py-2 px-4"
                    style="background-color: #1f4068 !important"
                    (click)="PostRefunding()">
                    Confirm Refund
                </button>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display33}" style="background-color: rgba(90,90,90,0.5);">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document" style="width:900px;">
        <div class="modal-content position-relative">
            <div class="modal-header p-0 m-0 d-flex justify-content-start" style="position: relative;">
                <button type="button" class="close" aria-label="Close" (click)="oncloseModal()">
                    <img src="../../../../assets/images/ic_round-close.svg" alt="">
                </button>
            </div>
            <div class="modal-body p-0 m-0">
                <img [src]="imageSize" style="width:100%;height: 500px" />

            </div>

            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-white bbtclose" (click)="oncloseModal()">
close
</button>

          </div> -->


        </div>
    </div>
</div>
