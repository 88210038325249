<!-- <app-edite-user-details></app-edite-user-details> -->


<!-- start content page  -->
<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'FAQ'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'FAQ'"></app-sidebar>
    <!-- end sidebar  -->

    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
        <!-- <div class="row">
            <p-breadcrumb class="max-w-full" [model]="[{label:'FAQ'}]" [home]="home"></p-breadcrumb>

        </div> -->
        <div class="fw-inquiries d-flex justify-content-between" style="color: #6c6a6a;">
            <p class="fw-bold ms-1" style="color: #6c6a6a;">FAQ</p>
            <div class="d-flex justify-content-lg-between  ">

                <!-- start dropdown button  -->
                <!-- <app-dropdown class="me-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown> -->
                <!-- end dropdown button  -->
                <!-- start add button (add new owner) in page  -->
                <!-- <app-add-new-button [id]="'/owner/create_new'" class="mt-2"></app-add-new-button> -->

                <!-- end add button (add new owner) in page  -->
            </div>
            <!-- start add button (add new apartment) in page  -->
            <!-- end add button (add new apartment) in page  -->

        </div>
        <p-toast></p-toast>
        <div class="card mt-2">
            <div class="d-flex justify-content-between align-items-center ps-1 pe-3 pt-1" style="margin-bottom: 12px;">
              <!-- length Of person -->
              <div class="d-flex mx-2 mt-4">
                  <p class="me-2 fs-5 fw-bold ml-8">FAQ : {{numberFAQ}}</p>
                  <!-- <p class="mb-3 bg-gray rounded-5 blue px-2 d-flex align-items-center">
          {{fullRespone?.totalRecords}} {{pagePerson}}
      </p> -->
              </div>
              <!-- search input -->
              <div class="d-flex align-items-center mx-2 mt-2">
                  <a class="px-3 py-2 rounded border text-white text-decoration-none marmodify" style="background-color:#1F4068; cursor: pointer;" (click)="openmodelcreatefaq()">
                      <img src="assets/images/plus.svg" class="me-2 ">Add New
                  </a>
              </div>
          </div>
            <!-- <p-table #dt1 [value]="FAQ" [(selection)]="selectppdd" dataKey="id1" [filterDelay]="0" /> -->



            <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="FAQ">
                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData">
                        <ng-container>

                            <th class="td-name">
                                Question title

                            </th>
                            <th class="td-name">
                                question answer


                            </th>

                            <th class="td-name">


                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fqq>

                    <tr style="cursor: pointer;" (click)="hidecard()">


                        <td>
                            {{fqq?.faq_Quest}}
                        </td>
                         <td>
                          <p style="width: 40vw; overflow: hidden; text-overflow: ellipsis;
                          white-space: nowrap;"> {{fqq?.faq_Ans}}
                          </p>
                         </td>

                        <td class="cursor position-relative" style="text-align: center;">
                            <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,fqq.faq_ID)">
    <img src="assets/images/edit.svg">
</button>
                            <div *ngIf="showEdit[fqq.faq_ID] " class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100" (click)="openmodel2updatefaq(fqq)">update</button>

                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="deleteModal(fqq.faq_ID)">Delete </button>

                            </div>

                        </td>

                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>

            </p-table>
            <p-paginator *ngIf="FAQ.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
              [totalRecords]="totalRecords" [first]="first"></p-paginator>

            <!-- <div style="width: 100%; height: 72px; padding-top: 12px; padding-bottom: 16px; padding-left: 24px; padding-right: 24px;   justify-content: space-between; align-items: center; display: inline-flex">
        <div style="border-radius: 4px; justify-content: flex-start; align-items: flex-start; gap: 10px; display: flex">

            <button (click)="decreamentPage( )" [disabled]="disableperv" class="  cvxs" style="text-align: center; color: #1F4068; font-size: 16px; font-family: 'Montserrat'; font-weight: 500; line-height: 20px; word-wrap: break-word">
          Previous
    </button>
        </div>
        <div style="color: #484649; font-size: 14px; font-family: 'Montserrat'; font-weight: 400; line-height: 20px; word-wrap: break-word">Page {{pageNumber}} of {{totalofPages}}</div>
        <div style="border-radius: 4px; justify-content: flex-start; align-items: flex-start; gap: 10px; display: flex">

            <button [disabled]="disablenext" (click)="   incrementpage( )" class="  cvxs" style="text-align: center; color: #1F4068; font-size: 16px; font-family: 'Montserrat'; font-weight: 500; line-height: 20px; word-wrap: break-word">
              Next
    </button>
        </div>
    </div> -->
        </div>
    </main>
    <!-- end content page  -->
</div>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="position: relative;background-color:#ffffff; border-bottom: none !important;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                Add FAQ
        </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal1()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Question title</label>
                        <input type="text" [(ngModel)]="Question_title" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">question answer</label>
                        <textarea class="border p-3 pt-3 rounded-2 w-100" [(ngModel)]="question_answer" rows="5" style="resize: inherit"></textarea>

                    </div>
                </div>

            </div>




            <div class="modal-footer">

                <div class="d-flex justify-content-between w-100 px-2">
                        <a (click)="onCloseModal1()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold  xcvddmodel">
                  Cancel
    </a>
                        <button (click)="CreateFAQ()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                  confirm
    </button>
                    </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}" >
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="position: relative;background-color:#ffffff; border-bottom: none !important;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                Update FAQ
        </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal2()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Question title</label>
                        <input type="text" [(ngModel)]="Question_title_update" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">question answer</label>
                        <textarea class="border p-3 pt-3 rounded-2 w-100" [(ngModel)]="question_answer_update" rows="5" style="resize: inherit"></textarea>

                    </div>
                </div>

            </div>




            <div class="modal-footer">

              <div class="d-flex justify-content-between w-100 px-2">
                        <a (click)="onCloseModal2()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold xcvddmodel">
                  Cancel
    </a>
                        <button (click)="UpdateFAQ()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                  confirm
    </button>
                    </div>

                <!-- Confirm -->


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display3}">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="position: relative;background-color:#ffffff; border-bottom: none !important;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                add new advertisement
      </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal3()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div class="row">

                    <label class="form-label gray fw-bold"> Upload picture
            </label>
                    <div class="position-relative h-124px mb-3">

                        <input type="file" accept="image/*" (change)="selectFile($event)" class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                        <img src="assets/images/apartmentImages/_File upload base.svg" class="position-absolute" width="907%">

                    </div>

                </div>
                <div class="row" *ngIf="urls != null">
                    <div class="col-md-2 card33">
                        <a (click)="removeItem()" style="border: none;   margin-bottom: 10px; position: absolute;">
                            <img class="imageRemove" src="../../../../../../assets/images/delete.svg" width="32px" height="32px;">
                        </a>
                        <img [src]="urls" alt="Avatar" style="width:350px;height:205px ; ">

                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">link</label>
                        <input type="text" [(ngModel)]="link_create_ads" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">button name</label>
                        <input type="text" [(ngModel)]="button_name" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>
                </div>
            </div>




            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between">
                        <a (click)="onCloseModal3()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvddmodel">
                Cancel
  </a>
                        <button (click)="CreateAds()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                confirm
  </button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display4}">
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 400px;">
        <div class="modal-content">
              <div class="modal-header pb-0 mb-0 d-flex justify-content-end">
                <button type="button" class="close border-0" aria-label="Close" #closebutton (click)="onCloseHandled()">
                  <img src="../../../assets/images/close2.svg" alt="">
                </button>
                  </div>
           <div class="modal-body text-center my-0 py-0">
                  <span><img src="../../../assets/images/delete2.svg" alt=""></span>
                  <h5>Confirm Deletion</h5>
                  <p class="text-muted ">Are you want to delete the FAQ? </p>
            </div>
                  <div class="modal-footer text-center mx-auto mt-0 pt-0 mb-2">
                    <button type="button" class="btn btn-delete mx-2" data-bs-dismiss="modal" (click)="onCloseHandled()">Cancel</button>
                    <button type="button" class="btn btn-color mx-2" id="closeModal" (click)="DeleteFAQ()" >Delete</button>
                  </div>
     </div>
      </div>
</div>
