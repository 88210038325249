<!-- <app-edite-user-details></app-edite-user-details> -->
<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'App Config '"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'App Config '"></app-sidebar>
    <!-- end sidebar  -->

    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
        <!-- <div class="row">
            <p-breadcrumb class="max-w-full" [model]="[{label:'App Configuration'}]" [home]="home"></p-breadcrumb>

        </div> -->
        <div class="fw-inquiries d-flex justify-content-between" style="color: #6c6a6a;">
            <p class="fw-bold ms-1" style="color: #6c6a6a;">App Configuration</p>
            <div class="d-flex justify-content-lg-between  ">

                <!-- start dropdown button  -->
                <!-- <app-dropdown class="me-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown> -->
                <!-- end dropdown button  -->
                <!-- start add button (add new owner) in page  -->
                <!-- <app-add-new-button [id]="'/owner/create_new'" class="mt-2"></app-add-new-button> -->

                <!-- end add button (add new owner) in page  -->
            </div>
            <!-- start add button (add new apartment) in page  -->
            <!-- end add button (add new apartment) in page  -->

        </div>
        <p-toast></p-toast>

        <!--General info-->
        <!--start General info form-->
        <div class="card mt-2">

            <div class="row paroductApartment  px-1">
                <div class="bg-white p-12 xpp12">
                    <div style="margin-bottom: 24px;">
                        <h5 class="fw-bold mb-0">App Configurations</h5>
                    </div>


                    <div class="row" style="margin-bottom: 20px;">
                        <div class="mb-3 col-12 col-md-6">

                            <label for="exampleInputPricepermonth" class="form-label gray  "> The allowed period to pay rest of security deposit(days) </label>
                            <input [(ngModel)]="detailconfig.service_Fees" type="number" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />
                        </div>
                        <div class="mb-3 col-12 col-md-6">
                            <label for="exampleInputSecuritydeposit" class="form-label gray  "> The allowed period to pay part of security deposit(hours)</label>
                            <input [(ngModel)]="detailconfig.security_hours" type="number" class=" form-control rounded-2 py-2 fs-6" id="exampleInputSecuritydeposit" />

                        </div>

                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div>
                            <label style="color: #605D62;">Terms and Condition EN</label>
                            <textarea [(ngModel)]="detailconfig.terms_En" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"></textarea>
                        </div>

                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div>
                            <label style="color: #605D62;">Terms and Condition DE</label>
                            <textarea [(ngModel)]="detailconfig.terms_De" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"></textarea>
                        </div>

                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div>
                            <label style="color: #605D62;">Privacy EN</label>
                            <textarea [(ngModel)]="detailconfig.privacy_En" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"></textarea>
                        </div>

                    </div>
                    <!-- [(ngModel)]="contract_details.rC_EndRent" -->

                    <div class="row" style="margin-bottom: 20px;">
                        <div>
                            <label style="color: #605D62;">Privacy DE</label>
                            <textarea [(ngModel)]="detailconfig.privacy_De" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"></textarea>
                        </div>

                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div>
                            <label style="color: #605D62;">Payment Terms EN</label>
                            <textarea [(ngModel)]="detailconfig.paymentTerms_En" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"></textarea>
                        </div>

                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div>
                            <label style="color: #605D62;">Payment Terms EN DE</label>
                            <textarea [(ngModel)]="detailconfig.paymentTerms_De" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"></textarea>
                        </div>

                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div>
                            <label class="fw-bold" style="color: #605D62;">General rules</label>
                            <textarea [(ngModel)]="detailconfig.general_Rules" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"></textarea>
                        </div>

                    </div>
                    <div class="div-4">
                        <div class="div-5 d-flex justify-content-end">

                            <div class="div-6">
                                <!-- <button class="auto-layout-button"><div class="label-2">Cancel</div></button> -->


                                <button (click)="AddConfig()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                                Save
                  </button>
                                <!-- <button class="label-wrapper"><div class="label-3">save</div>
                    </button> -->
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </main>
    <!-- end content page  -->
</div>
