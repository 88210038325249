<div class="accordion accordion-flush mb-5" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingOne">
        <button class="accordion-button ps-0  collapsed text-black fw-bold bg-transparent border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            Public transport
        </button>
      </h2>
      <div id="flush-collapseOne" class="accordion-collapse collapse mt-3" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
       <div class="mb-3">
        <p class="fw-bold" style="color: #605D62 !important;">Transport within 270 m</p>
        <div class="form-control w-100 fs-6 pt-2">1 bus</div>
       </div>
       <div class="mb-3">
        <p class="fw-bold " style="color: #605D62 !important;">Transport within 520 m</p>
        <div class="form-control w- fs-6 pt-2">Tram 2</div>
       </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingTwo">
        <button class="accordion-button collapsed ps-0  text-black fw-bold bg-transparent border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
          Passport of tenant
        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse collapse mt-3" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body mb-3"> 
          <img src="assets/images/user/File upload base.svg" class="me-2">
          <img src="assets/images/user/File upload base.svg" class="me-2">
          <img src="assets/images/user/File upload base.svg" class="me-2">
          <img src="assets/images/user/File upload base.svg" class="me-2">
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingthree">
        <button class="accordion-button ps-0 collapsed text-black fw-bold bg-transparent border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
          Other details
        </button>
      </h2>
      <div id="flush-collapsethree" class="accordion-collapse mt-3 collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
       <div class="mb-3">
        <p class="fw-bold" style="color: #605D62 !important;">Property square feet</p>
        <div class="form-control w-100 fs-6 pt-2">2356 sq ft</div>
       </div>
       <div class="mb-3">
        <p class="fw-bold " style="color: #605D62 !important;">Laundry type</p>
        <div class="form-control w- fs-6 pt-2">Laundry in building</div>
       </div>
       <div class="mb-3">
        <p class="fw-bold " style="color: #605D62 !important;">Date available</p>
        <div class="form-control w- fs-6 pt-2">Jun 2, 2023 - July 2, 2023</div>
       </div>
      </div>
    </div>
    
</div>