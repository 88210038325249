<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'View Inqueirs'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'View Inqueirs'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10">
        <!-- start part 1 in page  -->
        <!-- <div class="row" style="color: #6c6a6a">
            <p-breadcrumb class="max-w-full" [model]="gfg" [home]="home"></p-breadcrumb>

        </div> -->
        <div class="d-md-flex justify-content-between dashboard mt-2">
          <div class="d-flex">
              <p routerLink="/inquiries" class="fw-bold me-2" style="color: #6c6a6a; cursor: pointer;">inquiries</p>
              <img src="assets/images/user/right-arrow.svg" class="me-2" style="margin-top: -15px">
              <p class="fw-bold" style="color: #6c6a6a">View inquiries</p>
          </div>
      </div>
        <p-toast></p-toast>

        <!-- end part 1 in page  -->
         <div class="bg-white  rounded-3 p-4">
            <div class="row">
                <div class="col-12 col-md-7">

                    <ng-container>
                        <div class="container testimonial-group ">
                            <div class="row mb-3 vcrr" *ngIf="inquire_details">







                            <ng-container>
                              <div class="container testimonial-group">
                                <div class="row mb-3 vcrr">
                                  <div class="image-gallery" style="max-width: 1500px;">
                                    <div class="main-image">
                                      <img
                                        [src]="currentImage"
                                        style="width: 100%; height: 500px; border-radius: 13px;" />
                                    </div>
                                    <div class="thumbnail-container" style="display: flex; justify-content: start; margin-top: 20px; ">
                                      <div *ngFor="let img of displayedThumbnails; let i = index" style="cursor: pointer;" (click)="changeMainImage(img)">
                                        <img *ngIf="i > 0"
                                          [src]="img?.includes('https') ? img : '../../../assets/images/apartmentImages/default_apartment.jpg'"
                                          [class.selected-thumbnail]="img === currentImage"
                                          style="height: 120px; width: 95%; border-radius: 13px;" />
                                      </div>
                                    </div>
                                    <div *ngIf="inquire_details.apartment_Pictures.length > 5" class="navigation-buttons" style="display: flex; justify-content: space-between; margin-top: 20px;">
                                      <button class="btn btn-secondary" style="border-radius:10px; background-color:#D2D9E1; color: #1F4068;" (click)="previous()" [disabled]="currentIndex === 0">Previous</button>
                                      <button class="btn btn-secondary" style="border-radius:10px; background-color:#D2D9E1; color: #1F4068;" (click)="next()" [disabled]="currentIndex + 5 >= inquire_details.apartment_Pictures.length">Next</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>























                            </div>
                        </div>
                    </ng-container>

















                  <div>
                    <div class="d-flex align-items-center mb-2">
                        <p class="fw-1000 mt-3 mr-3 mb-3 fs-6">Rating ({{ value }})</p>
                        <div class="ms-2 ">
                            <p-rating [(ngModel)]="value" [readonly]="true" [cancel]="false"   ></p-rating>
                        </div>
                    </div>

                    <div>
                        <p class="fw-1000 mr-3 mb-3 fs-6">Descriptions</p>
                        <div class="description-text">
                            <p class="text-muted small">{{ inquire_details?.apartment_Description }}</p>
                        </div>
                    </div>
                </div>













                </div>
                <div class="col-12 col-md-5">

                    <div class="mt-0">
                        <h4 class="fw-bold my-0">{{ inquire_details?.apartment_Name }}</h4>
                        <!-- <p class="gray mt-0">{{ inquire_details?.apartment_Location }}</p> -->
                        <h5 class="blue fw-bold my-0">
                            {{ bookingPrice}} / month
                        </h5>
                        <div class="d-flex">
                            <div class="d-flex justify-content-between me-3">
                                <img src="assets/images/apartmentImages/location.svg" />
                                <p class="mt-3 ms-2 gray">{{ inquire_details?.apartment_Location }}</p>
                            </div>
                            <div class="d-flex justify-content-between me-3">
                                <img src="assets/images/apartmentImages/room_bed.svg" />
                                <p class="mt-3 gray ms-2">{{ inquire_details?.apartment_RoomsNo }}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <img src="assets/images/apartmentImages/user.svg" />
                                <p class="gray mt-3 ms-2">{{ inquire_details?.apartment_PersonsNo}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-0">
                        <img src="assets/images/apartmentImages/schedule time.svg" />
                        <p class="gray mt-3 ms-2">Time period: {{ inquire_details?.booking_Start_Date }} to {{ inquire_details?.booking_End_Date }} </p>
                    </div>
                    <div class="d-flex mt-0">
                        <p class="fw-bold my-0">Requested Appartment</p>

                        <!-- <p class="gray mt-3 ms-2"> {{ inquire_details?.requested_By }} </p> -->
                    </div>
                    <div style="display: flex; align-items: center;cursor: pointer;" routerLink="/users/app-edite-user-details/{{inquire_details?.requested_user_ID}}" class="w-96 h-16 px-6 py-4 bg-white justify-start items-center gap-3 inline-flex">

                        <img  style="     width: 64px; height: 64px;  border-radius: 50%" class="w-10 h-10 rounded-full" [src]="inquire_details?.requested_By_Img!=''?inquire_details?.requested_By_Img:'assets/images/Frame 1261154899.svg'" />
                        <div class="flex-col justify-start items-start inline-flex">
                            <div class="text-zinc-800 text-sm font-semibold font-['Montserrat'] leading-tight">{{ inquire_details?.requested_by }} </div>
                            <div class="text-neutral-400 text-sm font-normal font-['Montserrat'] leading-tight"></div>
                        </div>
                    </div>











                    <div class="container mt-4 requestd">
                      <h5 class="fw-bold">Request Details</h5>
                      <form>
                          <div class="row g-3 mb-3">
                              <div class="col-md-6">
                                  <label for="startDate" class="form-label">Start Date</label>
                                  <!-- <input type="text" class="form-control" id="startDate"  value="{{ inquire_details?.booking_Start_Date }}" > -->
                                  <input type="text" class="form-control" id="startDate" [value]="inquire_details?.booking_Start_Date | date: 'dd/MM/yyyy'" >

                              </div>
                              <div class="col-md-6">
                                  <label for="endDate" class="form-label">End Date</label>
                                  <input type="text" class="form-control" id="endDate" [value]="inquire_details?.booking_End_Date | date: 'dd/MM/yyyy'" >
                              </div>
                          </div>
                          <div class="row g-3 mb-3">
                              <div class="col-md-6">
                                  <label for="numberOfGuests" class="form-label">Number of Guests</label>
                                  <input type="text" class="form-control" id="numberOfGuests" value="{{ inquire_details?.booking_Guests_NOs }}">
                              </div>
                              <div class="col-md-6">
                                  <label for="agencyCode" class="form-label">Agency / broker code</label>
                                  <input type="text" class="form-control" id="agencyCode" value="" placeholder="agency code">
                              </div>
                          </div>
                          <div class="mb-3">
                              <label for="selectRole" class="form-label">Select Your Role</label>
                              <input type="text" class="form-control" id="selectRole" value="{{ inquire_details?.booking_Guest_Role}}">
                          </div>
                          <div class="mb-3">
                              <label for="universityName" class="form-label">University Name</label>
                              <input type="text" class="form-control" id="universityName" value="{{ inquire_details?.booking_University}}">
                          </div>

                        <p-confirmPopup></p-confirmPopup>


<button type="button" class="btn btn-primary btn-block mb-0 fs-6" style="width: 100%; border-radius:10px; padding:11px" (click)="showConfirm('confirm')" [disabled]="isAcceptDisabled(inquire_details?.booking_Status)" >Confirm</button>
<button type="button" class="btn btn-block fs-6" style="width: 100%; border-radius:10px; background-color:#D2D9E1; color: #1F4068; padding:11px" (click)="showConfirm('reject')"   >Reject</button>

<!-- Confirm Dialog -->
<div *ngIf="showConfirmDialog" class="modal" tabindex="-1" role="dialog" style="background-color: rgb(23 22 22 / 50%); display: block;">
  <div class="modal-dialog" role="document" style="max-width: 371px;">
    <div class="modal-content" style="margin-top: 150px;">
      <button type="button" aria-label="Close" class="close"
        style="border: none; background-color: #ffffff; width:40px; height: 40px;margin-left: auto;margin-right:15px;"
        (click)="onCloseConfirmDialog()">
        <span aria-hidden="true" style="color: #000000; font-size: 35px;">×</span>
      </button>
      <div class="modal-body">
        <div class="row text-center justify-content-center">
          <p>Are you sure you want to confirm?</p>
          <div class="ui-g-12 text-center mt-4">
            <button type="button" pButton label="Yes"  (click)="confirmApproval()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Reject Reason Dialog -->
<div *ngIf="showRejectReasonDialog" class="modal" tabindex="-1" role="dialog" style="background-color: rgb(23 22 22 / 50%); display: block;">
  <div class="modal-dialog" role="document" style="max-width: 371px;">
    <div class="modal-content" style="margin-top: 150px;">
      <button type="button" aria-label="Close" class="close"
        style="border: none; background-color: #ffffff; width:40px; height: 40px;margin-left: auto;margin-right:15px;"
        (click)="onCloseRejectDialog()">
        <span aria-hidden="true" style="color: #000000; font-size: 35px;">×</span>
      </button>
      <div class="modal-body">
        <div class="row text-center justify-content-center">
          <div class="ui-g-12">
            <!-- <input type="text" pInputText placeholder="Enter reason" [(ngModel)]="rejectReason" class="w-full"/> -->

            <textarea type="text" pInputText  [(ngModel)]="rejectReason" name="rejectReason" placeholder="Enter reason" class="form-control w-full"  ></textarea>

          </div>
          <div class="ui-g-12 text-center mt-4">
            <button type="button" pButton label="Submit" (click)="rejectApproval()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


                      </form>
                  </div>








                </div>
            </div>

        </div>
    </main>

</div>


<div class="row m-0">
  <div class="col-lg-2"></div>
  <div class="col-lg-10">
    <div class="container mt-4 p-4 m-2">
      <div class="card bookingdetails">
        <div class="card-header">
          <h4 class="fs-5 fw-bold">Booking Details</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <div class="d-flex justify-content-between px-4">
                <div class="fw-bold">Bed No</div>
                <div class="fw-bold">Room type</div>
                <div class="fw-bold">Bed price</div>
                <div class="fw-bold">Security deposit</div>
                <div class="fw-bold">Service fee</div>
                <div class="fw-bold">Status</div>
              </div>
            </div>
          </div>

        <div class="mb-4"  >
          <p-accordion id="bed"  >
            <!-- Bed 1 Section -->
            <p-accordionTab   [selected]="true" [headerStyle]="{'background-color': '#fff' }" *ngFor="let bed of inquire_details?.booking_Beds let i=index" >
              <ng-template pTemplate="header"  >
                <div class="d-flex justify-content-between align-items-center m-0  rounded w-100 px-2">
                  <div class="fw-bold">{{ bed.bed_No }}  </div>
                  <div>{{ bed.room_Type }}</div>
                  <div>€ {{ bed.bed_Price }}</div>
                  <div>€ {{ bed.secuirty_Deposit }}</div>
                  <div>€ {{ bed.service_Fees }}</div>
                  <div class="badge" [ngClass]="{'rented': bed.status === 'Rented', 'available': bed.status !== 'Rented'}">{{ bed.status }}</div>
                </div>
              </ng-template>
              <!-- <hr class="m-0"/> -->
              <div class="row bg-white m-0 p-4 mb-3">
                <div class="col-12 m-0 p-3 rounded pl-4 pr-4 bginfo">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <img src="https://via.placeholder.com/50" class="rounded-circle" alt="Profile Image">
                    </div>
                    <div class="col-lg-3 border-end">
                      <h6 class="fw-bold text-lowercase">{{ bed.guest_Data.guest_Name }}</h6>
                      <p class="m-0 text-muted">{{ bed.guest_Data.guest_Mail }}</p>
                    </div>
                    <div class="col-lg-3 text-right border-end">
                      <p class="m-0"><strong>Check In Date</strong><br>{{ bed.guest_Data.check_In | date: 'dd/MM/yyyy' }}</p>

                    </div>
                    <div class="col-lg-3 text-right border-end">
                      <p class="m-0"><strong>Check Out Date</strong><br>{{ bed.guest_Data.check_Out | date: 'dd/MM/yyyy'}}</p>
                    </div>
                    <div class="col-lg-2 text-right">
                      <p class="m-0"><strong>Phone Number</strong><br>{{ bed.guest_Data.phone_Number }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <p-accordion  [style]="{'background-color': '#f4f3f3'}">
                <div class="booking-process-container">
                  <h5 class="booking-process-header">Booking Process</h5>
                  <p class="booking-process-subtext">View tenant booking process</p>
                </div>


              <!-- <p-accordionTab header="Booking Process"> -->
                <p-accordion    [style]="{'background-color': '#f4f3f3'}"  >
                  <!-- Passport Section -->
                  <p-accordionTab id="pass"  class="custom-accordion-tab" [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false" >
                    <ng-template pTemplate="header"  >
                        <div class="d-flex align-items-center justify-content-start">
                          <!-- <img *ngIf="isAccordionOpen" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                          <img *ngIf="!isAccordionOpen" src="assets/img/check.svg" alt="icon" class="img-fluid"> -->
                          <!-- <img   *ngIf=" passApproved[bed.bed_ID]" src="assets/img/check.svg" alt="icon" class="img-fluid">
                          <img *ngIf=" !passApproved[bed.bed_ID]" src="assets/img/checkgray.svg" alt="icon" class="img-fluid"> -->
                          <img   *ngIf="bed.guest_Passport.status==='Approved'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                          <img *ngIf=" bed.guest_Passport.status==='Rejected'  || bed.guest_Passport.status==='Pending'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                          <span class="ml-2">Passport</span>
                        </div>
                        <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                          <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt=""> {{ bed.guest_Passport.passport_Date }}</span>
                            <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt=""> {{ bed.guest_Passport.passport_Time }}</span>
                          <!-- <span class="badge approve">Approved</span> -->
                          <!-- <span class="badge approve" *ngIf="bed.guest_Passport?.passport_Approved">Approved</span>
                          <span class="badge reject" *ngIf="!bed.guest_Passport?.passport_Approved">Pending</span> -->


                          <!-- <span class="badge pending" *ngIf="status[bed.bed_ID] === 'loading'">Pending</span>
                           <span class="badge approve" *ngIf="status[bed.bed_ID] === 'approved'">Approved</span>
                           <span class="badge pending" *ngIf="status[bed.bed_ID] === 'pending'">Pending</span>
                           <span class="badge reject" *ngIf="status[bed.bed_ID] === 'rejected'">Rejected</span> -->
                           <span class="badge pending" *ngIf="bed.guest_Passport.status==='Pending'">Pending</span>
                           <span class="badge approve" *ngIf="bed.guest_Passport.status==='Approved'">Approved</span>
                           <span class="badge reject" *ngIf="bed.guest_Passport.status==='Rejected' ">Rejected</span>

                        </div>


                      <!-- </div> -->
                    </ng-template>
                    <div class="card-body d-grid align-items-center">
                      <div class="row">
                        <!-- <img [src]="bed.guest_Passport.passport_Photo || 'https://via.placeholder.com/150'" class="img-fluid col-6 imgpass" alt="Passport Image"> -->
                        <ng-container *ngIf="bed.guest_Passport?.passport_Photo as passportUrl">
                          <ng-container *ngIf="passportUrl.endsWith('.jpeg') || passportUrl.endsWith('.jpg') || passportUrl.endsWith('.gif') || passportUrl.endsWith('.png') || passportUrl.endsWith('.heic'); else pdfTemplate">
                            <!-- <img [src]="passportUrl" class="img-fluid col-6 imgpass" alt="Passport Image"> -->
                            <img [src]="passportUrl" class="img-fluid col imgpass" alt="Passport Image" style="object-fit: fill; width: 70%; ">



                          </ng-container>
                          <ng-template #pdfTemplate>
                            <div class="col">
                            <span class="rounded-circle bg-light p-2">
                              <img src="/assets/images/_File upload icon.svg" alt="">
                            </span>
                            <div class="ml-2 mr-3">Passport.pdf</div>
                            <a [href]="passportUrl" target="_blank" class="btn btn-primary">View PDF</a>
                          </div>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngIf="!bed.guest_Passport?.passport_Photo">
                          <img src="https://via.placeholder.com/150" class="img-fluid col-6 imgpass" alt="Placeholder Image">
                        </ng-container>

                        <div class="col-7 border-start">
                          <div class="paddingpass">
                            <h5 class="card-title">Passport of tenant</h5>
                            <p class="card-text">Please review passport then confirm or reject it</p>
                            <!-- <button type="button" class="btn btn-primary btn-block mb-0 fs-6" style="width: 80%; border-radius:10px; padding:11px">Confirm</button>
                            <button type="button" class="btn btn-block fs-6" style="width: 80%; border-radius:10px; background-color:#D2D9E1; color: #1F4068; padding:11px">Reject</button> -->
                           <!-- Buttons to trigger approval and rejection -->
<button type="button" class="btn btn-primary btn-block mb-0 fs-6" style="width: 100%; border-radius:10px; padding:11px" (click)="showConfirmPass('confirm',bed.guest_Passport?.passport_ID,bed.bed_ID,i)"  [disabled]="bed.guest_Passport?.status==='Approved'">Approve Passport</button>
<button type="button" class="btn btn-block fs-6" style="width: 100%; border-radius:10px; background-color:#D2D9E1; color: #1F4068; padding:11px" (click)="showConfirmPass('reject',bed.guest_Passport?.passport_ID,bed.bed_ID,i)"   >Reject Passport</button>

<!-- Confirm Dialog -->
<div *ngIf="showConfirmDialogP" class="modal" tabindex="-1" role="dialog" style="background-color: rgb(23 22 22 / 50%); display: block;">
  <div class="modal-dialog" role="document" style="max-width: 371px;">
    <div class="modal-content" style="margin-top: 150px;">
      <button type="button" aria-label="Close" class="close"
        style="border: none; background-color: #ffffff; width:40px; height: 40px;margin-left: auto;margin-right:15px;"
        (click)="onCloseConfirmDialog()">
        <span aria-hidden="true" style="color: #000000; font-size: 35px;">×</span>
      </button>
      <div class="modal-body">
        <div class="row text-center justify-content-center">
          <p>Are you sure you want to approve this passport?</p>
          <div class="ui-g-12 text-center mt-4">
            <button type="button" class="btn btn-primary" (click)="handleAction(inquire_details?.booking_ID,true,passID,bedId,index)">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Reject Reason Dialog -->
<div *ngIf="showRejectReasonDialogP" class="modal" tabindex="-1" role="dialog" style="background-color: rgb(23 22 22 / 50%); display: block;">
  <div class="modal-dialog" role="document" style="max-width: 371px;">
    <div class="modal-content" style="margin-top: 150px;">
      <button type="button" aria-label="Close" class="close"
        style="border: none; background-color: #ffffff; width:40px; height: 40px;margin-left: auto;margin-right:15px;"
        (click)="onCloseRejectDialog()">
        <span aria-hidden="true" style="color: #000000; font-size: 35px;">×</span>
      </button>
      <div class="modal-body">
        <div class="row text-center justify-content-center">
          <div class="ui-g-12">
            <!-- <input type="text" [(ngModel)]="rejectReason" placeholder="Enter reason" class="form-control w-full"/> -->
            <textarea type="text" pInputText  [(ngModel)]="rejectReasonP" name="rejectReasonP" placeholder="Enter reason" class="form-control w-full"  ></textarea>
          </div>
          <div class="ui-g-12 text-center mt-4">
            <button type="button" class="btn btn-primary" (click)="handleAction(inquire_details?.booking_ID,false,passID,bedId,index)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </p-accordionTab>

                  <!-- Security Deposit Section -->

                  <p-accordionTab  class="custom-accordion-tab" [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                    <ng-template pTemplate="header"  >
                      <div class="d-flex align-items-center justify-content-start">
                        <!-- <img *ngIf="isAccordionOpen" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <img *ngIf="!isAccordionOpen" src="assets/img/check.svg" alt="icon" class="img-fluid"> -->
                        <img *ngIf=" bed.secuirty_Deposit_Invoices?.status === 'Not Completed'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <img *ngIf=" bed.secuirty_Deposit_Invoices?.status === 'Completed'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                        <span class="ml-2">Security Deposit</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                        <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt="">  {{ bed.secuirty_Deposit_Invoices?.secuirty_Date }} </span>
                          <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt="">{{ bed.secuirty_Deposit_Invoices?.secuirty_Time }}</span>
                          <span class="badge unpaid" *ngIf="bed.secuirty_Deposit_Invoices?.status === 'Not Completed'">Unpaid</span>
                          <span class="badge approve" *ngIf="bed.secuirty_Deposit_Invoices?.status === 'Completed'">paid</span>
                      </div>


                    <!-- </div> -->
                  </ng-template>
                    <div class="container mt-3">
                      <table class="table table-borderless">
                        <thead>
                          <tr class="texttable">
                            <th>Invoice Number</th>
                            <th>Duration</th>
                            <th>Amount</th>
                            <th>Payment Methods</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let invoice of bed.secuirty_Deposit_Invoices?.invoices">
                            <td>{{ invoice?.invoice_Code }}</td>
                            <td>{{ invoice?.invoice_Duration }}</td>
                            <td>{{ invoice?.invoice_Amount }}</td>
                            <td>{{ invoice?.payment_Method }}</td>
                            <td><span class="badge" [ngClass]="{'approve': invoice?.payment_Is_Paid, 'reject': !invoice?.payment_Is_Paid}">{{ invoice?.payment_Is_Paid ? 'Paid' : 'Unpaid' }}</span></td>
                            <td><img src="/assets/images/download.svg" alt="" class="me-1" ></td>
                          </tr>
                          <!-- <tr>
                            <td>Invoice435</td>
                            <td>April 27 - May 27, 2023</td>
                            <td>$35.00</td>
                            <td>Apple Pay</td>
                            <td><span class="badge unpaid">unpaid</span></td>
                            <td><img src="/assets/images/download.svg" alt="" class="me-1" ></td>
                          </tr> -->
                        </tbody>
                      </table>
                    </div>
                  </p-accordionTab>

                  <!-- Sign Contract Section -->
                  <p-accordionTab [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                  <ng-template pTemplate="header"  >
                    <div class="d-flex align-items-center justify-content-start">
                      <img *ngIf=" bed.contract.status === 'Completed'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                        <img *ngIf=" bed.contract.status === 'Not Completed'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                      <span class="ml-2">Sign Contract</span>

                    </div>
                    <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                      <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt="">{{ bed.contract.contract_Date }}</span>
                        <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt="">  {{ bed.contract.contract_Time }}</span>
                        <span class="badge approve" *ngIf="bed.contract.status === 'Completed'">Signed</span>
                        <span class="badge reject" *ngIf="bed.contract.status === 'Not Completed'">Unsigned</span>
                    </div>


                  <!-- </div> -->
                </ng-template>
                    <div class="container mt-3">
                      <div class="d-flex justify-content-between align-items-center border p-3 rounded mb-3">
                        <div class="d-flex align-items-center">
                          <div class="rounded-circle bg-light p-2">
                            <img src="/assets/images/_File upload icon.svg" alt="">
                          </div>
                          <div class="ml-2 mr-3">Contract.pdf</div>
                        </div>
                        <div class="d-flex align-items-center">

                          <a [href]="bed.contract.contract_File" target="_blank" class="text-decoration-none text-primary mx-2"><img src="/assets/images/view.svg" class="me-1" alt="">View</a>
                          <a href="#" class="text-decoration-none text-primary mx-2"> <img src="/assets/images/download.svg" alt="" class="me-1" >Download</a>
                          <button class="btn custom-btn-outline-primary ml-3" (click)="resignContract(inquire_details?.booking_ID, bed.contract.sign_ID,i)" [disabled]="bed.contract.status==='Not Completed'">Resign Contract</button>
                        </div>
                      </div>
                    </div>
                  </p-accordionTab>

                  <!-- Arriving Details Section -->
                  <p-accordionTab  [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                    <ng-template pTemplate="header"  >
                      <div class="d-flex align-items-center justify-content-start">
                        <!-- <img *ngIf="isAccordionOpen" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <img *ngIf="!isAccordionOpen" src="assets/img/check.svg" alt="icon" class="img-fluid"> -->
                        <img *ngIf=" bed.arriving_Details?.status === 'Completed'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                        <img *ngIf=" bed.arriving_Details?.status === 'Not Completed'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <span class="ml-2">Arriving Details</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                        <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt="">{{ bed.arriving_Details.date }}</span>
                          <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt="">{{ bed.arriving_Details.time }}</span>
                        <!-- <span class="badge approve">Completed</span> -->
                        <span class="badge approve" *ngIf="bed.arriving_Details.status === 'Completed'">Completed</span>
                        <span class="badge reject" *ngIf="bed.arriving_Details.status === 'Not Completed'">Pending</span>
                      </div>


                    <!-- </div> -->
                  </ng-template>
                    <!-- <hr class="m-0"/> -->
                    <div class="container mt-3 mb-3">
                      <h6 class="fw-bold text-lowercase">Arriving Details</h6>
                      <p class="text-muted">View tenant arrival date and transportation requirements, if needed</p>
                      <div class="p-3 rounded bg-light">
                        <div class="row">
                          <div class="col border-start">
                            <div class="text-muted">Check In Date</div>
                            <div class="font-weight-bold">{{ bed.arriving_Details.check_In_Date }}</div>
                          </div>
                          <div class="col border-start">
                            <div class="text-muted">Check In Time</div>
                            <div class="font-weight-bold">{{ bed.arriving_Details.check_In_Time }}</div>
                          </div>
                          <div class="col border-start">
                            <div class="text-muted">Transportation</div>
                            <div class="font-weight-bold">{{ bed.arriving_Details.is_Transportation ? 'Yes' : 'No' }}</div>
                          </div>
                          <div class="col border-start">
                            <div class="text-muted">Airport</div>
                            <div class="font-weight-bold">{{ bed.arriving_Details.airport_Name }}</div>
                          </div>
                          <div class="col border-start">
                            <div class="text-muted">Flight Number</div>
                            <div class="font-weight-bold">{{ bed.arriving_Details.flight_No }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-accordionTab>

                  <!-- Check In Process Section -->
                  <p-accordionTab  [selected]="true" [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                    <ng-template pTemplate="header">
                      <div class="d-flex align-items-center justify-content-start">
                        <!-- Overall Check for All Steps -->
                        <img *ngIf="
                          bed.check_In_Process?.scanning_QR?.status === 'Completed' &&
                          bed.check_In_Process?.confirm_Identity?.status === 'Completed' &&
                          bed.check_In_Process?.payments?.status === 'Completed' &&
                          bed.check_In_Process?.handover?.status === 'Completed' &&
                          bed.check_In_Process?.apartment_Rules?.status === 'Completed'; else pendingTemplate
                        " src="assets/img/check.svg" alt="icon" class="img-fluid">

                        <span class="ml-2">Check In Process</span>
                      </div>

                      <ng-template #pendingTemplate>
                        <img src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                      </ng-template>

                      <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                        <span class="mx-2">
                          <img src="/assets/images/date.svg" class="me-1" alt="">
                          {{ bed.check_In_Process?.scanning_QR?.date }}
                        </span>
                        <span class="mx-4">
                          <img src="/assets/images/clock.svg" class="me-1" alt="">
                          {{ bed.check_In_Process?.scanning_QR?.time }}
                        </span>
                        <span class="badge approve" *ngIf="
                          bed.check_In_Process?.scanning_QR?.status === 'Completed' &&
                          bed.check_In_Process?.confirm_Identity?.status === 'Completed' &&
                          bed.check_In_Process?.payments?.status === 'Completed' &&
                          bed.check_In_Process?.handover?.status === 'Completed' &&
                          bed.check_In_Process?.apartment_Rules?.status === 'Completed'
                        ">Completed</span>
                        <span class="badge reject" *ngIf="
                          bed.check_In_Process?.scanning_QR?.status !== 'Completed' ||
                          bed.check_In_Process?.confirm_Identity?.status !== 'Completed' ||
                          bed.check_In_Process?.payments?.status !== 'Completed' ||
                          bed.check_In_Process?.handover?.status !== 'Completed' ||
                          bed.check_In_Process?.apartment_Rules?.status !== 'Completed'
                        ">Not Completed</span>
                      </div>
                    </ng-template>

                    <!-- <hr class="m-0"/> -->
                     <div class="m-0 p-0"   >
                    <div class="container m-0"   >
                      <h6 class="fw-bold text-lowercase">Check In Process</h6>
                      <p class="text-muted">View tenant check-in process from scanning bed QR code to signing the contract and completing the payment process.</p>
                      <p-accordion   >
                        <!-- Scanning Bed QR Code Section -->
                        <p-accordionTab [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                          <!-- <hr class="m-0"/> -->
                          <ng-template pTemplate="header"  >
                            <div class="d-flex align-items-center justify-content-start">
                              <!-- <img *ngIf="isAccordionOpen" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                              <img *ngIf="!isAccordionOpen" src="assets/img/check.svg" alt="icon" class="img-fluid"> -->
                              <img *ngIf=" bed.check_In_Process?.scanning_QR?.status === 'Completed'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                              <img *ngIf=" bed.check_In_Process?.scanning_QR?.status === 'Not Completed'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">

                              <span class="ml-2">Scanning Bed QR Code</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                              <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt=""> {{ bed.check_In_Process.scanning_QR.date }}</span>
                                <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt=""> {{ bed.check_In_Process.scanning_QR.time }}</span>
                              <!-- <span class="badge approve">Completed</span> -->
                              <span class="badge approve" *ngIf="bed.check_In_Process?.scanning_QR?.status === 'Completed'">Completed</span>
                              <span class="badge reject" *ngIf="bed.check_In_Process?.scanning_QR?.status === 'Not Completed'">Pending</span>
                            </div>


                          <!-- </div> -->
                        </ng-template>
                          <div class="container mt-3 mb-3">
                            <div class="d-flex justify-content-between align-items-center p-3 rounded bg-light">
                              <div><strong>Bed QR code:</strong> {{ bed.check_In_Process.scanning_QR.qr_Code }}</div>
                              <div class="d-flex align-items-center">
                                <a   class="text-decoration-none text-primary mx-2 d-flex align-items-center" (click)="onOpenQrModal(bed.check_In_Process?.scanning_QR?.qr_Image ,bed.check_In_Process?.scanning_QR?.qr_Code,bed.room_Type,inquire_details.apartment_QR)" >
                                  <img src="/assets/images/view.svg" class="me-1" alt=""> View
                                </a>
                                <a   class="text-decoration-none text-primary mx-2 d-flex align-items-center">
                                  <img src="/assets/images/download.svg" alt="" class="me-1" > Download
                                </a>
                                <a   class="text-decoration-none text-primary mx-2 d-flex align-items-center">
                                  <img src="/assets/images/share.svg" alt="" class="me-1" > Share
                                </a>
                              </div>
                            </div>
                          </div>
                        </p-accordionTab>

                                <!-- Confirm Identity Section -->
                  <p-accordionTab [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                    <ng-template pTemplate="header"  >
                      <div class="d-flex align-items-center justify-content-start">
                        <!-- <img *ngIf="isAccordionOpen" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <img *ngIf="!isAccordionOpen" src="assets/img/check.svg" alt="icon" class="img-fluid"> -->
                        <img *ngIf=" bed.check_In_Process?.confirm_Identity?.status === 'Completed'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                        <img *ngIf=" bed.check_In_Process.confirm_Identity.status === 'Not Completed'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <span class="ml-2">Confirm Identity</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                        <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt="">  {{ bed.check_In_Process.confirm_Identity.date }}</span>
                          <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt=""> {{ bed.check_In_Process.confirm_Identity.time }}</span>
                        <!-- <span class="badge approve">Completed</span> -->
                        <span class="badge approve" *ngIf="bed.check_In_Process?.confirm_Identity?.status === 'Completed'">Completed</span>
                        <span class="badge reject" *ngIf="bed.check_In_Process?.confirm_Identity?.status === 'Not Completed'">Pending</span>
                      </div>


                    <!-- </div> -->
                  </ng-template>
                    <!-- <hr class="m-0"/> -->
                    <div class="container mt-3">
                      <h5>Passport of tenant</h5>
                      <p class="text-muted">Please review passport and tenant image then confirm or reject it</p>
                      <div class="row mb-3">
                        <div class="col-md-6">
                          <!-- <img [src]="bed.check_In_Process?.confirm_Identity?.guest_Passport || 'https://via.placeholder.com/300x200'" style="width: 90%; height: 220px; border-radius: 10px;" class="img-fluid rounded" alt="Passport Image"> -->
                          <ng-container *ngIf="bed.check_In_Process?.confirm_Identity?.guest_Passport as passporttUrl">
                            <ng-container *ngIf="passporttUrl.endsWith('.jpeg') || passporttUrl.endsWith('.jpg') || passporttUrl.endsWith('.gif') || passporttUrl.endsWith('.png') || passporttUrl.endsWith('.heic'); else pdfTemplate">
                              <!-- <img [src]="passportUrl" class="img-fluid col-6 imgpass" alt="Passport Image"> -->
                              <img [src]="passporttUrl" class="img-fluid col imgpass" alt="Passport Image" style="object-fit: fill; width: 70%; ">



                            </ng-container>
                            <ng-template #pdfTemplate>
                              <div class="col">
                              <span class="rounded-circle bg-light p-2">
                                <img src="/assets/images/_File upload icon.svg" alt="">
                              </span>
                              <div class="ml-2 mr-3">Passport.pdf</div>
                              <a [href]="passporttUrl" target="_blank" class="btn btn-primary">View PDF</a>
                            </div>
                            </ng-template>
                          </ng-container>
                          <ng-container *ngIf="!bed.check_In_Process?.confirm_Identity?.guest_Passport">
                            <img src="https://via.placeholder.com/150" class="img-fluid col-6 imgpass" alt="Placeholder Image">
                          </ng-container>
                        </div>
                        <div class="col-md-6">
                          <img [src]="bed.check_In_Process?.confirm_Identity?.guest_Selife || 'https://via.placeholder.com/300x200'" style="width: 90%; height: 220px; border-radius: 10px;" class="img-fluid rounded" alt="Tenant Image">
                          <div class="d-flex mt-3">
                            <button type="button" class="btn btn-primary btn-block mb-0 fs-6 m-1" style="width: 44%; border-radius: 10px; padding: 10px;" (click)="showConfirmSelfi('confirm',i,bed.guest_Data?.guest_ID)"  [disabled]="isAcceptDisabledSelfi(bed.check_In_Process?.confirm_Identity?.status )">Confirm</button>
                            <button type="button" class="btn btn-block fs-6 m-1" style="width: 44%; border-radius: 10px; background-color: #D2D9E1; color: #1F4068; padding: 10px;" (click)="showConfirmSelfi('reject',i,bed.guest_Data?.guest_ID)"  >Reject</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-accordionTab>

                  <div *ngIf="showConfirmDialogS" class="modal" tabindex="-1" role="dialog" style="background-color: rgb(23 22 22 / 50%); display: block;">
                    <div class="modal-dialog" role="document" style="max-width: 371px;">
                      <div class="modal-content" style="margin-top: 150px;">
                        <button type="button" aria-label="Close" class="close"
                          style="border: none; background-color: #ffffff; width:40px; height: 40px;margin-left: auto;margin-right:15px;"
                          (click)="onCloseConfirmDialog()">
                          <span aria-hidden="true" style="color: #000000; font-size: 35px;">×</span>
                        </button>
                        <div class="modal-body">
                          <div class="row text-center justify-content-center">
                            <p>Are you sure you want to approve this image?</p>
                            <div class="ui-g-12 text-center mt-4">
                              <button type="button" class="btn btn-primary" (click)="handleActionSelfi(true,inquire_details?.booking_ID, guestID,indexselfi) ">Yes</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Reject Reason Dialog -->
                  <div *ngIf="showRejectReasonDialogS" class="modal" tabindex="-1" role="dialog" style="background-color: rgb(23 22 22 / 50%); display: block;">
                    <div class="modal-dialog" role="document" style="max-width: 371px;">
                      <div class="modal-content" style="margin-top: 150px;">
                        <button type="button" aria-label="Close" class="close"
                          style="border: none; background-color: #ffffff; width:40px; height: 40px;margin-left: auto;margin-right:15px;"
                          (click)="onCloseRejectDialog()">
                          <span aria-hidden="true" style="color: #000000; font-size: 35px;">×</span>
                        </button>
                        <div class="modal-body">
                          <div class="row text-center justify-content-center">
                            <div class="ui-g-12">
                              <!-- <input type="text" [(ngModel)]="rejectReason" placeholder="Enter reason" class="form-control w-full"/> -->
                              <textarea type="text" pInputText  [(ngModel)]="rejectReasonS" name="rejectReasonS" placeholder="Enter reason" class="form-control w-full"  ></textarea>
                            </div>
                            <div class="ui-g-12 text-center mt-4">
                              <button type="button" class="btn btn-primary" (click)="handleActionSelfi(false,inquire_details?.booking_ID,guestID,indexselfi) ">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- <p-accordionTab  [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">

                    <ng-template pTemplate="header"  >
                      <div class="d-flex align-items-center justify-content-start">
                        <img *ngIf="bed.check_In_Process?.payments?.status === 'Completed'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                        <img *ngIf="bed.check_In_Process?.payments?.status === 'Pending'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <span class="ml-2">Payments</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                        <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt=""> {{ bed.check_In_Process?.payments?.date }}</span>
                          <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt=""> {{ bed.check_In_Process?.payments?.time }}</span>
                          <span class="badge approve" *ngIf="bed.check_In_Process?.payments?.status === 'Completed'">Completed</span>
                                <span class="badge reject" *ngIf="bed.check_In_Process?.payments?.status === 'Pending'">Pending</span>
                      </div>



                  </ng-template>
                    <div class="container mt-3">
                      <table class="table table-borderless">
                        <thead>
                          <tr class="texttable">
                            <th>Invoice Number</th>
                            <th>Duration</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Payment Methods</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let invoice of bed.check_In_Process?.payments?.invoices">
                            <td>{{ invoice?.invoice_Code }}</td>
                            <td>{{ invoice?.invoice_Duration }}</td>
                            <td>{{ invoice?.invoice_Amount }}</td>
                            <td>{{ invoice?.payment_Method }}</td>
                            <td><span class="badge" [ngClass]="{'approve': invoice?.payment_Is_Paid, 'unpaid': !invoice?.payment_Is_Paid}">{{ invoice?.payment_Is_Paid ? 'Paid' : 'Unpaid' }}</span></td>
                            <td><img src="/assets/images/download.svg" alt="" class="me-1" ></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </p-accordionTab> -->

                  <p-accordionTab [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                    <ng-template pTemplate="header">
                      <div class="d-flex align-items-center justify-content-start">
                        <!-- Overall Check for All Payments -->
                        <ng-container *ngIf="bed.check_In_Process?.payments?.invoices?.length > 0; else noInvoices">
                          <ng-container *ngFor="let invoice of bed.check_In_Process?.payments?.invoices; let last = last">
                            <ng-container *ngIf="!invoice.payment_Is_Paid; then unpaidTemplate else checkIfLast">
                            </ng-container>
                            <ng-template #checkIfLast>
                              <ng-container *ngIf="last">
                                <img src="assets/img/check.svg" alt="icon" class="img-fluid">
                                <span class="ml-2">Payments</span>
                              </ng-container>
                            </ng-template>
                          </ng-container>
                          <ng-template #unpaidTemplate>
                            <img src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                            <span class="ml-2">Payments</span>
                          </ng-template>
                        </ng-container>
                        <ng-template #noInvoices>
                          <img src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                          <span class="ml-2">Payments</span>
                        </ng-template>
                      </div>
                      <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                        <span class="mx-2">
                          <img src="/assets/images/date.svg" class="me-1" alt="">
                          {{ bed.check_In_Process?.payments?.rent_Date }}
                        </span>
                        <span class="mx-4">
                          <img src="/assets/images/clock.svg" class="me-1" alt="">
                          {{ bed.check_In_Process?.payments?.rent_Time }}
                        </span>
                        <ng-container *ngFor="let invoice of bed.check_In_Process?.payments?.invoices; let last = last">
                          <ng-container *ngIf="!invoice.payment_Is_Paid; then notAllPaid else checkIfLastStatus">
                          </ng-container>
                          <ng-template #checkIfLastStatus>
                            <ng-container *ngIf="last">
                              <span class="badge approve">Paid</span>
                            </ng-container>
                          </ng-template>
                        </ng-container>
                        <ng-template #notAllPaid>
                          <span class="badge reject">Unpaid</span>
                        </ng-template>
                      </div>
                    </ng-template>
                    <div class="container mt-3">
                      <table class="table table-borderless">
                        <thead>
                          <tr class="texttable">
                            <th>Invoice Number</th>
                            <th>Duration</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Payment Methods</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let invoice of bed.check_In_Process?.payments?.invoices">
                            <td>{{ invoice?.invoice_Code }}</td>
                            <td>{{ invoice?.invoice_Duration }}</td>
                            <td>{{ invoice?.invoice_Amount }}</td>
                            <td>{{ invoice?.payment_Method }}</td>
                            <td>
                              <span class="badge" [ngClass]="{'approve': invoice?.payment_Is_Paid, 'unpaid': !invoice?.payment_Is_Paid}">
                                {{ invoice?.payment_Is_Paid ? 'Paid' : 'Unpaid' }}
                              </span>
                            </td>
                            <td>
                              <a [href]="invoice?.invoice_File" target="_blank">
                                <img src="/assets/images/download.svg" alt="" class="me-1">
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </p-accordionTab>




                  <!-- Handover Protocols Section -->
                  <p-accordionTab  [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                    <!-- <hr class="m-0"/> -->
                    <ng-template pTemplate="header"  >
                      <div class="d-flex align-items-center justify-content-start">
                        <img *ngIf="bed.check_In_Process?.handover?.status === 'Completed'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                        <img *ngIf="bed.check_In_Process?.handover?.status === 'Not Completed'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <span class="ml-2">Handover Protocols</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                        <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt="">{{ bed.check_In_Process.handover.date }}</span>
                          <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt=""> {{ bed.check_In_Process.handover.time }}</span>
                        <!-- <span class="badge approve">Completed</span> -->
                        <span class="badge approve" *ngIf="bed.check_In_Process.handover.status === 'Completed'">Completed</span>
                        <span class="badge reject" *ngIf="bed.check_In_Process.handover.status === 'Not Completed'">Pending</span>
                      </div>


                    <!-- </div> -->
                  </ng-template>
                    <div class="container mt-3 mb-3">
                      <div class="d-flex justify-content-between align-items-center p-3 rounded bg-light border">
                        <div><strong>Handover Protocols</strong></div>
                        <div class="d-flex align-items-center">
                          <a href="#" class="text-decoration-none text-primary mx-2 d-flex align-items-center">
                            <img src="/assets/images/view.svg" class="me-1" alt=""> View
                          </a>
                          <a href="#" class="text-decoration-none text-primary mx-2 d-flex align-items-center">
                            <img src="/assets/images/download.svg" alt="" class="me-1" > Download
                          </a>
                          <a href="#" class="text-decoration-none text-primary mx-2 d-flex align-items-center">
                            <img src="/assets/images/share.svg" alt="" class="me-1" > Share
                          </a>
                        </div>
                      </div>
                    </div>
                  </p-accordionTab>

                  <!-- Apartment Rules Section -->
                  <p-accordionTab  [headerStyle]="{'background-color': '#fff' }" (onOpen)="isAccordionOpen = true" (onClose)="isAccordionOpen = false">
                    <!-- <hr class="m-0"/> -->
                     <ng-template pTemplate="header"  >
                      <div class="d-flex align-items-center justify-content-start">
                        <img *ngIf="bed.check_In_Process?.apartment_Rules?.status === 'Completed'" src="assets/img/check.svg" alt="icon" class="img-fluid">
                        <img *ngIf="bed.check_In_Process?.apartment_Rules?.status === 'Not Completed'" src="assets/img/checkgray.svg" alt="icon" class="img-fluid">
                        <span class="ml-2">Apartment Rules</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-end w-100 pe-3">
                        <span class="mx-2"><img src="/assets/images/date.svg" class="me-1" alt=""> {{ bed.check_In_Process.apartment_Rules.date }}</span>
                          <span class="mx-4"><img src="/assets/images/clock.svg" class="me-1" alt=""> {{ bed.check_In_Process.apartment_Rules.time }}</span>
                        <!-- <span class="badge approve">Completed</span> -->
                        <span class="badge approve" *ngIf="bed.check_In_Process?.apartment_Rules?.status === 'Completed'">Completed</span>
                        <span class="badge reject" *ngIf="bed.check_In_Process?.apartment_Rules?.status === 'Not Completed'">Pending</span>
                      </div>


                    <!-- </div> -->
                  </ng-template>
                    <div class="container mt-3 mb-3">
                      <div class="d-flex justify-content-between align-items-center p-3 rounded bg-light border">
                        <div><strong>Apartment Rules</strong></div>
                        <div class="d-flex align-items-center">
                          <a href="#" class="text-decoration-none text-primary mx-2 d-flex align-items-center">
                            <img src="/assets/images/view.svg" class="me-1" alt="">  View
                          </a>
                          <a href="#" class="text-decoration-none text-primary mx-2 d-flex align-items-center">
                            <img src="/assets/images/download.svg" alt="" class="me-1" > Download
                          </a>
                          <a href="#" class="text-decoration-none text-primary mx-2 d-flex align-items-center">
                            <img src="/assets/images/share.svg" class="me-1" alt=""> Share
                          </a>
                        </div>
                      </div>
                    </div>
                  </p-accordionTab>
                        </p-accordion>

                    </div>
                  </div>
                  </p-accordionTab>


                </p-accordion>
              <!-- </p-accordionTab> -->

            </p-accordion>

            <div class="row bg-white m-0 p-4" id="more-details"  >
              <div class="col-12 m-0 p-3 rounded pl-4 pr-4">
                <div class="row align-items-center">
                  <div class="container mt-3">
                    <h6 class="fw-bold text-lowercase">More details</h6>
                    <p class="text-muted">View tenant passport and reported problems, invoices</p>
                    <div class="accordion" id="accordionExample">
                      <div class="container mt-3">
                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                          <div><strong>Problem reports (2)</strong></div>
                          <div><img src="/assets/images/expand_more.svg" alt=""></div>
                        </div>
                      </div>
                      <div class="container mt-3">
                        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                          <div><strong>Fixed problem (0)</strong></div>
                          <div><img src="/assets/images/expand_more.svg" alt=""></div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                      <button class="btn custom-btn-outline-primary ml-3">Unpublish</button>
                      <button class="btn btn-primary" style="border-radius: 10px; font-size: 14px;">Mark as Available</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </p-accordionTab>

            <!-- end Booking Process Section -->



          </p-accordion>

        </div>










        </div>
      </div>
    </div>
  </div>
</div>







<div *ngIf="qrCodeImg !== ''" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': displayQr}"
  style="background-color: rgb(23 22 22 / 50%);">
  <div class="modal-dialog" role="document" style="max-width: 371px;">
    <div class="modal-content" style="margin-top: 150px;">
      <button type="button" aria-label="Close" class="close"
        style="border: none; background-color: #ffffff; width:40px; height: 40px;margin-left: auto;margin-right:15px;">
        <button aria-hidden="true" style="color: #000000; font-size: 35px; border:none ; background:#fff;" (click)="onCloseQrModal()">×</button>
      </button>
      <div class="modal-body">
        <div class="row text-center justify-content-center " id="qrToPdf">


          <!-- <div class="d-flex justify-content-center  fw-bold">
            <div class="mr-3">{{roomType}}.R</div>
            <div style="width:170px"></div>
            <div>ID-{{aprtCode}}</div>
          </div> -->
          <div class="d-flex justify-content-between fs-6 fw-bold">
            <div class="mr-3">{{roomType}}.R </div>
            <div style="width:170px"></div>
            <div>ID-{{aprtCode}} </div>
          </div>
          <!-- <p class="mt-4">Scan this code</p> -->
          <p class="mt-4 fs-6 text-center">Scan this code</p>
          <img id="qr_image_e" [src]="qrCodeImg" class="qrfix">
          <!-- <div class="fw-semibold">QR Code : <span class="fw-normal" style="color:#B3261E;">{{qrCode}}</span> </div> -->
          <div class="fw-bold text-center fs-6 mt-4">QR Code : <span class="fw-normal" style="color:#B3261E;">{{qrCode}}</span> </div>

        </div>
        <div class="row text-center justify-content-center">
          <p class="mt-4 text-center">Or enter the number manually</p>
          <div class="custom-apartment-buttons mt-1">
            <!-- <button class="custom-btn-delete">{{qrCode}}</button>
            <button class="custom-btn-rented mt-4"  >Download</button> -->
            <button class="custom-btn-delete">{{qrCode}}</button>
            <button class="custom-btn-rented mt-4" (click)="printDiv('qrToPdf')">Download</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
























