<div id="Generalinfo">
    <p class="fw-bold mb-0">General info</p>
    <p style="color: #605D62 !important;">{{title}}</p>
</div>
<div class="d-flex mb-5 d-md-block d-flex justify-content-center justify-md-content-start ">
    <div class=" d-md-flex  align-items-center justify-md-content-start ">
        <div class="rounded-circle imageOfPerson me-4" style="box-shadow: 0 2px 6px #d1d1d1;">
            <img class="imageOfPerson rounded-circle" style="border: 3px solid white;" [src]="imageUrl ? imageUrl : defaultImageUrl()">
        </div>
        <div class="d-flex mt-4">
            <div class="d-flex  justify-content-between ">

                <div class="btn-container" *ngIf="titlePage!='partenerView'">
                    <p-button class="btn-style sendDataPButton" label="Upload picture" [loading]="loadingButton" type="button" ></p-button>
                    <input type="file" #fileInput accept="image/*" (change)="uploadPic($event)" data-toggle="tooltip" data-placement="top" title="Upload picture">
                </div>

                  <button type="button" class="outline-none border-0 py-2 px-3 rounded-3 delete" *ngIf="titlePage!= 'partenerView ' && imageUrl && viewDeleteButtonButton" (click)="uploadPic('delete')">Delete</button>


                <button class="me-3 outline-none border-0 text-white py-2 px-3 rounded-3" *ngIf="titlePage=='Owner details'" style="background-color: #1f4068;">Message</button>
            </div>
        </div>
    </div>
</div>
