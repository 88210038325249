<!-- start header  -->
<header>
  <app-head (newItemEvent)="addItem($event)" [titleModule]="'Apartments'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
  <!-- start sidebar  -->
  <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'apartments'"></app-sidebar>
  <!-- end sidebar  -->
  <!-- start content page  -->
  <main class="p-4 col-lg-10">
    <!-- start part 1 in page  -->
    <div class="fw-bold d-flex justify-content-between" style="color: #6c6a6a">
      <a href="javascript:void(0)" routerLink="/apartments" class="removeUnderLine">
        <p class="fw-bold ml-8">Apartments</p>
      </a>
    </div>
    <p-toast></p-toast>
    <!-- end part 1 in page  -->

    <div class="bg-white border rounded-3 mt-3 p-3 p-4">
      <div class="row">
        <div class="col-12 col-md-7">
          <!-- <ng-container>
            <div class="container testimonial-group">
              <div class="row mb-3 vcrr">
                <div class="image-gallery" style="max-width: 1500px;">
                  <div class="main-image">
                    <img
                      [src]="aprt_Imgs[0].includes('https') ? aprt_Imgs[0] : '../../../assets/images/apartmentImages/default_apartment.jpg'"
                      style="width: 100%; height:500px; border-radius:13px" />
                  </div>
                  <div class="thumbnail-container" style="display: flex; justify-content: start; margin-top: 20px;">
                    <div *ngFor="let img of aprt_Imgs; let i = index" style="cursor: pointer;">
                      <img *ngIf="i > 0"
                        [src]="img?.includes('https') ? img : '../../../assets/images/apartmentImages/default_apartment.jpg'"
                        style="height: 120px; width: 100%; border-radius:13px;" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container> -->
          <ng-container>
            <div class="container testimonial-group">
              <div class="row mb-3 vcrr">
                <div class="image-gallery" style="max-width: 1500px;">
                  <div class="main-image">
                    <img
                      [src]="currentImage"
                      style="width: 100%; height: 500px; border-radius: 13px;" />
                  </div>
                  <div class="thumbnail-container" style="display: flex; justify-content: start; margin-top: 20px; ">
                    <div *ngFor="let img of displayedThumbnails; let i = index" style="cursor: pointer;" (click)="changeMainImage(img)">
                      <img *ngIf="i > 0"
                        [src]="img?.includes('https') ? img : '../../../assets/images/apartmentImages/default_apartment.jpg'"
                        [class.selected-thumbnail]="img === currentImage"
                        style="height: 120px; width: 95%; border-radius: 13px;" />
                    </div>
                  </div>
                  <div *ngIf="aprt_Imgs.length > 5" class="navigation-buttons" style="display: flex; justify-content: space-between; margin-top: 20px;">
                    <button class="btn btn-secondary" style="border-radius:10px; background-color:#D2D9E1; color: #1F4068;" (click)="previous()" [disabled]="currentIndex === 0">Previous</button>
                    <button class="btn btn-secondary" style="border-radius:10px; background-color:#D2D9E1; color: #1F4068;" (click)="next()" [disabled]="currentIndex + 5 >= aprt_Imgs.length">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="d-flex align-items-center mb-2">
            <p class="fw-1000 mt-3 mr-3 mb-3 fs-6 fw-semibold">Rating ({{ rating_count }})</p>
            <div class="ms-2">
              <p-rating [(ngModel)]="rating_total" [readonly]="true" [cancel]="false"></p-rating>
            </div>
          </div>
          <div>
            <p class="fw-1000 mr-3 mb-3 fs-6 fw-semibold">Description</p>
            <div class="description-text">
              <p>{{ aprt.apartment_Description }}</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-5">
          <div class="custom-apartment-details bg-white rounded-3 p-3">
            <h3 class="custom-apartment-title">{{ aprt.apartment_Name }}</h3>
            <p class="custom-apartment-type">{{ aprt.apartment_Type }}</p>
            <h4 class="custom-apartment-price">€{{ bedsPrice }} <span>/ month</span></h4>
            <div class="custom-apartment-info d-flex mb-0">
              <div class="custom-info-item d-flex align-items-center me-4">
                <img src="assets/images/apartmentImages/location.svg" alt="Location">
                <p>{{ aprt.apartment_Location }}</p>
              </div>
              <div class="custom-info-item d-flex align-items-center me-4">
                <img src="assets/images/apartmentImages/room_bed.svg" alt="Bedrooms">
                <p>{{noAllbed}}</p>
              </div>
              <div class="custom-info-item d-flex align-items-center">
                <img src="assets/images/apartmentImages/user.svg" alt="Guests">
                <p>Living: {{ aprt.apartment_BedRoomsNo }} persons</p>
              </div>
            </div>
            <div class="custom-info-item">
              <img src="assets/images/apartmentImages/schedule time.svg" alt="Duration">
              <p>Duration: {{ apartmentsContract?.contractDate_Start | date: 'dd/MM/yyyy' }} - {{
                apartmentsContract?.contractDate_End | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="custom-info-item">
              <img src="assets/images/apartmentImages/schedule time.svg" alt="Published">
              <p>Published: {{ apartmentsContract?.contractDate_Start | date: 'dd/MM/yyyy - hh:mm a' }}</p>
            </div>
            <div class="custom-apartment-actions d-flex justify-content-between mb-5">
              <button class="custom-btn custom-btn-edit w-75" routerLink="/apartments/page/{{aprt?.apartment_ID}}">
                <img src="assets/images/apartmentImages/edit.svg" alt="Edit" class="custom-btn-icon">Edit
              </button>
              <button class="custom-btn custom-btn-share w-25">
                <img src="assets/images/apartmentImages/share.svg" alt="Share" class="custom-btn-icon">Share
              </button>
            </div>

            <p-accordion class="mt-3">


              <p-accordionTab header="Public transport" [headerStyle]="{'background-color': '#fff' }">
                <div class="custom-transport-info">
                  <div class="custom-transport-item" *ngFor="let item of aprt.apartment_Transports">
                    <p class="custom-transport-distance">Transport within {{ item.transport_Distance }}</p>
                    <div class="custom-transport-box">{{ item.transport_Name }}</div>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab header="Apartment Equipments" [headerStyle]="{'background-color': '#fff' }">
                <div class="custom-property-item"><span class="custom-property-label">bathroom details</span>
                  <div class="custom-property-item" *ngFor="let item of allResponse.apartment_Equipments?.bathroom_Details let x=index">
                    <p class="custom-property-label">  {{ item.bathroom_Name }}</p>
                    <div class="custom-property-box" *ngFor="let i of allResponse.apartment_Equipments?.bathroom_Details[x]?.bathroom_Details">{{ i}}</div>
                  </div>
                </div>
                <div class="custom-property-item"><span class="custom-property-label">kitchen details</span>
                  <div class="custom-property-item" *ngFor="let item of allResponse.apartment_Equipments?.kitchen_Details">
                    <div class="custom-property-box" >  {{ item }}</div>
                  </div>
                </div>
                <div class="custom-property-item"><span class="custom-property-label">Apartment Features</span>
                  <div class="custom-property-item" *ngFor="let item of allResponse.apartment_Equipments?.apartment_Features">
                    <div class="custom-property-box" >  {{ item }}</div>
                  </div>
                </div>
                <div class="custom-property-item"><span class="custom-property-label">Apartment Facilites</span>
                  <div class="custom-property-item" *ngFor="let item of allResponse.apartment_Equipments?.apartment_Facilites">
                    <div class="custom-property-box" >  {{ item }}</div>
                  </div>
                </div>
                <div class="custom-property-item"><span class="custom-property-label">Rooms Details</span>
                  <div class="custom-property-item" *ngFor="let item of allResponse.apartment_Equipments?.rooms_Details let x=index">
                    <p class="custom-property-label">  {{ item.room_Name }}</p>
                    <div class="custom-property-box" *ngFor="let i of allResponse.apartment_Equipments?.rooms_Details[x]?.room_Details"> {{i}}</div>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab header="apartment Backup Info" [headerStyle]="{'background-color': '#fff' }">

                  <div class="custom-property-item" >
                    <p class="custom-property-label">Electricity meter no</p>
                    <div class="custom-property-box"> {{  allResponse.apartment_Backup_Info?.apartment_Electricity_Meter_No }}</div>
                </div>
                <div class="custom-property-item" >
                  <p class="custom-property-label">Electricity meter consumption</p>
                  <div class="custom-property-box"> {{  allResponse.apartment_Backup_Info?.apartment_Electricity_Meter_Consumption }}</div>
              </div>
              <div class="custom-property-item" >
                <p class="custom-property-label">Water meter no</p>
                <div class="custom-property-box"> {{  allResponse.apartment_Backup_Info?.apartment_Water_Meter_No}}</div>
            </div>
            <div class="custom-property-item" >
              <p class="custom-property-label">Water meter consumption</p>
              <div class="custom-property-box"> {{  allResponse.apartment_Backup_Info?.apartment_Water_Meter_Consumption}}</div>
          </div>
          <div class="custom-property-item" >
            <p class="custom-property-label">Gas meter no</p>
            <div class="custom-property-box"> {{  allResponse.apartment_Backup_Info?.apartment_Gas_Meter_No}}</div>
        </div>
        <div class="custom-property-item" >
          <p class="custom-property-label">Gas meter consumption</p>
          <div class="custom-property-box"> {{  allResponse.apartment_Backup_Info?.apartment_Gas_Meter_Consumption}}</div>
      </div>
      <div class="custom-property-item"><span class="custom-property-label">apartment_Addons_Fields</span>
        <div class="custom-property-item" *ngFor="let item of allResponse.apartment_Backup_Info?.apartment_Addons_Fields">
          <p class="custom-property-label">  {{ item.field_Name }}</p>
          <div class="custom-property-box" > {{ item.field_Content }}</div>
        </div>
      </div>
      <div class="custom-property-item">
        <span class="custom-property-label">Room Devices</span>
        <div class="custom-property-item" *ngFor="let room of allResponse.apartment_Backup_Info?.apartment_Rooms_Devices">
          <p class="custom-property-label">{{ room.room_Name }}</p>
          <div class="custom-property-box" *ngFor="let device of room.room_Devices">
            <p class="custom-property-label">{{ device.device_Name }}</p>
            <p>{{ device.device_Description }}</p>
          </div>
        </div>
      </div>


      <div class="custom-property-item">
        <span class="custom-property-label">Apartment damages images</span>
        <div class="row">
          <div class="col-6" *ngFor="let item of allResponse.apartment_Backup_Info?.apartment_Damages_Imgs">
            <img [src]="item ? item : 'https://via.placeholder.com/100x100'" class="img-fluid img-thumbnail" alt="damage Image" (click)="openImagePopup(item)">
          </div>
        </div>
      </div>


      <div *ngIf="currentImagee" class="popup" >
        <span class="close"  style="color:#c8d6e7" (click)="closePopup()">&times;</span>
        <div class="popup-content align-content-center justify-content-center">

          <img [src]="currentImagee" class="popup-image">
        </div>
      </div>
              </p-accordionTab>
              <!-- <p-accordionTab header="Apartment Damages" [headerStyle]="{'background-color': '#fff' }"  >

                <div class="custom-property-item">
                  <span class="custom-property-label">Apartment damages images</span>
                  <div class="row">
                    <div class="col-6" *ngFor="let item of aprt.apartment_Images">
                      <img [src]="item ? item : 'https://via.placeholder.com/100x100'" class="img-fluid img-thumbnail" alt="damage Image" (click)="openImagePopup(item)">
                    </div>
                  </div>
                </div>


                <div *ngIf="currentImagee" class="popup" >
                  <span class="close"  style="color:#c8d6e7" (click)="closePopup()">&times;</span>
                  <div class="popup-content align-content-center justify-content-center">

                    <img [src]="currentImagee" class="popup-image">
                  </div>
                </div>




              </p-accordionTab> -->


              <p-accordionTab header="Check Rules" [headerStyle]="{'background-color': '#fff' }">
                <div class="custom-property-item">
                  <span class="custom-property-label">Check Rules</span>
                  <div class="custom-property-label">
                    <p class="custom-property-label"> Type: </p>
                    <div class="custom-property-box" > {{allResponse.apartment_Check_Rules?.checkType }}</div>
                    <p class="custom-property-label"> WiFi Name:  </p>
                    <div class="custom-property-box" >  {{ allResponse.apartment_Check_Rules?.wifi_Name }}</div>
                    <p class="custom-property-label"> WiFi Password:  </p>
                    <div class="custom-property-box" >   {{ allResponse.apartment_Check_Rules?.wifi_Password }}</div>
                    <p class="custom-property-label"> Number of Doors:  </p>
                    <div class="custom-property-box" >    {{ allResponse.apartment_Check_Rules?.numof_Doors }}</div>
                    <p class="custom-property-label"> Mail Box Number:   </p>
                    <div class="custom-property-box" > {{ allResponse.apartment_Check_Rules?.mailBox_Num }}</div>
                    <p class="custom-property-label"> Trash Location:  </p>
                    <div class="custom-property-box" > {{ allResponse.apartment_Check_Rules?.trash_Location }}</div>
                    <p class="custom-property-label"> Apartment Location:  </p>
                    <div class="custom-property-box" >  {{ allResponse.apartment_Check_Rules?.apt_Location }}</div>
                    <p class="custom-property-label"> Safe Code:  </p>
                    <div class="custom-property-box" >{{ allResponse.apartment_Check_Rules?.safe_Code }}</div>

                    <!-- <div *ngIf="aprt.apartment_Check_Rules?.safe_Img"> -->
                      <div *ngIf="allResponse.apartment_Check_Rules?.safe_Img">
                        <p class="custom-property-label">safe image</p>
                      <img [src]="allResponse.apartment_Check_Rules?.safe_Img" class="img-fluid img-thumbnail" alt="Safe Image" (click)="openImagePopup(aprt.apartment_Check_Rules.safe_Img)">
                    </div>
                    <div *ngIf="allResponse.apartment_Check_Rules?.door_Img">
                      <p class="custom-property-label">door image</p>
                      <img [src]="allResponse.apartment_Check_Rules?.door_Img" class="img-fluid img-thumbnail" alt="Door Image" (click)="openImagePopup(aprt.apartment_Check_Rules.door_Img)">
                    </div>
                    <div *ngIf="allResponse.apartment_Check_Rules?.building_Img">
                      <p class="custom-property-label">building image</p>
                      <img [src]="allResponse.apartment_Check_Rules?.building_Img" class="img-fluid img-thumbnail" alt="Building Image" (click)="openImagePopup(aprt.apartment_Check_Rules.building_Img)">
                    </div>

                    <div *ngFor="let rule of allResponse.apartment_Check_Rules?.apt_rules">

                      <p class="custom-property-label">rule description:  </p>
                      <div class="custom-property-box " style="font-size: 12px; word-wrap: break-word;" >{{ rule.rule_Desc }}</div>
                    </div>
                  </div>

                  <div class="custom-property-item">
                    <span class="custom-property-label">Trash bin images</span>
                    <div class="row">
                      <div class="col-6" *ngFor="let item of allResponse.apartment_Check_Rules?.tarsh_Pin_Imgs">
                        <img [src]="item.pic_URL ? item.pic_URL : 'https://via.placeholder.com/100x100'" class="img-fluid img-thumbnail" alt="trash bin Image" (click)="openImagePopup(item.pic_URL)">
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div *ngIf="currentImage" class="popup">
                  <span class="close" style="color:#c8d6e7" (click)="closePopup()">&times;</span>
                  <div class="popup-content align-content-center justify-content-center">
                    <img [src]="currentImage" class="popup-image">
                  </div>
                </div> -->
              </p-accordionTab>

              <p-accordionTab header="Apartment Contract" [headerStyle]="{'background-color': '#fff' }">
                <div class="custom-property-item">
                  <span class="custom-property-label">Apartment Contract</span>
                  <div class="custom-property-label">

                    <p class="custom-property-label">Landlord:</p>
                    <div class="custom-property-box">{{ allResponse.apartment_Contract.landLord }}</div>

                    <p class="custom-property-label">Tenant Name:</p>
                    <div class="custom-property-box">{{ allResponse.apartment_Contract.tenantName }}</div>

                    <p class="custom-property-label">Contract Start Date:</p>
                    <div class="custom-property-box">{{ allResponse.apartment_Contract.contractDate_Start | date:'fullDate' }}</div>

                    <p class="custom-property-label">Contract End Date:</p>
                    <div class="custom-property-box">{{ allResponse.apartment_Contract.contractDate_End | date:'fullDate' }}</div>

                    <p class="custom-property-label">Rent Fees:</p>
                    <div class="custom-property-box">{{ allResponse.apartment_Contract.rent_Fees }}</div>

                    <p class="custom-property-label">Digital Contract:</p>
                    <div class="custom-property-box">{{ allResponse.apartment_Contract.digital_Contract ? 'Yes' : 'No' }}</div>

                    <p class="custom-property-label">Contract Path:</p>
                    <div class="custom-property-box">
                      <a [href]="allResponse.apartment_Contract.contract_Path" target="_blank">View Contract</a>
                    </div>

                    <div class="contract-details">
                      <h6 class="mt-3">Contract Details</h6>
                      <div *ngFor="let detail of allResponse.apartment_Contract.contractDetails">
                        <p class="custom-property-label">Section Name:</p>
                        <div class="custom-property-box">{{ detail.sec_Name || 'N/A' }}</div>

                        <p class="custom-property-label">Section Description:</p>
                        <div class="custom-property-box">{{ detail.sec_Desc || 'N/A' }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-accordionTab>


              <!-- <p-accordionTab header="Rooms Devices" [headerStyle]="{'background-color': '#fff' }">
                <div class="custom-property-item">
                  <span class="custom-property-label">Room Devices</span>
                  <div class="custom-property-item" *ngFor="let room of aprt.apartment_Rooms_Devices">
                    <p class="custom-property-label">{{ room.room_Name }}</p>
                    <div class="custom-property-box" *ngFor="let device of room.room_Devices">
                      <p class="custom-property-label">{{ device.device_Name }}</p>
                      <p>{{ device.device_Description }}</p>
                    </div>
                  </div>
                </div>
              </p-accordionTab> -->
              <p-accordionTab header="Basic apartment details" [headerStyle]="{'background-color': '#fff' }">
                <div class="custom-property-info">
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment number</p>
                    <div class="custom-property-box">{{ aprt.apartment_No }} </div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment floor</p>
                    <div class="custom-property-box">{{ aprt.apartment_Floor }}</div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment street</p>
                    <div class="custom-property-box">{{ aprt.apartment_StreetName }} </div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment building</p>
                    <div class="custom-property-box">{{ aprt.apartment_BuildingName }}</div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment city</p>
                    <div class="custom-property-box">{{ aprt.apartment_City }}</div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment_Area</p>
                    <div class="custom-property-box">{{ aprt.apartment_Area }}</div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment area square</p>
                    <div class="custom-property-box">{{ aprt.apartment_Area_Square }}</div>
                  </div>

                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment video link</p>
                    <div class="custom-property-box fs-6" style="font-size: 12px; word-wrap: break-word;"><a href="{{ aprt.apartment_GoogleLocation }}" target="_blank">{{ aprt.apartment_VideoLink }}</a> </div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment google location</p>
                    <div class="custom-property-box " style="font-size: 12px; word-wrap: break-word;"> <a href="{{ aprt.apartment_GoogleLocation }}" target="_blank">{{ aprt.apartment_GoogleLocation }}</a> </div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment 360DLink</p>
                    <div class="custom-property-box" style="font-size: 12px; word-wrap: break-word;"> <a href="{{ aprt.apartment_GoogleLocation }}" target="_blank">{{ aprt.apartment_360DLink }}</a> </div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment bedRooms No</p>
                    <div class="custom-property-box">{{ aprt.apartment_BedRoomsNo }}</div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment bathroom No</p>
                    <div class="custom-property-box">{{ aprt.apartment_BathroomNo }}</div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment shared area</p>
                    <div *ngIf="aprt.apartment_SharedArea" class="custom-property-box">Yes</div>
                    <div *ngIf="!aprt.apartment_SharedArea" class="custom-property-box">No</div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment sleeping area</p>
                    <div class="custom-property-box">{{ aprt.apartment_SleepingArea }}</div>
                  </div>
                  <div class="custom-property-item">
                    <p class="custom-property-label">apartment elevator</p>
                    <div *ngIf="aprt.apartment_Elevator" class="custom-property-box">Yes</div>
                    <div *ngIf="!aprt.apartment_Elevator" class="custom-property-box">No</div>
                  </div>

                </div>
              </p-accordionTab>
            </p-accordion>

            <div class="accord"></div>
            <div class="custom-apartment-buttons mt-5">
              <button class="custom-btn-rented">Mark as rented</button>
              <button class="custom-btn-delete">
                <img src="assets/images/delete3.svg">Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="qr-code-container">
        <div class="qr-code-details">
          <span class="qr-title">Apartment QR Code :</span>
          <span class="qr-code">{{ aprt.apartment_QRCode }}</span>
        </div>
        <div class="qr-actions">
          <a class="qr-action"
            (click)="onOpenQrModal(aprt.apartment_QR_Img, aprt.apartment_QRCode,aprt.apartment_Type,aprt.apartment_Code,1)">
            <i class="pi pi-eye"></i> View
          </a>
          <a class="qr-action">
            <i class="pi pi-download"></i> Download
          </a>
          <a class="qr-action">
            <i class="pi pi-share-alt"></i> Share
          </a>
          <button class="btn-assign">Assign worker</button>
        </div>
      </div>

      <!--
    hide room Details
      <div class="row m-0">
        <div class="col-lg-12">
          <div class="mt-4">
            <div class="card bookingdetails">
              <div class="card-header">
                <h4 class="fs-5 fw-bold">Beds Details</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between px-4">
                      <div class="fw-bold">Bed No</div>
                      <div class="fw-bold">Room type</div>
                      <div class="fw-bold">Bed price</div>
                      <div class="fw-bold">Security deposit</div>
                      <div class="fw-bold">Service fee</div>
                      <div class="fw-bold">Status</div>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <p-accordion id="bed" [multiple]="true" [activeIndex]="[0]">
                    <p-accordionTab [selected]="true" [headerStyle]="{'background-color': '#fff' }"
                      *ngFor="let room of aprt.apartment_Rooms">
                      <ng-template pTemplate="header">
                        <div class="d-flex justify-content-between align-items-center m-0 rounded w-100">
                          <div class="fw-bold">{{ room.beds_No }}</div>
                          <div>{{ room.room_Type }}</div>
                          <div>€ {{ room.bed_Price }}</div>
                          <div>€ {{ room.bed_SecuirtyDeposit }}</div>
                          <div>€ {{ room.bed_Service_Fees }}</div>
                          <div class="badge rented" *ngIf="!room.room_Available">Rented <img
                              src="/assets/images/expand_more.svg" alt=""></div>
                          <div class="badge rented" *ngIf="room.room_Available">Available <img
                              src="/assets/images/expand_more.svg" alt=""></div>
                        </div>
                      </ng-template>

                      <div class="row bg-white m-0 p-4 mb-3">
                        <div class="col-12 m-0 p-3 rounded pl-4 pr-4 bginfo">
                          <div class="row align-items-center">
                            <div class="col-auto">
                              <img src="https://via.placeholder.com/50" class="rounded-circle" alt="Profile Image">
                            </div>
                            <div class="col-lg-3 border-end">
                              <h6 class="fw-bold text-lowercase">Osman Ahmed</h6>
                              <p class="m-0 text-muted">osman2025@gmail.com</p>
                            </div>
                            <div class="col-lg-3 text-right border-end">
                              <p class="m-0"><strong>Check In Date</strong><br>12 Jun, 2024</p>
                            </div>
                            <div class="col-lg-3 text-right border-end">
                              <p class="m-0"><strong>Check Out Date</strong><br>12 Jun, 2025</p>
                            </div>
                            <div class="col-lg-2 text-right">
                              <p class="m-0"><strong>Phone Number</strong><br>+20 1154652523</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="tenant-documents m-0">
                        <div class="header">
                          <h3>Tenant Documents</h3>
                          <p>View tenant Documents</p>
                        </div>
                        <div class="documents-container row">
                          <div class="col-md-6">
                            <div class="document-header">QR Details</div>
                            <div class="document-content">
                              <div class="doc-info">
                                <p>Qr Code:{{ room.room_QRCode }}</p>
                              </div>
                              <div class="doc-actions">
                                <button class="btn-view" (click)="onOpenQrModal(room.room_QR_Img)"><i class="pi pi-eye"></i> View</button>
                                <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                                <button class="btn-share"><i class="pi pi-share"></i> Share</button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="document-header">Contract</div>
                            <div class="document-content">
                              <div class="doc-info">
                                <img src="assets/images/document.svg" alt="Contract" />
                                <p>Contract.pdf</p>
                              </div>
                              <div class="doc-actions">
                                <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="document-header">Handover protocols</div>
                            <div class="document-content">
                              <div class="doc-info">
                                <img src="assets/images/document.svg" alt="Handover protocols" />
                                <p>Handover protocols.pdf</p>
                              </div>
                              <div class="doc-actions">
                                <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="document-header">Rental Rules</div>
                            <div class="document-content">

                              <div class="doc-info">
                                <img src="assets/images/document.svg" alt="Handover protocols" />
                                <p>Rental Rules.pdf</p>
                              </div>
                              <div class="doc-actions">
                                <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row bg-white m-0 p-4" id="more-details">
                        <div class="col-12 m-0 p-3 rounded pl-4 pr-4">
                          <div class="row align-items-center">
                            <div class="container mt-3">
                              <h6 class="fw-bold text-lowercase">More details</h6>
                              <p class="text-muted">View tenant passport and reported problems, invoices</p>
                              <div class="accordion" id="accordionExample">
                                <div class="container mt-3">
                                  <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <div><strong>Passport of tenent</strong></div>
                                    <div><i class="fas fa-chevron-down text-muted"></i></div>
                                  </div>
                                </div>
                                <div class="container mt-3">
                                  <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <div><strong>Problem reports (2)</strong></div>
                                    <div><i class="fas fa-chevron-down text-muted"></i></div>
                                  </div>
                                </div>
                                <div class="container mt-3">
                                  <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <div><strong>Fixed problem (0)</strong></div>
                                    <div><i class="fas fa-chevron-down text-muted"></i></div>
                                  </div>
                                </div>
                                <div class="container mt-3">
                                  <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <div><strong>Pending Invoices</strong></div>
                                    <div><i class="fas fa-chevron-down text-muted"></i></div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex justify-content-end mt-3">
                                <button class="btn custom-btn-outline-primary ml-3">Unpublish</button>
                                <button class="btn btn-primary" style="border-radius: 10px; font-size: 14px;">Mark as
                                  Available</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    -->

      <div *ngIf="showRoomDesign" class="row m-0">
        <div class="col-lg-12">
          <div class="mt-4">
            <div class="card bookingdetails">
              <div class="card-header">
                <h4 class="fs-5 fw-bold">Rooms Details</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between px-4">
                      <div class="fw-bold">Bed No</div>
                      <div class="fw-bold">Room type</div>
                      <div class="fw-bold">Bed price</div>
                      <div class="fw-bold">Security deposit</div>
                      <div class="fw-bold">Service fee</div>
                      <div class="fw-bold">Status</div>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <p-accordion id="bed" [multiple]="true" [activeIndex]="[0]">
                    <p-accordionTab [selected]="true" [headerStyle]="{'background-color': '#fff' }"
                      *ngFor="let room of aprt.apartment_Rooms let i = index">
                      <ng-template pTemplate="header">
                        <div class="d-flex justify-content-between align-items-center m-0 rounded w-100">
                          <div class="fw-bold">{{ room.beds_No }}</div>
                          <div>{{ room.room_Type }}</div>
                          <div>€ {{ room.bed_Price }}</div>
                          <div>€ {{ room.bed_SecuirtyDeposit }}</div>
                          <div>€ {{ room.bed_Service_Fees }}</div>
                          <div class="badge rented" *ngIf="!room.room_Available">Rented <img
                              src="/assets/images/expand_more.svg" alt=""></div>
                          <div class="badge rented" *ngIf="room.room_Available">Available <img
                              src="/assets/images/expand_more.svg" alt=""></div>
                        </div>
                      </ng-template>

                      <!-- <div class="row bg-white m-0 p-4 mb-3">
                      <div class="col-12 m-0 p-3 rounded pl-4 pr-4 bginfo">
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <img src="https://via.placeholder.com/50" class="rounded-circle" alt="Profile Image">
                          </div>
                          <div class="col-lg-3 border-end">
                            <h6 class="fw-bold text-lowercase">Osman Ahmed</h6>
                            <p class="m-0 text-muted">osman2025&#64;gmail.com</p>
                          </div>
                          <div class="col-lg-3 text-right border-end">
                            <p class="m-0"><strong>Check In Date</strong><br>12 Jun, 2024</p>
                          </div>
                          <div class="col-lg-3 text-right border-end">
                            <p class="m-0"><strong>Check Out Date</strong><br>12 Jun, 2025</p>
                          </div>
                          <div class="col-lg-2 text-right">
                            <p class="m-0"><strong>Phone Number</strong><br>+20 1154652523</p>
                          </div>
                        </div>
                      </div>
                    </div>






                    <div class="tenant-documents m-0 mb-3"  *ngFor="let bed of room.room_Beds">

                      <div class="header">
                        <h3>bed {{bed.bed_No}}</h3>
                        <hr/>
                        <h3>Tenant Documents</h3>
                        <p>View tenant Documents</p>
                      </div>
                      <div class="documents-container row">
                        <div class="col-md-6">
                          <div class="document-header">QR Details</div>
                          <div class="document-content">
                            <div class="doc-info">
                              <p>Qr Code:{{ bed.bed_QRCode }}</p>
                            </div>
                            <div class="doc-actions">
                              <button class="btn-view" (click)="onOpenQrModal(bed.bed_QR_Img, bed.bed_QRCode)"><i class="pi pi-eye"></i> View</button>
                              <button class="btn-download" (click)="downloadImage(bed.bed_QR_Img)"><i class="pi pi-download" ></i> Download</button>
                              <button class="btn-share"><i class="pi pi-share"></i> Share</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="document-header">Contract</div>
                          <div class="document-content">
                            <div class="doc-info">
                              <img src="assets/images/document.svg" alt="Contract" />
                              <p>Contract.pdf</p>
                            </div>
                            <div class="doc-actions">
                              <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                              <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="document-header">Handover protocols</div>
                          <div class="document-content">
                            <div class="doc-info">
                              <img src="assets/images/document.svg" alt="Handover protocols" />
                              <p>Handover protocols.pdf</p>
                            </div>
                            <div class="doc-actions">
                              <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                              <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="document-header">Rental Rules</div>
                          <div class="document-content">

                            <div class="doc-info">
                              <img src="assets/images/document.svg" alt="Handover protocols" />
                              <p>Rental Rules.pdf</p>
                            </div>
                            <div class="doc-actions">
                              <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                              <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row bg-white m-0 p-4" id="more-details">
                      <div class="col-12 m-0 p-3 rounded pl-4 pr-4">
                        <div class="row align-items-center">
                          <div class="container mt-3">
                            <h6 class="fw-bold text-lowercase">More details</h6>
                            <p class="text-muted">View tenant passport and reported problems, invoices</p>
                            <div class="accordion" id="accordionExample">
                              <div class="container mt-3">
                                <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                  <div><strong>Passport of tenent</strong></div>
                                  <div><i class="fas fa-chevron-down text-muted"></i></div>
                                </div>
                              </div>
                              <div class="container mt-3">
                                <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                  <div><strong>Problem reports (2)</strong></div>
                                  <div><i class="fas fa-chevron-down text-muted"></i></div>
                                </div>
                              </div>
                              <div class="container mt-3">
                                <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                  <div><strong>Fixed problem (0)</strong></div>
                                  <div><i class="fas fa-chevron-down text-muted"></i></div>
                                </div>
                              </div>
                              <div class="container mt-3">
                                <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                  <div><strong>Pending Invoices</strong></div>
                                  <div><i class="fas fa-chevron-down text-muted"></i></div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                              <button class="btn custom-btn-outline-primary ml-3">Unpublish</button>
                              <button class="btn btn-primary" style="border-radius: 10px; font-size: 14px;">Mark as
                                Available</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->


                      <div *ngIf="showRoomDesign" class="row m-0">
                        <div class="col-lg-12">
                          <div class="mt-4">
                            <div class="card bookingdetails">
                              <div class="card-header">
                                <h4 class="fs-5 fw-bold">Beds Details</h4>
                              </div>
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-12 mb-3">
                                    <div class="d-flex justify-content-between px-2">
                                      <div class="fw-bold">Bed No</div>
                                      <!-- <div class="fw-bold">Room type</div> -->
                                      <!-- <div class="fw-bold">Bed price</div> -->
                                      <div class="fw-bold">Check In</div>
                                      <div class="fw-bold">Check Out</div>
                                      <div class="fw-bold">Status</div>
                                    </div>
                                  </div>
                                </div>

                                <div class="mb-4">
                                  <p-accordion id="bed" [multiple]="true" [activeIndex]="[]">
                                    <ng-container *ngFor="let bed of room.room_Beds   ">
                                      <p-accordionTab [selected]="true" [headerStyle]="{'background-color': '#fff' }">
                                        <ng-template pTemplate="header">
                                          <div
                                            class="d-flex justify-content-between align-items-center m-0  rounded w-100">
                                            <div class="fw-bold">{{ bed.bed_No }} </div>
                                            <!-- <div> Tenant Name</div> -->
                                            <div>11/2/2024</div>
                                            <div>22/3/2024</div>
                                            <!-- <div>€ {{ room.bed_Service_Fees }}</div> -->
                                            <div class="badge rented" *ngIf="!bed.bed_Available">Rented <img
                                                src="/assets/images/expand_more.svg" alt=""></div>
                                            <div class="badge rented" *ngIf="bed.bed_Available">Available <img
                                                src="/assets/images/expand_more.svg" alt=""></div>
                                          </div>
                                        </ng-template>

                                        <div class="row bg-white m-0 p-4 mb-3">
                                          <div class="col-12 m-0 p-3 rounded pl-4 pr-4 bginfo">
                                            <div class="row align-items-center ">
                                              <div class="col-auto">
                                                <img src="https://via.placeholder.com/50" class="rounded-circle"
                                                  alt="Profile Image">
                                              </div>
                                              <div class="col-lg-3 border-end">
                                                <h6 class="fw-bold text-lowercase">Osman Ahmed</h6>
                                                <p class="m-0 text-muted">osman2025&#64;gmail.com</p>
                                              </div>
                                              <!-- <div class="col-lg-3 text-right border-end">
                                                <p class="m-0"><strong>Check In Date</strong><br>12 Jun, 2024</p>
                                              </div>
                                              <div class="col-lg-3 text-right border-end">
                                                <p class="m-0"><strong>Check Out Date</strong><br>12 Jun, 2025</p>
                                              </div> -->
                                              <div class="col-lg-2 text-right">
                                                <p class="m-0"><strong>Phone Number</strong><br>+20 1154652523</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>






                                        <div class="tenant-documents m-0 mb-3">

                                          <div class="header">
                                            <h3>bed {{bed.bed_No}}</h3>
                                            <hr />
                                            <h3>Tenant Documents</h3>
                                            <p>View tenant Documents</p>
                                          </div>
                                          <div class="documents-container row">
                                            <div class="col-md-6">
                                              <div class="document-header">QR Details</div>
                                              <div class="document-content">
                                                <div class="doc-info">
                                                  <p>Qr Code:{{ bed.bed_QRCode }}</p>
                                                </div>
                                                <div class="doc-actions">
                                                  <button class="btn-view"
                                                    (click)="onOpenQrModal(bed.bed_QR_Img, bed.bed_QRCode,room.room_Type,aprt.apartment_Code,i+1)"><i
                                                      class="pi pi-eye"></i> View</button>
                                                  <button class="btn-download capture"
                                                   (click)="printDiv('qrToPdf')"><i
                                                      class="pi pi-download"></i> Download</button>
                                                  <!-- <p>{{bed.bed_QR_Img}}</p> -->
                                                  <button class="btn-share"><i class="pi pi-share"></i> Share</button>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="document-header">Contract</div>
                                              <div class="document-content">
                                                <div class="doc-info">
                                                  <img src="assets/images/document.svg" alt="Contract" />
                                                  <p>Contract.pdf</p>
                                                </div>
                                                <div class="doc-actions">
                                                  <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                                  <button class="btn-download"><i class="pi pi-download"></i>
                                                    Download</button>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="document-header">Handover protocols</div>
                                              <div class="document-content">
                                                <div class="doc-info">
                                                  <img src="assets/images/document.svg" alt="Handover protocols" />
                                                  <p>Handover protocols.pdf</p>
                                                </div>
                                                <div class="doc-actions">
                                                  <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                                  <button class="btn-download"><i class="pi pi-download"></i>
                                                    Download</button>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="document-header">Rental Rules</div>
                                              <div class="document-content">
                                                <!-- <div class="doc-info">
                                              <p>Qr Code:{{ room.room_QRCode }}</p>
                                            </div>
                                            <div class="doc-actions">
                                              <button class="btn-view" (click)="onOpenQrModal(room.room_QR_Img)"><i class="pi pi-eye"></i> View</button>
                                              <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                                            </div> -->
                                                <div class="doc-info">
                                                  <img src="assets/images/document.svg" alt="Handover protocols" />
                                                  <p>Rental Rules.pdf</p>
                                                </div>
                                                <div class="doc-actions">
                                                  <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                                  <button class="btn-download"><i class="pi pi-download"></i>
                                                    Download</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="row bg-white m-0 p-4" id="more-details">
                                          <div class="col-12 m-0 p-3 rounded pl-4 pr-4">
                                            <div class="row align-items-center">
                                              <div class="container mt-3">
                                                <h6 class="fw-bold text-lowercase">More details</h6>
                                                <p class="text-muted">View tenant passport and reported problems,
                                                  invoices</p>
                                                <div class="accordion" id="accordionExample">
                                                  <div class="container mt-3">
                                                    <div
                                                      class="d-flex justify-content-between align-items-center border-bottom py-2">
                                                      <div><strong>Passport of tenent</strong></div>
                                                      <div><i class="fas fa-chevron-down text-muted"></i></div>
                                                    </div>
                                                  </div>
                                                  <div class="container mt-3">
                                                    <div
                                                      class="d-flex justify-content-between align-items-center border-bottom py-2">
                                                      <div><strong>Problem reports (2)</strong></div>
                                                      <div><i class="fas fa-chevron-down text-muted"></i></div>
                                                    </div>
                                                  </div>
                                                  <div class="container mt-3">
                                                    <div
                                                      class="d-flex justify-content-between align-items-center border-bottom py-2">
                                                      <div><strong>Fixed problem (0)</strong></div>
                                                      <div><i class="fas fa-chevron-down text-muted"></i></div>
                                                    </div>
                                                  </div>
                                                  <div class="container mt-3">
                                                    <div
                                                      class="d-flex justify-content-between align-items-center border-bottom py-2">
                                                      <div><strong>Pending Invoices</strong></div>
                                                      <div><i class="fas fa-chevron-down text-muted"></i></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="d-flex justify-content-end mt-3">
                                                  <button class="btn custom-btn-outline-primary ml-3">Unpublish</button>
                                                  <button class="btn btn-primary"
                                                    style="border-radius: 10px; font-size: 14px;">Mark as
                                                    Available</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </p-accordionTab>
                                    </ng-container>
                                  </p-accordion>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>







                    </p-accordionTab>

                  </p-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!showRoomDesign" class="row m-0">
        <div class="col-lg-12">
          <div class="mt-4">
            <div class="card bookingdetails">
              <div class="card-header">
                <h4 class="fs-5 fw-bold">Beds Details</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between px-2">
                      <div class="fw-bold">Bed No</div>
                      <!-- <div class="fw-bold">Room type</div> -->
                      <!-- <div class="fw-bold">Bed price</div> -->
                      <div class="fw-bold">Check In</div>
                      <div class="fw-bold">Check Out</div>
                      <div class="fw-bold">Status</div>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <p-accordion id="bed" [multiple]="true" [activeIndex]="[]">
                    <ng-container *ngFor="let bed of aprt?.apartment_Rooms[0]?.room_Beds">
                      <p-accordionTab [selected]="true" [headerStyle]="{'background-color': '#fff' }">
                        <ng-template pTemplate="header">
                          <div
                            class="d-flex justify-content-between align-items-center m-0  rounded w-100">
                            <div class="fw-bold">{{ bed?.bed_No }} </div>
                            <!-- <div> Tenant Name</div> -->
                            <div>11/2/2024</div>
                            <div>22/3/2024</div>
                            <!-- <div>€ {{ room.bed_Service_Fees }}</div> -->
                            <div class="badge rented" *ngIf="!bed?.bed_Available">Rented <img
                                src="/assets/images/expand_more.svg" alt=""></div>
                            <div class="badge rented" *ngIf="bed?.bed_Available">Available <img
                                src="/assets/images/expand_more.svg" alt=""></div>
                          </div>
                        </ng-template>

                        <div class="row bg-white m-0 p-4 mb-3">
                          <div class="col-12 m-0 p-3 rounded pl-4 pr-4 bginfo">
                            <div class="row align-items-center ">
                              <div class="col-auto">
                                <img src="https://via.placeholder.com/50" class="rounded-circle"
                                  alt="Profile Image">
                              </div>
                              <div class="col-lg-3 border-end">
                                <h6 class="fw-bold text-lowercase">Osman Ahmed</h6>
                                <p class="m-0 text-muted">osman2025&#64;gmail.com</p>
                              </div>
                              <!-- <div class="col-lg-3 text-right border-end">
                                <p class="m-0"><strong>Check In Date</strong><br>12 Jun, 2024</p>
                              </div>
                              <div class="col-lg-3 text-right border-end">
                                <p class="m-0"><strong>Check Out Date</strong><br>12 Jun, 2025</p>
                              </div> -->
                              <div class="col-lg-2 text-right">
                                <p class="m-0"><strong>Phone Number</strong><br>+20 1154652523</p>
                              </div>
                            </div>
                          </div>
                        </div>






                        <div class="tenant-documents m-0 mb-3">

                          <div class="header">
                            <h3>bed {{bed.bed_No}}</h3>
                            <hr />
                            <h3>Tenant Documents</h3>
                            <p>View tenant Documents</p>
                          </div>
                          <div class="documents-container row">
                            <div class="col-md-6">
                              <div class="document-header">QR Details</div>
                              <div class="document-content">
                                <div class="doc-info">
                                  <p>Qr Code:{{ bed.bed_QRCode }}</p>
                                </div>
                                <div class="doc-actions">
                                  <button class="btn-view"
                                    (click)="onOpenQrModal(bed.bed_QR_Img, bed.bed_QRCode,aprt.apartment_Rooms.room_Type,aprt.apartment_Code,1)"><i
                                      class="pi pi-eye"></i> View</button>
                                  <button class="btn-download capture"
                                   (click)="printDiv('qrToPdf')"><i
                                      class="pi pi-download"></i> Download</button>
                                  <!-- <p>{{bed.bed_QR_Img}}</p> -->
                                  <button class="btn-share"><i class="pi pi-share"></i> Share</button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="document-header">Contract</div>
                              <div class="document-content">
                                <div class="doc-info">
                                  <img src="assets/images/document.svg" alt="Contract" />
                                  <p>Contract.pdf</p>
                                </div>
                                <div class="doc-actions">
                                  <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                  <button class="btn-download"><i class="pi pi-download"></i>
                                    Download</button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="document-header">Handover protocols</div>
                              <div class="document-content">
                                <div class="doc-info">
                                  <img src="assets/images/document.svg" alt="Handover protocols" />
                                  <p>Handover protocols.pdf</p>
                                </div>
                                <div class="doc-actions">
                                  <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                  <button class="btn-download"><i class="pi pi-download"></i>
                                    Download</button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="document-header">Rental Rules</div>
                              <div class="document-content">
                                <!-- <div class="doc-info">
                              <p>Qr Code:{{ room.room_QRCode }}</p>
                            </div>
                            <div class="doc-actions">
                              <button class="btn-view" (click)="onOpenQrModal(room.room_QR_Img)"><i class="pi pi-eye"></i> View</button>
                              <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                            </div> -->
                                <div class="doc-info">
                                  <img src="assets/images/document.svg" alt="Handover protocols" />
                                  <p>Rental Rules.pdf</p>
                                </div>
                                <div class="doc-actions">
                                  <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                  <button class="btn-download"><i class="pi pi-download"></i>
                                    Download</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row bg-white m-0 p-4" id="more-details">
                          <div class="col-12 m-0 p-3 rounded pl-4 pr-4">
                            <div class="row align-items-center">
                              <div class="container mt-3">
                                <h6 class="fw-bold text-lowercase">More details</h6>
                                <p class="text-muted">View tenant passport and reported problems,
                                  invoices</p>
                                <div class="accordion" id="accordionExample">
                                  <div class="container mt-3">
                                    <div
                                      class="d-flex justify-content-between align-items-center border-bottom py-2">
                                      <div><strong>Passport of tenent</strong></div>
                                      <div><i class="fas fa-chevron-down text-muted"></i></div>
                                    </div>
                                  </div>
                                  <div class="container mt-3">
                                    <div
                                      class="d-flex justify-content-between align-items-center border-bottom py-2">
                                      <div><strong>Problem reports (2)</strong></div>
                                      <div><i class="fas fa-chevron-down text-muted"></i></div>
                                    </div>
                                  </div>
                                  <div class="container mt-3">
                                    <div
                                      class="d-flex justify-content-between align-items-center border-bottom py-2">
                                      <div><strong>Fixed problem (0)</strong></div>
                                      <div><i class="fas fa-chevron-down text-muted"></i></div>
                                    </div>
                                  </div>
                                  <div class="container mt-3">
                                    <div
                                      class="d-flex justify-content-between align-items-center border-bottom py-2">
                                      <div><strong>Pending Invoices</strong></div>
                                      <div><i class="fas fa-chevron-down text-muted"></i></div>
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex justify-content-end mt-3">
                                  <button class="btn custom-btn-outline-primary ml-3">Unpublish</button>
                                  <button class="btn btn-primary"
                                    style="border-radius: 10px; font-size: 14px;">Mark as
                                    Available</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </p-accordionTab>
                    </ng-container>
                  </p-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <!--
      <div class="row m-0">
        <div class="col-lg-12">
          <div class="mt-4">
            <div class="card bookingdetails">
              <div class="card-header">
                <h4 class="fs-5 fw-bold">Beds Details</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between px-4">
                      <div class="fw-bold">Bed No</div>
                      <div class="fw-bold">Room type</div>
                      <div class="fw-bold">Bed price</div>
                      <div class="fw-bold">Security deposit</div>
                      <div class="fw-bold">Service fee</div>
                      <div class="fw-bold">Status</div>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <p-accordion id="bed" [multiple]="true" [activeIndex]="[0]">
                    <ng-container *ngFor="let room of aprt.apartment_Rooms; ">
                    <p-accordionTab [selected]="true" [headerStyle]="{'background-color': '#fff' }"
                    *ngFor="let bed of room.room_Beds ;  index  " >
                      <ng-template pTemplate="header">
                        <div class="d-flex justify-content-between align-items-center m-0 rounded w-100">
                          <div class="fw-bold">{{ bed.bed_number }}  </div>
                          <div>{{ room.room_Type }}</div>
                          <div>€ {{ room.bed_Price }}</div>
                          <div>€ {{ room.bed_SecuirtyDeposit }}</div>
                          <div>€ {{ room.bed_Service_Fees }}</div>
                          <div class="badge rented" *ngIf="!bed.bed_Available">Rented <img
                              src="/assets/images/expand_more.svg" alt=""></div>
                          <div class="badge rented" *ngIf="bed.bed_Available">Available <img
                              src="/assets/images/expand_more.svg" alt=""></div>
                        </div>
                      </ng-template>

                      <div class="row bg-white m-0 p-4 mb-3">
                        <div class="col-12 m-0 p-3 rounded pl-4 pr-4 bginfo">
                          <div class="row align-items-center">
                            <div class="col-auto">
                              <img src="https://via.placeholder.com/50" class="rounded-circle" alt="Profile Image">
                            </div>
                            <div class="col-lg-3 border-end">
                              <h6 class="fw-bold text-lowercase">Osman Ahmed</h6>
                              <p class="m-0 text-muted">osman2025&#64;gmail.com</p>
                            </div>
                            <div class="col-lg-3 text-right border-end">
                              <p class="m-0"><strong>Check In Date</strong><br>12 Jun, 2024</p>
                            </div>
                            <div class="col-lg-3 text-right border-end">
                              <p class="m-0"><strong>Check Out Date</strong><br>12 Jun, 2025</p>
                            </div>
                            <div class="col-lg-2 text-right">
                              <p class="m-0"><strong>Phone Number</strong><br>+20 1154652523</p>
                            </div>
                          </div>
                        </div>
                      </div>






                      <div class="tenant-documents m-0 mb-3"   >

                        <div class="header">
                          <h3>bed {{bed.bed_No}}</h3>
                          <hr/>
                          <h3>Tenant Documents</h3>
                          <p>View tenant Documents</p>
                        </div>
                        <div class="documents-container row">
                          <div class="col-md-6">
                            <div class="document-header">QR Details</div>
                            <div class="document-content">
                              <div class="doc-info">
                                <p>Qr Code:{{ bed.bed_QRCode }}</p>
                              </div>
                              <div class="doc-actions">
                                <button class="btn-view" (click)="onOpenQrModal(bed.bed_QR_Img, bed.bed_QRCode)"><i class="pi pi-eye"></i> View</button>
                                <button class="btn-download capture" (click)="downloadImage(bed.bed_QR_Img)"><i class="pi pi-download" ></i> Download</button>

                                <button class="btn-share"><i class="pi pi-share"></i> Share</button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="document-header">Contract</div>
                            <div class="document-content">
                              <div class="doc-info">
                                <img src="assets/images/document.svg" alt="Contract" />
                                <p>Contract.pdf</p>
                              </div>
                              <div class="doc-actions">
                                <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="document-header">Handover protocols</div>
                            <div class="document-content">
                              <div class="doc-info">
                                <img src="assets/images/document.svg" alt="Handover protocols" />
                                <p>Handover protocols.pdf</p>
                              </div>
                              <div class="doc-actions">
                                <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="document-header">Rental Rules</div>
                            <div class="document-content">

                              <div class="doc-info">
                                <img src="assets/images/document.svg" alt="Handover protocols" />
                                <p>Rental Rules.pdf</p>
                              </div>
                              <div class="doc-actions">
                                <button class="btn-view"><i class="pi pi-eye"></i> View</button>
                                <button class="btn-download"><i class="pi pi-download"></i> Download</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row bg-white m-0 p-4" id="more-details">
                        <div class="col-12 m-0 p-3 rounded pl-4 pr-4">
                          <div class="row align-items-center">
                            <div class="container mt-3">
                              <h6 class="fw-bold text-lowercase">More details</h6>
                              <p class="text-muted">View tenant passport and reported problems, invoices</p>
                              <div class="accordion" id="accordionExample">
                                <div class="container mt-3">
                                  <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <div><strong>Passport of tenent</strong></div>
                                    <div><i class="fas fa-chevron-down text-muted"></i></div>
                                  </div>
                                </div>
                                <div class="container mt-3">
                                  <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <div><strong>Problem reports (2)</strong></div>
                                    <div><i class="fas fa-chevron-down text-muted"></i></div>
                                  </div>
                                </div>
                                <div class="container mt-3">
                                  <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <div><strong>Fixed problem (0)</strong></div>
                                    <div><i class="fas fa-chevron-down text-muted"></i></div>
                                  </div>
                                </div>
                                <div class="container mt-3">
                                  <div class="d-flex justify-content-between align-items-center border-bottom py-2">
                                    <div><strong>Pending Invoices</strong></div>
                                    <div><i class="fas fa-chevron-down text-muted"></i></div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex justify-content-end mt-3">
                                <button class="btn custom-btn-outline-primary ml-3">Unpublish</button>
                                <button class="btn btn-primary" style="border-radius: 10px; font-size: 14px;">Mark as
                                  Available</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                  </ng-container>
                  </p-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->










    </div>
  </main>
  <!-- end content page  -->
</div>

<div *ngIf="qrCodeImg !== ''" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': displayQr}"
  style="background-color: rgb(23 22 22 / 50%);">
  <div class="modal-dialog" role="document" style="max-width: 371px;">
    <div class="modal-content" style="margin-top: 150px;">
      <button  type="button" aria-label="Close"
        style="border: none; background-color: #ffffff; width:40px; height: 40px;margin-left: auto;margin-right:15px;">
        <button aria-hidden="true" style="color: #000000; font-size: 35px; border: none; background-color: #ffffff;" (click)="onCloseQrModal()">×</button>
      </button>
      <div class="modal-body">
        <div class="row text-center justify-content-center " id="qrToPdf">


          <div class="d-flex justify-content-between fs-6 fw-bold">
            <div class="mr-3">{{roomType}}.R<div>R/{{roomNo}}</div></div>
            <div style="width:170px"></div>
            <div>ID-{{aprtCode}} </div>
          </div>
          <p class="mt-4 fs-6 text-center">Scan this code</p>
          <img id="qr_image_e" [src]="qrCodeImg" class="qrfix">
          <div class="fw-bold text-center fs-6 mt-4">QR Code : <span class="fw-normal" style="color:#B3261E;">{{qrCode}}</span> </div>

        </div>
        <div class="row text-center justify-content-center">
          <p class="mt-4 text-center">Or enter the number manually</p>
          <div class="custom-apartment-buttons mt-1" >
            <button class="custom-btn-delete">{{qrCode}}</button>
            <button class="custom-btn-rented mt-4" (click)="printDiv('qrToPdf')">Download</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
