<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Finance'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Finance'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
        <div class="d-md-flex justify-content-between" style="margin-bottom:20px">
            <div class="d-flex" style="align-items: center;">
                <p class="fw-bold me-2" style="color: #6c6a6a;margin-bottom: 0px;"><a style="color: #6c6a6a;" class="removeUnderLine"
                        routerLink="/Payments">Finance </a></p>

                <p class="fw-bold me-2" style="margin-bottom: 0px;color: #605D62;">></p>
                <p class="fw-bold" style="color: #6c6a6a;margin-bottom: 0px;">Add New Payment</p>
            </div>
            <!-- <div class="input">
                <input type="text" class="form-control py-2" placeholder="Search Finance" (keyup)="searchAction($event)" [(ngModel)]="searchText" [ngModelOptions]="{standalone:true}" />
                  <img src="../../../assets/images/search.svg" alt="">
              </div> -->
              </div>
        <div class="card px-4 py-3 ">
            <div class="header d-flex align-items-center">
                <h5 class="mb-2">Add New Payment</h5>
            </div>
            <form [formGroup]="paymentForm" (ngSubmit)="AddPayment()">
                <div class="body py-4">
                    <div class="row g-4 mb-3">
                        <div class="col-md-4">
                            <label class="form-label">Pay To</label>
                            <select class="form-select" (change)="SelectType($event)" formControlName="Pay_To" [(ngModel)]="toType">
                                <option value="Owner">Owner</option>
                                <option value="Apartment">Apartment</option>
                                <option value="Partner">Partner</option>
                                <option value="Worker">Worker</option>
                                <option value="Admins">Admins</option>
                            </select>

                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Payment Method</label>
                            <select class="form-select" formControlName="Pay_Method">
                                <option value="Cash">Cash</option>
                                <option value="Online">Online</option>

                            </select>

                        </div>
                        <div class="col-md-4" *ngIf="toType == 'Apartment'">
                            <label class="form-label">Type</label>
                            <select class="form-select" formControlName="Pay_To_Type">
                                <option value="Incoming">Incoming Payments </option>
                                <option value="Outgoing">Outgoing Payments</option>
                            </select>
                        </div>

                        <div class="col-md-4">
                            <label for="Amount" class="form-label">Amount</label>
                            <input type="number" id="Amount" class="form-control" placeholder="0.00" formControlName="Payment_Amount" />
                        </div>
                        <div class="col-md-4" *ngIf="toType == 'Admins' || toType == 'Employees'">
                            <label for="Bonus" class="form-label">Bonus</label>
                            <input type="number" id="Bonus" class="form-control" placeholder="0.00" formControlName="Payment_Bouns" />
                        </div>
                        <div class="col-md-12">
                            <label for="desc" class="form-label">Expense Description </label>
                            <textarea type="text" rows="6" id="desc" class="form-control" placeholder="Enter a description..."
                                formControlName="Payment_Desc"></textarea>
                        </div>
                    </div>
                </div>

                <div class="card mt-2">
                    <div class="d-flex justify-content-between align-items-center mb-2 px-3">

                        <div class="mx-2 mt-4">
                            <p class="me-2 fs-5 fw-bold ml-8">{{ toType }}</p>
                            <span class="me-2">Please select the {{ toType }} for whom you would like to make a payment</span>
                        </div>

                        <div class="input">
                            <input type="text" class="form-control py-2" placeholder="Global Search" (keyup)="searchAction($event)"
                                [(ngModel)]="searchText" [ngModelOptions]="{standalone:true}" />
                            <img src="../../../assets/images/search.svg" alt="">
                        </div>
                    </div>


                    <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}"
                        [value]="allData">
                        <ng-template pTemplate="header" class="text-center">
                            <tr>
                                <ng-container>


                                    <th class="td-name">
                                        Name

                                    </th>
                                    <th class="td-name" *ngIf="toType != 'Apartment'">
                                        Email
                                    </th>
                                    <th *ngIf="toType == 'Apartment'">
                                        Location
                                    </th>
                                    <th *ngIf="toType == 'Worker'">
                                        Type
                                    </th>
                                    <th class="td-name">
                                        Phone number

                                    </th>
                                    <th class="td-name">
                                        WhatsApp

                                    </th>
                                    <!--  <th *ngIf="toType == 'Owner'">
                          owner Apartment
                        </th>
                        <th *ngIf="toType == 'Owner'">
                          Rent
                        </th> -->
                                    <th *ngIf="toType == 'Worker' || toType == 'Partner'">
                                        Profit
                                    </th>
                                    <th *ngIf="toType == 'Admins'">
                                        Salary
                                    </th>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data>

                            <tr>


                                <td>
                                    <input type='radio' name="selectedUser" (change)="selectUser(data)" />
                                    <span class="ms-2">
                                        {{ data.owner_Name || data.apt_Name|| data.worker_Name || data.admin_Name || data?.partner_Name }}</span>

                                </td>
                                <td *ngIf="toType != 'Apartment'">
                                    {{ data?.owner_Email || data?.worker_Email || data?.admin_Name || data?.partner_Email }}
                                </td>
                                <td *ngIf="toType == 'Apartment'">
                                    {{ data?.apt_Location }}
                                </td>
                                <td *ngIf="toType == 'Worker'">
                                    {{ data?.worker_Type }}
                                </td>
                                <td>
                                    {{ data?.owner_Phone || data?.owner_Phone || data?.worker_Phone || data?.admin_Phone || data?.partner_Phone }}
                                </td>

                                <td>
                                    {{ data?.owner_WA || data?.owner_WA || data?.worker_WA || data?.admin_WA || data?.partner_WA }}
                                </td>
                                <!--  <td *ngIf="toType == 'Owner'">
                    {{ data?.owner_Apartment }}
                    </td>
                    <td *ngIf="toType == 'Owner'">
                      {{ data?.owner_Rent }}
                      </td> -->
                                <td *ngIf="toType == 'Worker' || toType == 'Partner'">
                                    {{ data?.worker_Profit || data?.partner_Profit }}
                                </td>
                                <td *ngIf="toType == 'Admins'">
                                    {{ data?.salary }}
                                </td>

                            </tr>

                        </ng-template>


                        <ng-template pTemplate="emptymessage">
                            <div class="container text-center mt-4">
                                <p class="mb-0">No data found.</p>
                            </div>
                        </ng-template>

                    </p-table>
                    <p-paginator *ngIf="allData.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
                      [totalRecords]="totalRecords" [first]="first"></p-paginator>


                </div>
                <div>
                  <div class="col-md-12 mb-3">
                    <label class="form-label gray fw-bold">Attachments</label>
                    <div class="image-upload mainimage mt-2">
                      <input type="file" multiple (change)="onUploadContract($event)">
                      <div class="image-uploads">
                        <div *ngIf="!attachUrls.length" class="d-flex align-items-center justify-content-center text-center h-100">
                          <div class="text-center imageData pt-2">
                            <div class="icon m-auto">
                              <img src="/assets/images/upload 1.svg" alt="Upload icon">
                            </div>
                            <p class="mb-0">Click to upload <span class="text-muted fw-normal">or drag and drop</span></p>
                            <p class="text-muted fw-normal">PNG, JPG, PDF, or MP4 (max. 8MB)</p>
                          </div>
                        </div>
                        <div class="uploaded-images-container" *ngIf="attachUrls">
                          <div *ngFor="let file of attachUrls" class="uploaded-image">
                            <ng-container *ngIf="isImage(file.type)">
                              <img [src]="file.url" alt="{{ file.name }}" style="height: 140px; width: 160px;">
                            </ng-container>
                            <ng-container *ngIf="!isImage(file.type)">
                              <div class="upload-PDF">
                                <span>{{ file.name }}</span>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                    <div class="col-md-12">
                        <label for="note" class="form-label">Note </label>
                        <textarea type="text" rows="6" id="note" class="form-control" placeholder="Enter a note..."
                            formControlName="Payment_Notes"></textarea>
                    </div>
                </div>

                <div class="mt-4 d-flex justify-content-between ">
                    <button type="button" class="btn btn-black" routerLink="/payments">cancel</button>
                    <button type="submit" class="btn btn-color py-2 px-4">save</button>
                </div>
            </form>
        </div>
    </main>
</div>
