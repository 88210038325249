<div class="total-detals">
    <div class="d-flex">
        <div class="rounded-circle border image-size"><img class="w-100 h-100 " src="assets/images/Group 68.svg"/></div>
        <span class="mx-3 mt-2 fw-bold">Total Revenue</span>
    </div>
    <div>
        <h3 class="my-2">€ 2,000</h3>
        <div>
            <i class="fa fa-arrow-down red" *ngIf="rate<20" aria-hidden="true"></i>
            <i class="fa fa-arrow-up green" *ngIf="rate>=20" aria-hidden="true"></i>
            <span class="ms-1 fw-bold" [class.red]="rate<20" [class.green]="rate>=20"
            >{{rate}}%</span>
            <span class="mx-3">vs last month</span>
        </div>
    </div>
</div>