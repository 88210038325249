<div class="d-flex justify-content-center align-items-center mb-3 login">
    <div class="w-sm-50">

        <div class="text-center"><img src="./assets/images/logo.svg" class="mb-3" /></div>
        <div>
            <h2 class="text-center fw-bold ">Log in to your account</h2>
            <p class="text-center text-color text-weight">Welcome back! Please enter your details.</p>
        </div>
        <p-toast></p-toast>

        <!-- start form log in -->
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label text-color text-weight">Email </label>
                <input type="email" formControlName="username" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                <div class="text-danger mt-3 error d-flex align-items-center"
                 *ngIf="loginForm.get('username')?.errors && (loginForm.get('username')?.touched || submitted)">
                <i class="fa-solid fa-ban text-danger mx-2"></i>
                <p class="mb-0 text-danger"> Email is required</p>
              </div>
            </div>
            <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label text-color text-weight">Password</label>
               <div class="password">
                <input [type]="passwordVisible ? 'text' : 'password'" formControlName="password" class="form-control" id="exampleInputPassword1">
                <img class="eye"  *ngIf="passwordVisible" src="../../../../assets/images/eye.png" alt="" role="button" (click)="togglePasswordVisibility()">
                <img  class="eye" *ngIf="!passwordVisible" src="../../../../assets/images/hide-eye.png"role="button" (click)="togglePasswordVisibility()">
               </div>
               <div class="text-danger mt-3 error d-flex align-items-center"
               *ngIf="loginForm.get('password')?.errors && (loginForm.get('password')?.touched || submitted)">
              <i class="fa-solid fa-ban text-danger mx-2"></i>
              <p class="mb-0 text-danger"> Password is required</p>
            </div>

            </div>
            <div class=" d-md-flex justify-content-between align-items-center">
                <div class="mb-3 form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                    <label class="form-check-label text-color text-weight" for="exampleCheck1">Remember for 30
                        days</label>
                </div>
                <div class="mb-3 mt-2">
                  <label class="form-check-label text-color text-weight" style="cursor: pointer;" >Forgot Password?</label>
                </div>

            </div>

            <button type="submit" class="btn btn-sign p-3 w-100" >Sign in</button>

        </form>
        <!-- end form log in -->

    </div>
</div>
