<!-- start header -->
<header>
  <app-head (newItemEvent)="addItem($event)" [titleModule]="'Finance'"></app-head>
</header>
<!-- end header -->
<div class="row m-0">
  <!-- start sidebar  -->
  <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Finance'"></app-sidebar>
  <!-- end sidebar  -->
  <!-- start content page  -->


  <main class=" p-4 col-lg-10">
    <!-- start part 1 in page  -->
    <div class="d-md-flex justify-content-between" style="margin-bottom:24px;margin-top:8px;">
      <div class="d-flex" style="align-items: center;">
          <p class="fw-bold me-2" style="color: #6c6a6a;margin-bottom: 0px;"><a  style="color: #6c6a6a;"class="removeUnderLine" routerLink="/payments">Finance </a></p>

          <!-- <p class="fw-bold me-2" style="margin-bottom: 0px;color: #605D62;">></p>
          <p class="fw-bold" style="color: #6c6a6a;margin-bottom: 0px;">Recent Activities </p> -->
      </div>
      <div class="d-flex justify-content-lg-between ">
        <button  (click)="exportAsPDF('MyDIv');" class="outline-0 border-0 rounded-3 py-2 px-3 text-white fw-bold" style="background-color:#1F4068"><img
          src="assets/images/Vector.svg"> Download PDF</button>

      </div>
  </div>

  <p-toast></p-toast>
  <div class="card">
    <div class="row d-block d-md-flex justify-content-between my-2 px-3 pt-3">
      <div class="col-md-4">
        <div class="d-flex align-items-center" style="font-size: 15px;">
          <p class="me-2 fs-5 fw-bold ml-8">Finance</p>
        </div>
      </div>


  </div>
  <p-table id="MyDIv" #content class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="payments"  [scrollable]="true">
    <ng-template pTemplate="header" class="text-center">
        <tr>
            <ng-container>

                <th class="td-name"  style="overflow: hidden;
                white-space: nowrap;">
                    Transaction No
                    <img src="./assets/images/swap_sort 16px.svg" alt="">

                </th>
                <th class="td-name"  style="overflow: hidden;
                white-space: nowrap;">
                  Transaction Date
                  <img src="./assets/images/swap_sort 16px.svg" alt="">

              </th>
                <th class="td-name"  style="overflow: hidden;
                white-space: nowrap;">
                    Apartment Name
                    <img src="./assets/images/swap_sort 16px.svg" alt="">
              </th>
              <th class="td-name">
                Payment
          </th>
              <th class="td-name">
                user type
              </th>
                <th class="td-name">
                 Name
                </th>

                <th class="td-name">
                    Amount

                </th>
                <th class="td-name">
                  Service Fee

                </th>
                <th class="td-name">
                    Type

                </th>
                <th class="td-name">
                  Method
                </th>
                <th class="td-name">
                  Phone Number
                </th>

                <th class="td-name">
                    Status
                    <img src="./assets/images/swap_sort 16px.svg" alt="">

                </th>

            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payment>

        <tr style="cursor: pointer;" >
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
                {{payment?.trans_No}}
            </td>
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
              {{payment?.trans_Date_Format | date}}
          </td>
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
                {{payment?.apt_Name}}
            </td>
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">{{payment?.payment}}</td>
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">{{payment?.user_Type}}</td>
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">{{payment?.userName}}</td>

            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
                {{payment?.amount}}
            </td>

            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
              {{payment?.service_Fee}}
            </td>
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
              {{payment?.type}}
            </td>

            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
              <div>
                  <span class="rounded-5 px-3"*ngIf="payment?.method==	'Stripe'">
                    <span>
                      <img class="payment" src="assets/images/stripe.svg" alt="">
                    </span>
                </span>
                <span class="rounded-5 px-3"*ngIf="payment?.method==	'Visa'">
                  <span>
                    <img class="payment" src="assets/images/visa.svg" alt="">
                  </span>
              </span>
                <span class="rounded-5 px-3" *ngIf="payment?.method=='Cash'">
                  Cash
              </span>
              </div>
          </td>
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
                {{payment?.phoneNo}}
            </td>
            <td routerLink="/payments/invoice/{{payment?.inv_ID}}">
              <div>
                  <span class="rounded-5 px-3 badge paid"*ngIf="payment?.status_Paid==true">
                    <span>
                      {{payment?.status_String}}
                    </span>
                    <img src="assets/images/greenMore.svg" alt="">
                </span>
                <span class="rounded-5 px-3 badge unPaid" *ngIf="payment?.status_Paid==false">
                  <span>
                    {{payment?.status_String}}
                  </span>
                  <img src="assets/images/redMore.svg" alt="">
              </span>
              </div>
          </td>

        </tr>

    </ng-template>


    <ng-template pTemplate="emptymessage">
        <div class="container text-center mt-4">
            <p class="mb-0">No data found.</p>
        </div>
    </ng-template>

</p-table>

  </div>

  </main>
  <!-- end content page  -->
</div>
