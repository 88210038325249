<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'users'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'users'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10">
        <div class="d-md-flex justify-content-between">
            <div class="d-flex">
                <p class="fw-bold me-2" style="color: #6c6a6a"><a style="color: #6c6a6a" class="removeUnderLine" routerLink="/users">Users
                    </a></p>

                <p class="fw-bold me-2">></p>
                <p class="fw-bold" style="color: #6c6a6a">View User Details</p>
            </div>
            <!-- <div>
                <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold" style="background-color: #EBEBEB; color:#1F4068"><img src="assets/images/user/share.png"> Share</button>
                <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold" style="background-color: #EBEBEB; color:#1F4068"><img src="assets/images/user/delete.svg"> Delete</button>
                <button class="outline-0 border-0 rounded-3 py-2 px-3 text-white fw-bold" style="background-color:#1F4068"><img
            src="assets/images/user/edit.svg"> Edit</button>
            </div> -->
        </div>

        <div class="mt-3 row border bg-white rounded-3 py-3 ">
            <aside class="col-2 p-0 ps-1 position-fixed d-none d-md-block">
                <a class="d-block text-decoration-none fw-bold px-2 py-3 text-black mb-1 active"
                    *ngFor="let listAnchor of listAnchors ; let index=index" style="color: #605D62 !important; cursor: pointer;"
                    (click)="onClick(listAnchor.id)" [class.active]="link[index]==true"
                    (click)="changeAnchor(index)">{{ listAnchor?.link }}</a>
            </aside>

            <div class="col-md-10 content-details " id="printedDiv">
                <!--General info-->
                <!-- <app-general-info-user></app-general-info-user> -->
                <div id="Generalinfo">
                    <div class="d-flex mt-3" style="float: right;">
                        <div class="d-flex  justify-content-between ">
                            <div>
                                <a class="px-3 py-2 rounded border text-white text-decoration-none" (click)="openmodel1()"
                                    style="background-color:#1F4068; cursor: pointer;">
                                    <img src="assets/images/plus.svg" class="me-2 ">Add Attachment
                                </a>

                            </div>

                            <!-- <div class="btn-container" *ngIf="titlePage!='Owner details'">
                            <p-button class="btn-style sendDataPButton" label="Upload picture" [loading]="loadingButton" type="button"></p-button>
                            <input type="file" #fileInput (change)="uploadPic($event)">
                        </div> -->

                            <!-- <button type="button" class="outline-none border-0 py-2 px-3 rounded-3 delete" *ngIf="titlePage!='Owner details'" (click)="uploadPic('delete')">Delete</button> -->
                            <!-- <button class="me-3 outline-none border-0 text-white py-2 px-3 rounded-3" *ngIf="titlePage=='Owner details'" style="background-color: #1f4068;">Message</button> -->
                        </div>
                    </div>
                    <p class="fw-bold mb-0">General info</p>
                    <p style="color: #605D62 !important;">Here you can view user details</p>
                </div>
                <div class="d-flex mb-5 d-md-block d-flex justify-content-center justify-md-content-start ">
                    <div class=" d-md-flex  align-items-center justify-md-content-start ">
                        <div class="rounded-circle imageOfPerson me-4" style="box-shadow: 0 2px 6px #d1d1d1;">
                            <img *ngIf="Tenant_details?.user_Photo!=null && Tenant_details?.user_Photo!=''"
                                class="imageOfPerson rounded-circle" style="border: 3px solid white;" [src]="Tenant_details?.user_Photo">
                            <img *ngIf="Tenant_details?.user_Photo==null||Tenant_details?.user_Photo==''"
                                class="imageOfPerson rounded-circle" style="border: 3px solid white;"
                                src="../../../../assets/images/avatardef.jpg">

                        </div>


                    </div>

                </div>
                <!--start General info form-->
                <!-- <form> -->
                <p-toast></p-toast>

                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Full Name</label>
                        <input class="form-control w-100" [(ngModel)]="Tenant_details!.user_FullName" type="text">
                    </div>

                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Email</label>
                    <img src="assets/images/user/mail.svg" class="position-relative top-icon-input" style="right: 20px;">
                    <input class="form-control w-100 ps-5" [(ngModel)]="Tenant_details.email" type="email">
                </div>
                <div>
                    <label class="fw-bold" style="color: #605D62;">About</label>
                    <textarea class="border rounded-3 w-100 p-2" rows="5" [(ngModel)]="Tenant_details.user_About" style="resize: unset;"
                        placeholder="Enter a description"></textarea>
                </div>
                <!-- </form> -->
                <!--end General info form-->
                <hr>
                <!--Other Details-->
                <div id="OtherDetails">
                    <p class="fw-bold mb-0">Other Details</p>
                </div>
                <!--start Other Details form-->

                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Date of birth</label>
                        <input class="form-control w-100" [(ngModel)]="Tenant_details.date_of_Birth" type="date">
                    </div>

                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Phone number</label>
                        <input class="form-control w-100" [(ngModel)]="Tenant_details.user_Phone" type="number">
                    </div>

                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Nationality</label>
                    <input class="form-control w-100" [(ngModel)]="Tenant_details.user_Nationality" type="text">
                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Gender</label>
                    <input type="text" [(ngModel)]="Tenant_details.user_Gender" class="form-control w-100">
                </div>
                <!-- <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Number of Guests</label>
                    <input type="number" class="form-control w-100">
                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Guest 1</label>
                    <input type="text" class="form-control w-100">
                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Guest 2</label>
                    <input type="text" class="form-control w-100">
                </div> -->
                <!--   <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Select Your Role*</label>
                    <input type="text" class="form-control w-100">
                </div> -->
                <!--  <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Purpose of Coming to Germany</label>
                    <textarea class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;" placeholder="Enter a description"></textarea>
                </div> -->
                <!--end Other Details form-->
                <hr>
                <!--Currently living-->
                <div>
                    <p class="fw-bold mb-0" id="Currentlyliving">Currently living</p>
                    <p style="color: #605D62 !important;">Past user rental histories</p>
                </div>
                <!--Cards of Currently living-->
                <div class="row paroductApartment">
                    <ng-container *ngFor="let item of Tenant_details.apartments;">

                        <div class=" col-12 col-md-6 col-lg-4 mb-4  position-relative px-2 mb-4 h-100" style="height: 393px !important;">

                            <a routerLink="/apartments/apartments-details/{{ item?.apt_ID }}" style="height: 46% !important;"
                                class="d-block">
                                <img [src]="item?.apartment_ThumbImg" class="w-100 h-100 apartmentImage">
                                <p class="fw-bold position-absolute rentedORavailable rounded-pill top-0 m-2 py-1 px-3 "
                                    [class.available]="item.statusString != 'Rented'">{{ item.apartment_Status }}</p>
                            </a>
                            <div class="bg-white p-3 details h-50 rounded-bottom">
                                <div class="fw-bold  ">{{ item.apartment_Name }}</div>
                                <div class="d-flex">
                                    <img src="assets/images/apartmentImages/location.svg" class="my-3">
                                    <p class="fw-bold mt-3 text-left mx-1">{{ item.apartment_Address }}</p>
                                </div>
                                <div class="d-flex" style="margin: -25px 0 -10px 0;">
                                    <div class="d-flex justify-content-between">
                                        <img src="assets/images/apartmentImages/room_bed.svg">
                                        <p class="fw-bold mt-4 mx-2">{{ item.apartment_LivingRooms }}</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <img src="assets/images/apartmentImages/user.svg">
                                        <p class="fw-bold mt-4 mx-2">{{ item.apartment_Persons }}</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="Price">
                                        ${{ item.apartment_Price }} <span class="time">/ month</span>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </ng-container>
                    <!-- <div class="col-12 col-md-6 col-lg-4 mb-7 " style=" height: 393px !important; margin-bottom: 120px;" *ngFor="let item of Tenant_details.apartments">
                        <div class="position-relative" style=" height: 328px !important;">
                            <img [src]="item.apartment_ThumbImg" class="w-100 h-100 ">
                            <p class="fw-bold position-absolute rentedORavailable rounded-pill top-0 m-3 py-1 px-3 " [class.available]="available">{{ item.apartment_Status }}</p>
                        </div>
                        <div class="bg-white px-2 py-3 details border rounded-bottom">
                            <div class="fw-bold"> {{ item.apartment_Name }} </div>
                            <div class="d-flex justify-content-between">
                                <div class="d-flex justify-content-between">
                                    <img src="assets/images/apartmentImages/location.svg">
                                    <p class="fw-bold mt-3 ms-2">{{ item.apartment_Address }}</p>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <img src="assets/images/apartmentImages/room_bed.svg">
                                    <p class="fw-bold mt-3 ms-2">{{ item.apartment_LivingRooms }} </p>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <img src="assets/images/apartmentImages/user.svg">
                                    <p class="fw-bold mt-3 ms-2">{{ item.apartment_Persons }}</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="Price">
                                    ${{ item.apartment_Price }} <span class="time">/ month</span>
                                </div>
                             </div>
                        </div>

                    </div> -->


                </div>
                <hr>
                <!--Document  details-->
                <div class="w-50">
                    <p class="fw-bold mb-0" id="Documentdetails">Document details</p>
                    <p style="color: #605D62 !important;">Documents, issue reports, and payment behaviour</p>
                </div>
                <!--Accordion-->
                <div class="accordion accordion-flush mb-5" id="accordionFlushExample">
                    <!-- <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button ps-0  collapsed text-black fw-bold bg-transparent border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Public transport
                      </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse mt-3" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="mb-3">
                                <p class="fw-bold" style="color: #605D62 !important;">Transport within 270 m</p>
                                <div class="form-control w-100 fs-6 pt-2">1 bus</div>
                            </div>
                            <div class="mb-3">
                                <p class="fw-bold " style="color: #605D62 !important;">Transport within 520 m</p>
                                <div class="form-control w- fs-6 pt-2">Tram 2</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="accordion-item" *ngIf="Tenant_details.tenant_Passport_URL!=''">
                        <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed ps-0  text-black fw-bold bg-transparent border-0" type="button"
                                data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                aria-controls="flush-collapseTwo">
                                Passport of tenant
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse mt-3" aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body mb-3">
                                <img [src]="Tenant_details.tenant_Passport_URL" class="me-2">
                                <!-- <img src="assets/images/user/File upload base.svg" class="me-2">
                                <img src="assets/images/user/File upload base.svg" class="me-2">
                                <img src="assets/images/user/File upload base.svg" class="me-2"> -->
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item" *ngIf="Tenant_Attach!=null">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed ps-0  text-black fw-bold bg-transparent border-0" type="button"
                                data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false"
                                aria-controls="flush-collapseThree">
                                <p class="fw-bold mb-0" id="Rentalhistory">Attachments </p>
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse mt-3" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body mb-3">
                                <p-table class="table-shopping" [selectionPageOnly]="true" [scrollable]="true" scrollHeight="400px"
                                    [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="Tenant_Attach">
                                    <ng-template pTemplate="header" class="text-center">
                                        <tr>
                                            <ng-container>


                                                <th class="td-name">
                                                    File Description

                                                </th>

                                                <th class="td-name">
                                                    Download File

                                                </th>
                                            </ng-container>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-doc>

                                        <tr>

                                            <td>
                                                {{ doc?.attach_Desc }}

                                            </td>


                                            <td>
                                                <a [href]="doc?.attach_Path" target="_blank">
                                                    <img src="assets/images/apartmentImages/move.svg" height="32px" width="32px">
                                                </a>
                                            </td>


                                        </tr>

                                    </ng-template>


                                    <ng-template pTemplate="emptymessage">
                                        <div class="container text-center mt-4">
                                            <p class="mb-0">No data found.</p>
                                        </div>
                                    </ng-template>

                                </p-table>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingthree">
                            <button class="accordion-button ps-0 collapsed text-black fw-bold bg-transparent border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                        Other details
                      </button>
                        </h2>
                        <div id="flush-collapsethree" class="accordion-collapse mt-3 collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                            <div class="mb-3">
                                <p class="fw-bold" style="color: #605D62 !important;">Property square feet</p>
                                <div class="form-control w-100 fs-6 pt-2">2356 sq ft</div>
                            </div>
                            <div class="mb-3">
                                <p class="fw-bold " style="color: #605D62 !important;">Laundry type</p>
                                <div class="form-control w- fs-6 pt-2">Laundry in building</div>
                            </div>
                            <div class="mb-3">
                                <p class="fw-bold " style="color: #605D62 !important;">Date available</p>
                                <div class="form-control w- fs-6 pt-2">Jun 2, 2023 - July 2, 2023</div>
                            </div>
                        </div>
                    </div> -->

                </div>
                <!--Rental history (5)-->
                <div class="d-flex justify-content-between">
                    <div>
                        <p class="fw-bold mb-0" id="Rentalhistory">Rental history </p>
                        <p style="color: #605D62 !important;">Past user rental histories</p>
                    </div>
                    <div>
                        <!-- <button class="px-3 py-2 fw-bold bg-transparent outline-0 border-0 rounded-3 " style="border: 1px solid #939094 !important;color: #1F4068;"><img src="assets/images/user/download.svg">
              Download all</button> -->
                    </div>

                </div>
                <p-table class="table-shopping" [selectionPageOnly]="true" [scrollable]="true" scrollHeight="400px"
                    [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="Tenant_details.invoices">
                    <ng-template pTemplate="header" class="text-center">
                        <tr>
                            <ng-container>


                                <th class="td-name">
                                    Invoice

                                </th>
                                <th class="td-name">
                                    Duration

                                </th>
                                <th class="td-name">
                                    Amount

                                </th>
                                <th class="td-name">
                                    Status

                                </th>
                                <!-- <th class="td-name">
                                    Behaviour

                                </th> -->


                                <th class="td-name">
                                    Download File

                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-Tenant>

                        <tr style="cursor: pointer;" (click)="gotodetail(Tenant?.invoice_ID)">

                            <td>
                                {{ Tenant?.invoice_Code }}

                            </td>
                            <td>
                                {{ Tenant?.invoice_Duration }}
                            </td>
                            <td>
                                $ {{ Tenant?.invoice_Amount }}
                            </td>
                            <td>
                                <div>
                                    <span class="rounded-5 px-3 badge paid" *ngIf="Tenant?.status_Paid==true">
                                        <span>
                                            Paid
                                        </span>
                                        <img src="assets/images/greenMore.svg" alt="">
                                    </span>
                                    <span class="rounded-5 px-3 badge unPaid" *ngIf="Tenant?.status_Paid==false">
                                        <span>
                                            Not Paid
                                        </span>
                                        <img src="assets/images/redMore.svg" alt="">
                                    </span>
                                </div>
                            </td>
                            <td>
                                <a class="text-center" [href]="Tenant?.invoice_URL" target="_blank">
                                    <img src="assets/images/apartmentImages/move.svg" height="32px" width="32px">
                                </a>
                            </td>

                            <!-- <td class="cursor position-relative">
                                <button class="border-0 outlin-0 bg-transparent">
                  <img src="assets/images/edit.svg">
              </button>


                            </td> -->

                        </tr>

                    </ng-template>


                    <ng-template pTemplate="emptymessage">
                        <div class="container text-center mt-4">
                            <p class="mb-0">No data found.</p>
                        </div>
                    </ng-template>

                </p-table>
                <!--table-->
                <div></div>

                <!--user information-->
                <div style="margin-top:30px">
                    <p class="fw-bold mb-0" id="userinformation">user information</p>
                    <p style="color: #605D62 !important;">this section display for admins only</p>
                </div>
                <!-- start user information form-->
                <div class="row">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">First name</label>
                        <input class="form-control w-100" [(ngModel)]="FName" type="text">
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fw-bold" style="color: #605D62">Last name</label>
                        <input [(ngModel)]="LName" class="form-control w-100" type="text">
                    </div>

                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">Passport ID</label>
                    <input [(ngModel)]="PassportID" class="form-control w-100 ps-5" type="text">
                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">About</label>
                    <textarea [(ngModel)]="About" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"
                        placeholder="Enter a description"></textarea>
                </div>
                <div class="mb-4">
                    <label class="fw-bold" style="color: #605D62;">User Image</label>
                    <div class="d-flex align-items-center pt-3">
                        <img *ngIf="!imageUrl" src="../../../../assets/images/avatardef.jpg" class="ng-star-inserted imageOfPerson">
                        <img *ngIf="imageUrl" [src]="imageUrl" class="ng-star-inserted userImage">

                    </div>

                    <div class="mb-3 py-2 px-2" style="width: 264px; background-color: #F6F6F6;">

                        <input #fileInput (change)="uploadPic($event)" type="file" accept="image/*"
                            class="ng-star-inserted d-block file position-absolute">
                        <div>
                            <img src="../../../../assets/images/user/upload 1.svg">
                            <span class="ms-3 text-dark">upload image</span>
                        </div>
                    </div>
                </div>
                <hr>
                <div style="margin-top:30px">
                    <p class="fw-bold mb-0" id="userinformation">user rating </p>
                    <p style="color: #605D62 !important;">this section help you rating your tenants</p>
                </div>
                <div class="d-flex justify-content-center justify-md-content-start py-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class=" d-md-flex  align-items-center justify-md-content-start ">
                            <div class="rounded-circle" style="box-shadow: 0 2px 6px #d1d1d1;">
                                <img class="rounded-circle rateImage" style="border: 3px solid white;" [src]="Tenant_details?.user_Photo">
                            </div>


                        </div>
                        <div class="ms-5">
                            <div style="margin-top:10px">
                                <p style="color: #605D62;font-size: 20px;">Please Rate This Tenant</p>
                            </div>
                            <div class="stars-icon2 mt-3">
                                <p-rating [(ngModel)]="ratingnumber" [cancel]="false"></p-rating>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div class="mb-4">
                        <label class="fw-bold" style="color: #605D62;">leave a comment</label>
                        <textarea [(ngModel)]="comment_leave" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"
                            placeholder="Enter a description"></textarea>
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <button (click)="gotopage2()"
                        class="backcancel bg-white rounded-2 border-0 py-2 px-3 fw-bold mx-2 blue downloadAll">Cancel</button>
                    <button (click)="UpdateTenantInfo()" class="outlet-0 text-white border-0 px-4 py-2 rounded-3"
                        style="background-color: #1f4068;" type="submit">Save</button>
                </div>
                <!-- end user information form-->


            </div>
        </div>
    </main>
    <!-- end content page  -->
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header pb-0" style="background-color:#ffffff; border-bottom: none !important;position:relative !important;">
                <p class="modal-title"
                    style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                        add Attachment
                    </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close"
                    aria-label="Close" (click)="onCloseModal1()">
                    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
                </button>


            </div>

            <div class="modal-body pt-0 px-4">
                <div class="row">
                    <div class="mb-4">
                        <label class="fw-bold mb-1" style="color: #605D62;">About</label>
                        <textarea [(ngModel)]="desk" class="border rounded-3 w-100 p-2" rows="5" style="resize: unset;"
                            placeholder="Enter a description"></textarea>
                    </div>
                </div>

                <div class="row">

                    <label class="form-label gray fw-bold"> Upload File
                    </label>
                    <div class="position-relative h-124px mb-3">

                        <input type="file" accept=" *" (change)="selectFile($event)"
                            class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                        <img src="assets/images/apartmentImages/_File upload base.svg" class="position-absolute" width="96%">

                    </div>

                </div>

                <div class="row" *ngIf="urls != null">
                    <div class="col-md-3 card33">
                        <a (click)="removeItem()" style="border: none;   margin-bottom: 10px; position: absolute;">
                            <img class="imageRemove" src="../../../../../../assets/images/delete.svg" width="32px" height="32px;">
                        </a>
                        <img [src]="urls" alt="Avatar" style="width:380px;height:205px ; ">

                    </div>
                </div>

            </div>


            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between">
                        <a (click)="onCloseModal1()" style="    border: 1px solid;"
                            class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvddmodel">
                            Cancel
                        </a>
                        <button (click)="AddAttach()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4"
                            style="background-color: #1f4068 !important">
                            confirm
                        </button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
