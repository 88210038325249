<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Messages'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'messages'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10" (click)=" hidecard( )">
        <p class="fw-bold" style="color: #6c6a6a">Messages : {{deatail?.chat_Code}}</p>
        <div class="mt-3 row border bg-white rounded-3">

            <!-- start the chat -->
            <p-toast></p-toast>

            <div class="col-12 col-md-12 px-0">
                <!-- start head in the chat-->
                <div class="border py-2 px-3">

                    <div class="d-flex justify-content-between">
                        <div class="d-flex">

                            <img *ngIf="deatail?.tenant_Photo" class="me-3" [src]="deatail?.tenant_Photo" style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px; cursor: pointer;margin-top: 13px;" routerLink="/users/app-edite-user-details/{{userID}}">


                            <img *ngIf="!deatail?.tenant_Photo" class="me-3" src="../../../assets/images/Frame 1261154899.svg" style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px;cursor: pointer;margin-top: 13px;" routerLink="/users/app-edite-user-details/{{userID}}">

                            <div class="ml-3 d-flex justify-content-between">
                                <div class="mt-3">
                                    <p class="line-height fw-bold mb-0">{{deatail?.tenant_Name}}</p>
                                    <p class="color-gray fw-light">@{{deatail?.tenant_Name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <td class="cursor position-relative">
                                <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,deatail?.chat_Code)">
          <img src="assets/images/edit.svg">
      </button>
                                <div *ngIf="showEdit[deatail?.chat_Code] " style="width: 220px; line-height: 10px;  z-index: 999;   right: 20px;" class="position-absolute z-3 rounded-3 bg-white border edit-button">
                                    <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100" routerLink="/messages/assgin-tiket/{{paramid}}">Reassign Chat to employee</button>

                                    <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="CloseTicket('Closed')">Close Chat  </button>
                                    <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="CloseTicket('Resolved')"> Solved  Chat </button>

                                </div>

                            </td>
                        </div>
                    </div>

                </div>
                <!-- end head in the chat-->
                <!-- start content in the chat-->
                <div class="px-4">
                    <div *ngFor=" let item of deatail.msgs">
                        <!-- Reciver -->
                        <div class="d-flex justify-content-between reciver  py-3" *ngIf="item?.is_Mobile">
                            <div class="d-flex">
                                <!-- <img src="assets/images/Avatar.svg" class="me-3 mb-3" /> -->
                                <img *ngIf="item?.user_Photo" style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px; cursor: pointer;margin-top: 13px;" [src]="item?.user_Photo" class="me-3" />
                                <img *ngIf="!item?.user_Photo" class="me-3" src="../../../assets/images/Frame 1261154899.svg" style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px; cursor: pointer;margin-top: 13px;">

                                <div>
                                    <div class="ml-3 d-flex justify-content-between">
                                        <div>
                                            <p class="mb-0">{{item?.user_Name}}</p>
                                        </div>
                                        <p class="mb-0 fw-light" style="margin-left: 70px;">{{item?.send_Time}}</p>
                                    </div>
                                    <p *ngIf="item?.msg_Body!=null && item?.msg_Body!=''" class="p-2 rounded-end rounded-bottom" style="background-color: #f6f6f6">
                                        {{item?.msg_Body}}
                                    </p>
                                    <img (click)="openmodel(item?.msg_Attachement)" style="width: 130px;  height: 130px" *ngIf="item?.msg_Attachement!=null && item?.msg_Attachement!=''" [src]="item?.msg_Attachement">
                                </div>
                            </div>
                        </div>
                        <!-- Sender -->
                        <div class="d-flex justify-content-end py-3" *ngIf="!item.is_Mobile">
                            <i class="fa-solid fa-check-double text-success mt-4 me-2 pt-2" *ngIf="item?.msg_Body!=null && item?.msg_Body!='' && item.is_Readed"></i>
                            <i class="fa-solid fa-check-double  mt-4 me-2 pt-2"  *ngIf="item?.msg_Body!=null && item?.msg_Body!='' && !item.is_Readed"></i>
                            <i class="fa-solid fa-check-double text-success  me-2 pt-2" style="margin-top:120px;" *ngIf="item?.msg_Attachement!=null && item?.msg_Attachement!='' && item.is_Readed"></i>
                            <i class="fa-solid fa-check-double  me-2 pt-2"  style="margin-top:120px;" *ngIf="item?.msg_Attachement!=null && item?.msg_Attachement!='' && !item.is_Readed"></i>
                            <div style="margin-bottom: 20px;">
                                <div class="ml-3 d-flex justify-content-between">
                                    <div>
                                        <p class="mb-0">{{item?.user_Name}}</p>
                                    </div>
                                    <p class="mb-0 fw-light" style="margin-left: 70px;">{{item?.send_Time}}</p>
                                </div>
                                <p *ngIf="item?.msg_Body!=null && item?.msg_Body!=''" class="p-2 rounded-start rounded-bottom text-white bg-blue">
                                    {{item?.msg_Body}}
                                </p>
                                <img (click)="openmodel(item?.msg_Attachement)" style="width: 130px;  height:130px" *ngIf="item?.msg_Attachement!=null && item?.msg_Attachement!=''" [src]="item?.msg_Attachement">

                            </div>
                        </div>
                    </div>
                    <div class="position-relative mb-2 h-25 m-auto" style="width: 98%">
                        <textarea [(ngModel)]="reply_Desc" id="w3review" name="w3review" class="w-100 h-100 p-3 border rounded" placeholder="Send a message" style="resize: none; outline: none"></textarea>
                        <div class="position-absolute d-flex justify-content-between" style="right: 6%; bottom: 15%">
                            <!-- <button class="border-0 bg-transparent"> -->
                              <span class="me-2 mt-1">{{fileSelected}}</span>
                            <input type="file" accept="image/*" (change)="selectFile($event)" style=" width: 28% !important;" class="ng-star-inserted d-block w-100 h-124px file position-absolute">

                            <img src="assets/images/message/attach_file.svg" />
                            <!-- </button> -->
                            <button class="border-0 bg-transparent">
                <img src="assets/images/message/more.svg" />
              </button>
                            <button (click)="ReplyDash() " class="px-3 py-2 rounded border text-white text-decoration-none" style="background-color: #1F4068;">
                <img src="assets/images/message/sendFill.svg" /> Send
              </button>
                        </div>
                    </div>
                </div>
                <!-- end content in the chat-->
                <!-- start send a message in the chat-->

                <!-- end send a message in the chat-->
            </div>
            <!-- end the chat-->
        </div>
    </main>
    <!-- end content page  -->
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display22}" style="background-color: rgba(90,90,90,0.5);">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document" style="width:900px;">
      <div class="modal-content position-relative">
        <div class="modal-header p-0 m-0 d-flex justify-content-start">
          <button type="button" class="close" aria-label="Close" (click)="oncloseModal()">
            <img src="../../../../assets/images/ic_round-close.svg" alt="">
          </button>
            </div>
          <div class="modal-body p-0 m-0">
              <img [src]="imageSize" style="width:100%;height: 500px" />

          </div>

          <!-- <div class="modal-footer">
              <button type="button" class="btn btn-white bbtclose" (click)="oncloseModal()">
close
</button>

          </div> -->


      </div>
  </div>
</div>
