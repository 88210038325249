<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Issue Reports'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Issue_Reports'"></app-sidebar>
    <!-- end sidebar  -->

    <!-- start content page  -->
    <main class="p-4 col-lg-10">
        <div class="d-md-flex justify-content-between dashboard" style="margin-top: 8px; margin-bottom: 16px;">
            <p class="fw-bold" style="color: #6c6a6a;">Issue Reports</p>
            <div class="d-flex justify-content-lg-between ">
              <!-- <app-dropdown class="ms-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown> -->

            <app-dropdown class="me-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown>
           <div class="d-flex mb-2  ">
                <button class="week rounded-start px-3 py-2 bg-w outline-none" (click)="FilterButtons('This Month')" [ngClass]="{'activeButton': monthButton == true}">This Month</button>
                <button class="week px-3 py-2 outline-none rounded-end" (click)="FilterButtons('Last Week')" [ngClass]="{'activeButton': weekButton == true}">Last Week</button>
            </div></div>
        </div>

        <p-toast></p-toast>

        <div class="card">
            <div class="row d-block d-md-flex justify-content-between align-items-center my-2 px-3 py-3">

                <div class="col-md-4">
                  <div class="d-flex align-items-center" style="font-size: 15px;">
                    <label for="" style="color: #313331;font-weight: 500;font-size: 16px;">Issue</label>
                    <img src="assets/images/line.svg" class="mx-3" >
                    <select class="form-select" (change)="clickIquires($event)">
                      <option *ngFor="let data of InquireFillterLists; let index=index;" [value]="data.id" [selected]="InquireFillterSelected[index]">{{data.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-5 col-8">
                  <div class="input">
                    <input type="text" class="form-control py-2 w-100" placeholder="Search Issues" (keyup)="searchAction($event)" [(ngModel)]="searchText" />
                      <img src="../../../assets/images/search.svg" alt="">
                  </div>
              </div>


                <!-- end apartmentLists button  -->
            </div>
            <!-- <p-table #dt [value]="issues" [(selection)]="issues" dataKey="id" [filterDelay]="0" /> -->



            <p-table class="table-shopping " [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="issues">

                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData">
                        <ng-container>


                            <th class="td-name">
                                Report ID

                            </th>
                            <th class="td-name">
                                Requested by

                            </th>
                            <th class="td-name">
                                Apartment Number

                            </th>
                            <th class="td-name">
                                Date Reported
                                <img src="./assets/images/arrow_downward.svg" alt="">

                            </th>
                            <th class="td-name">
                                Descriptions

                            </th>
                            <th class="td-name">
                                Status
                                <img src="./assets/images/arrow_downward.svg" alt="">

                            </th>
                            <th class="td-name">

                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-issue style="height: 200px !important;">

                    <tr style="cursor: pointer;" (click)="hidecard()" >


                        <td class="d-flex align-items-center" routerLink="/Issue_Reports/Report-view/{{issue.issue_ID}}">
                            {{issue?.issue_Code}}
                        </td>
                        <td routerLink="/Issue_Reports/Report-view/{{issue?.issue_ID}}">
                          {{issue?.requsted_By }}

                        </td>

                        <td routerLink="/Issue_Reports/Report-view/{{issue?.issue_ID}}">
                            {{issue?.apartment_Number}}
                        </td>

                        <td routerLink="/Issue_Reports/Report-view/{{issue?.issue_ID}}">
                            {{issue?.date_Reported | date:'medium' }}
                        </td>
                        <td routerLink="/Issue_Reports/Report-view/{{issue?.issue_ID}}">
                          <p style="width: 10vw; overflow: hidden; text-overflow: ellipsis;
                          white-space: nowrap;"> {{issue?.desc}}
                          </p>
                        </td>
                        <td class="text-center" routerLink="/Issue_Reports/Report-view/{{issue?.issue_ID}}">
                          <div>
                              <span class="rounded-5 px-3 badge Pending" *ngIf="issue?.issue_Status=='Pending'">
                                {{issue?.issue_Status}}
                                <span>
                                  <img src="assets/images/purbleArrow.svg" alt="">
                                </span>
                            </span>
                            <span class="rounded-5 px-3 badge Cancelled" *ngIf="issue?.issue_Status=='Cancelled'">
                              {{issue?.issue_Status}}
                              <span>
                                <img src="assets/images/redDot.svg" alt="">
                              </span>
                          </span>
                          <span class="rounded-5 px-3 badge Completed"  *ngIf="issue?.issue_Status=='Completed'">
                            {{issue?.issue_Status}}
                            <span>
                              <img src="assets/images/greenArrow.svg" alt="">
                            </span>
                        </span>
                      <span class="rounded-5 px-3  badge InProgress"  *ngIf="issue?.issue_Status=='InProgress'">
                        {{issue?.issue_Status}}
                        <span>
                          <img src="assets/images/blueArrow.svg" alt="">
                        </span>
                    </span>
                          </div>
                      </td>


                        <td class="cursor position-relative" style="text-align: center;">
                            <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,issue?.issue_ID)">
              <img src="assets/images/edit.svg">
          </button>
                            <div *ngIf="showEdit[issue.issue_ID] " class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100" routerLink="/Issue_Reports/Report-view/{{issue.issue_ID}}">View Details</button>

                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" routerLink="/Issue_Reports/assgin-issue/{{issue.issue_ID}}"> assign worker  </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" routerLink="/Issue_Reports/assgin-issue-owner/{{issue.issue_ID}}"> assign owner  </button>

                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="OpenModal1(issue?.issue_ID) " *ngIf="issue.worker!='00000000-0000-0000-0000-000000000000'">Mark it as In-Progress


                                </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="OpenModal2(issue?.issue_ID)">Mark it as Solved

                                   </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="OpenModal3(issue?.issue_ID)">New Appointment

                                   </button>
                                <button *ngIf="issue?.issue_Status !='Cancelled'" class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="cancelissue(issue?.issue_ID)">Cancel </button>

                            </div>

                        </td>

                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>


            </p-table>
            <p-paginator *ngIf="issues.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
              [totalRecords]="totalRecords" [first]="first"></p-paginator>


        </div>

    </main>
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                    Mark it as In-Progress
              </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal1()">
  <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div id="Bankdetails">
                    <!-- <p class="fw-bold mb-0"> Bank account information</p> -->
                    <p style="color: #605D62 !important;">Please Select an Appointment to go and solve this problem </p>
                </div>
                <!--start Other Details form-->

                <div class="row ">

                    <div class="col-12 col-md-9 mb-4 cardss" *ngFor="let item of appointement;let i=index">
                        <input type="radio" name="drone2" (change)="handleChange(item)">

                        <label class="fw-bold" style="color: #605D62; margin-left: 10px;">appointement ( {{i+1}} )
                          <br>
                          {{item.appo_Date}} {{item.appo_Time}} </label>
                    </div>
                </div>

            </div>




            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between">
                        <a (click)="onCloseModal1()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                        Cancel
                          </a>
                        <button (click)="  MarkasProgress( )" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                        confirm
                        </button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display3}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                  new appointment
            </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal3()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div id="Bankdetails">
                    <!-- <p class="fw-bold mb-0"> Bank account information</p> -->
                    <p style="color: #605D62 !important;">create new appointment to go and solve this problem </p>
                </div>
                <!--start Other Details form-->

                <div class="row ">

                    <div class="mb-3 col-12 col-md-12">
                        <label class="form-label gray fw-bold"> date</label>
                        <!-- <p-calendar [(ngModel)]="Apointment3"></p-calendar> -->
                        <p-calendar [(ngModel)]="Apointment3" [showTime]="true" dateFormat="dd/mm/yy" [showSeconds]="true"></p-calendar>

                    </div>

                </div>

            </div>




            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between">
                        <a (click)="onCloseModal3()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                      Cancel
        </a>
                        <button (click)="  MarkasProgress2( )" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                      confirm
        </button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                      mark it as solved
            </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal2()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div class="row">

                    <div class="col-12 col-md-12 mb-4 cards">
                        <p style="color: #605D62 !important;margin-bottom: 0px;"> Who will Pay
                        </p>

                        <input type="radio" name="drone" checked (change)="handleChange2('StudiFlats')">

                        <label class="fw-bold" style="color: #605D62; margin-left: 10px;"> StudiFlats

                           </label>
                        <input type="radio" name="drone" (change)="handleChange2('Tenant')">

                        <label class="fw-bold" style="color: #605D62; margin-left: 10px;"> Tenant

                           </label>
                        <input type="radio" name="drone" (change)="handleChange2('Owner')">

                        <label class="fw-bold" style="color: #605D62; margin-left: 10px;"> Owner

                              </label>
                    </div>
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Worker Cost</label>
                        <input type="number" [(ngModel)]="worker_cost" (keyup)="onSearchChange($event)" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Item Cost</label>
                        <input type="number" [(ngModel)]="item_Cost" (keyup)="onSearchChange($event)" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>

                    <div class="mb-3 col-12 col-md-12">
                      <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Service Cost</label>
                      <input type="number" [(ngModel)]="service_cost" (keyup)="onSearchChange($event)" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                  </div>

                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Total Cost</label>
                        <input type="number" disabled="disabled" [(ngModel)]="Total_cost" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">Description</label>
                        <textarea class="border p-3 pt-3 rounded-2 w-100" [(ngModel)]="discerption" rows="3" style="resize: inherit"></textarea>

                    </div>
                    <div class="mb-3 col-12 col-md-12">
                      <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Attachments</label>
                    <div class="image-upload mainimage">
                      <input type="file" accept="image/*,.pdf" multiple="multiple" (change)="onUploadContract($event,'contract_Path')">
                        <div class="image-uploads">
                          <div class="d-flex align-items-center justify-content-center text-center h-100" >
                            <div *ngIf="!selectedContractImg" class="text-center imageData pt-2" >
                              <div class="icon m-auto">
                                <img src="/assets/images/upload 1.svg" alt="img">
                               </div>
                               <p class="mb-0">Click to upload <span class="text-muted fw-normal"> or drag and drop</span></p>
                               <p class="text-muted fw-normal">PNG, JPG, PDF (max. 8MB)</p>
                            </div>
                            <ng-container *ngIf="selectedContractImg && (selectedContractImg.type == 'image/png' || selectedContractImg.type == 'image/jpg' || selectedContractImg.type == 'image/jpeg')">
                              <a (click)="opencloseModal(selectedContractImg.url)">
                              <img  *ngIf="selectedContractImg.url"  [src]="selectedContractImg.url" [alt]="selectedContractImg.name"  style="height: 124px;width:150px;">
                              </a>
                            </ng-container>

                            <ng-container *ngIf="selectedContractImg && selectedContractImg.type == 'application/pdf'">
                              <span>{{selectedContractImg.name}}</span>
                            </ng-container>
                          </div>
                        </div>
                    </div>

                  </div>
                </div>

            </div>




            <div class="modal-footer">

                    <div class="d-flex justify-content-between w-100">
                        <a (click)="onCloseModal2()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold  xcvdd">
                      Cancel
        </a>
                        <button (click)="MarkAsSolved()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                      confirm
        </button>

                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
