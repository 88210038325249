<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Roles'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Roles'"></app-sidebar>

    <main class="p-4 col-lg-10">

      <div class="row" style="color: #6c6a6a">

        <div class="d-md-flex justify-content-between dashboard mt-2">
          <div class="d-flex" style=" cursor: pointer;">
              <p routerLink="/roles" class="fw-bold me-2" style="color: #6c6a6a;">Roles</p>
              <img src="assets/images/user/right-arrow.svg" class="me-2" style="margin-top: -15px">
              <p class="fw-bold" style="color: #6c6a6a">Role Permessions</p>
          </div>
      </div>

     </div>
        <div class="card mt-2">
            <div class="row row2s">
                <p class="fw-bold ms-1" style="color: #000000;">Authorizations</p>
                <p>please add Authorizations to this role</p>
            </div>
            <p-toast></p-toast>

            <div class="row mb-4 row2ss " *ngFor="let item of Permission">
                <div class="col-12 col-md-6 mb-2">
                    <p class="fw-bold blue mb-0">{{item.page_Name}}</p>
                    <!-- <div class="gray col-12 col-md-6 blue">
                        please select the authorizations you want to give to this user </div> -->
                </div>
                <div class="col-12 col-md-6">
                    <!-- <div class="d-flex">
                        <p-inputSwitch [(ngModel)]="item.p_View"></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">disabled</p>
                    </div> -->
                    <div class="d-flex">
                        <p-inputSwitch [(ngModel)]="item.p_View"></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">view only</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch [(ngModel)]="item.p_Update"></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">update</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch [(ngModel)]="item.p_Add"></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">add </p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch [(ngModel)]="item.p_Delete"></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray"> delete</p>
                    </div>
                </div>
            </div>
            <!-- <div class="row mb-4 row2ss ">
                <div class="col-12 col-md-6 mb-2">
                    <p class="fw-bold blue mb-0">Payments</p>
                    <div class="gray col-12 col-md-6 blue">
                        please select the authorizations you want to give to this user </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">disabled</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">view only</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">update</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">add & delete</p>
                    </div>
                </div>
            </div>
            <div class="row mb-4 row2ss ">
                <div class="col-12 col-md-6 mb-2">
                    <p class="fw-bold blue mb-0">Users</p>
                    <div class="gray col-12 col-md-6 blue">
                        please select the authorizations you want to give to this user </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">disabled</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">view only</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">update</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">add & delete</p>
                    </div>
                </div>
            </div>
            <div class="row mb-4 row2ss ">
                <div class="col-12 col-md-6 mb-2">
                    <p class="fw-bold blue mb-0">Owners</p>
                    <div class="gray col-12 col-md-6 blue">
                        please select the authorizations you want to give to this user </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">disabled</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">view only</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">update</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">add & delete</p>
                    </div>
                </div>
            </div>
            <div class="row mb-4 row2ss ">
                <div class="col-12 col-md-6 mb-2">
                    <p class="fw-bold blue mb-0">Inquiries</p>
                    <div class="gray col-12 col-md-6 blue">
                        please select the authorizations you want to give to this user </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">disabled</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">view only</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">update</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">add & delete</p>
                    </div>
                </div>
            </div>
            <div class="row mb-4 row2ss ">
                <div class="col-12 col-md-6 mb-2">
                    <p class="fw-bold blue mb-0">Issue Reports</p>
                    <div class="gray col-12 col-md-6 blue">
                        please select the authorizations you want to give to this user </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">disabled</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">view only</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">update</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">add & delete</p>
                    </div>
                </div>
            </div>
            <div class="row mb-4 row2ss ">
                <div class="col-12 col-md-6 mb-2">
                    <p class="fw-bold blue mb-0">workers</p>
                    <div class="gray col-12 col-md-6 blue">
                        please select the authorizations you want to give to this user </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">disabled</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">view only</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">update</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">add & delete</p>
                    </div>
                </div>
            </div>
            <div class="row mb-4 row2ss ">
                <div class="col-12 col-md-6 mb-2">
                    <p class="fw-bold blue mb-0">partners</p>
                    <div class="gray col-12 col-md-6 blue">
                        please select the authorizations you want to give to this user </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">disabled</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">view only</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">update</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">add & delete</p>
                    </div>
                </div>
            </div> -->


            <div class="row mb-4 row2ss ">
                <div class="col-12 col-md-6 mb-2">
                    <a style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                    Cancel
      </a>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex" style="float:right;margin-right: 34px;">
                        <button class="rounded-2 text-white rounded-2 border-0 py-2 px-4" (click)="UpdateRolePerm()" style="background-color: #1f4068 !important;">
                        confirm
          </button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
