<div *ngIf="ActionButtonField">
    <div class="mb-3 me-4 col-12 col-md-6">
        <label class="form-label gray fw-bold">section {{index}}</label>
        <input type="text" class="form-control rounded-2 py-2 fs-6" [(ngModel)]="contractDetails.sec_Name" [ngModelOptions]="{standalone: true}" />
    </div>
    <div class="mb-3">
        <label class="form-label gray">Description</label>
        <textarea class="border p-3 rounded-2 w-100" rows="5" style="resize: inherit" [(ngModel)]="contractDetails.sec_Desc" [ngModelOptions]="{standalone: true}"></textarea>
        <!-- <div class=" col-md-12 ">
            <div class="d-flex" style="float:right">
                <button class="backcancel outline-0 bg-white rounded-2 py-2 px-3 fw-bold me-4" (click)="RemoveActionButton()">
        Remove
      </button>

            </div>
        </div> -->
    </div>

</div>