<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Admins'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Admins'"></app-sidebar>

    <main class="p-4 col-lg-10">

      <div class="fw-inquiries d-flex justify-content-between align-items-center" style="color: #6c6a6a;margin-top: 7px; margin-bottom: 15px;">
        <p class="fw-bold ms-1" style="color: #6c6a6a;">Admins</p>
        <div class="d-flex justify-content-lg-between  ">
          <div class="d-flex justify-content-lg-between flex-md-row flex-column">
            <div class="d-flex mb-2">
                <!-- start dropdown button  -->
                <app-dropdown class="me-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown>
            </div>
            <app-add-new-button *ngIf="AdminRole?.p_Add==true || is_Super==true" [id]="'/admins/add-admin'" class="mt-2"></app-add-new-button>
          </div>
        </div>
        <!-- start add button (add new apartment) in page  -->
        <!-- end add button (add new apartment) in page  -->

    </div>
        <p-toast></p-toast>
        <div class="card">


            <!-- <p-table #dt [value]="admins" [(selection)]="admins" dataKey="id" [filterDelay]="0" /> -->
            <div class="row d-flex justify-content-between align-items-center px-3">
              <!-- length Of person -->
              <div class="col-md-4">
                <div class="d-flex mx-2 mt-4">
                  <p class="me-2 fs-5 fw-bold ml-8">Admins</p>
              </div>
              </div>
              <!-- search input -->
              <div class="col-md-5">
                <div class="input">
                  <input type="text" class="form-control py-2 w-100" placeholder="Search Admins" (keyup)="searchAction($event)" [(ngModel)]="searchText" />
                    <img src="../../../assets/images/search.svg" alt="">
                </div>
              </div>
          </div>

            <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="admins">
                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData">
                        <ng-container>


                            <th class="td-name">
                                Name

                            </th>
                            <th class="td-name">
                                Email Address

                            </th>
                            <th class="td-name">
                                Phone Number

                            </th>
                            <th class="td-name">
                                Whatsapp Number
                            </th>
                            <th class="td-name">
                                Role

                            </th>
                            <th class="td-name">
                                Status

                            </th>
                            <th class="td-name">
                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-admin>

                    <tr style="cursor: pointer;" (click)="hidecard()">

                        <td>
                            {{admin?.fullName}}
                        </td>
                        <td>
                            {{admin?.email}}
                        </td>
                        <td>
                            {{admin?.phone_num}}
                        </td>
                        <td>
                            {{admin?.wA_Number}}
                        </td>
                        <td>
                            {{admin?.role}}
                        </td>
                        <!-- <td>
                            <p class="d-flex align-items-center fw-bold mt-2">{{admin?.status}}</p>
                        </td> -->
                        <td>
                            <div>
                              <span class="rounded-5 px-3 badge Active" *ngIf="admin?.status==true">
                                Active
                            </span>
                            <span class="rounded-5 px-3 badge InActive" *ngIf="admin?.status==false">
                              In Active
                          </span>
                          </div>
                        </td>
                        <td class="cursor position-relative" style="text-align: center;">
                            <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,admin.user_ID)">
                      <img src="assets/images/edit.svg">
                  </button>
                            <div *ngIf="showEdit[admin.user_ID]"  class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100" routerLink="/admins/edit-admin/{{admin?.user_ID}}">update  </button>
                                <!-- <button class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100" (click)="OpenModal3(admin?.email)">2FA Setup</button> -->

                                <button class="d-block border-0 outlin-0 bg-transparent  button text-start py-2 w-100" *ngIf="admin?.status==true" (click)="sendToModel1(admin)">deactivate</button>
                                <button class="d-block border-0 outlin-0 bg-transparent  button text-start py-2 w-100" *ngIf="admin?.status==false" (click)="sendToModel1(admin)">Activate</button>

                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="openModal2(admin.user_ID)"> Delete  </button>
                            </div>
                        </td>

                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>
            </p-table>

            <!-- <p-paginator *ngIf="roles" (onPageChange)="tiggerPageChange($event)" [rows]="10" [totalRecords]="fullRespone?.totalRecords"></p-paginator> -->

        </div>
    </main>
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
Activate And Deactivate Admin
            </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal1()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div class="row">

                    <div class="mb-3 col-12 col-md-12">
                        <label class="form-label gray fw-bold">select status</label>
                        <!-- start dropdown button  -->
                        <div class="selectForm">
                            <!-- <app-dropdown [list]="listDropDownArea" [holder]="selectArea" (selected)="selectedfromDropDownArea($event,'insert')"></app-dropdown> -->
                            <select style="    font-size: 17px;" class="s-cont form-select form-select-sm mb-3" [(ngModel)]="statusAdmin">
                        <option value="null" disabled hidden selected>select status</option>

                       <option   [ngValue]="true"> Active </option>
                       <option   [ngValue]="false"> DeActive </option>

                  </select>

                        </div>
                        <!-- end dropdown button  -->
                    </div>
                </div>

            </div>




            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between">
                        <a (click)="onCloseModal1()" style="   margin-right: 251px !important; border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                      Cancel
        </a>
                        <button (click)="onSubmitModal1()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                      confirm
        </button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600;">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                    Delete the item
          </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal2()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>

            </div>
            <div class="modal-body">


                <h4 style="margin-top:0px ;margin-bottom: 0px; font-size: 16px;"> Are you sure you want to delete the item? </h4>

            </div>

            <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <a (click)="onCloseModal2()" style="   margin-right: 251px !important; border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold xcvdd">
                    Cancel
      </a>
                        <button (click)="onSubmitModal2()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                    confirm
      </button>
                    </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display3}" style=" background-color: rgb(23 22 22 / 50%);;">
  <div class="modal-dialog" role="document">
      <div class="modal-content" style="    margin-top: 150px;">
          <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
              <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                  <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
Setup 2FA Authenticator          </span>
              </p>
              <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal1()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



          </div>

          <div class="modal-body">


              <div class="row">

                  <div class="mb-3 col-12 col-md-12">
                      <label class="form-label gray fw-bold">Scan This QR by any Authenticator App and then enter generated Code by App in below Input Field</label>
                      <!-- start dropdown button  -->
                      <div class="selectForm">
                          <!-- <app-dropdown [list]="listDropDownArea" [holder]="selectArea" (selected)="selectedfromDropDownArea($event,'insert')"></app-dropdown> -->
                          <img src="{{ faarray?.qR_Link }}" class="img-thumbnail img-fluid border-0">
                          <input class="form-control w-100" [(ngModel)]="AuthCode" type="text">

                      </div>
                      <!-- end dropdown button  -->
                  </div>
              </div>

          </div>




          <div class="modal-footer">

              <div class="d-flex justify-content-between">
                  <div class="py-2 px-3 fw-bold"></div>
                  <div class="d-flex justify-content-between">
                      <a (click)="onCloseModal3()" style="   margin-right: 251px !important; border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                    Cancel
      </a>
                      <button (click)="onSubmitModal3(paramid3)" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                    confirm
      </button>
                  </div>
              </div>
              <!-- Confirm -->

          </div>


      </div>
      <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
