import { Component } from '@angular/core';

@Component({
  selector: 'app-total-detail-dashboard',
  templateUrl: './total-detail-dashboard.component.html',
  styleUrls: ['./total-detail-dashboard.component.scss']
})
export class TotalDetailDashboardComponent {
rate:number=20
}
