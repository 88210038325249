<div class="card mt-2 bvngd">


    <!-- <p-table #dt [value]="products" [(selection)]="products" dataKey="id" [filterDelay]="0" /> -->
    <div class="row d-flex justify-content-between align-items-center px-3">
        <!-- length Of person -->
        <div class="col-md-4">
          <div class="d-flex mx-2 mt-4">
            <p class="me-2 fs-5 fw-bold ml-8">{{pagePerson}}</p>
            <p class="mb-3 bg-gray rounded-5 blue px-2 d-flex align-items-center">
                {{fullRespone?.totalRecords}} {{pagePerson}}
            </p>
        </div>
        </div>
        <!-- search input -->
        <div class="col-md-5">
          <div class="input">
            <input type="text" class="form-control py-2 w-100" placeholder="Search Owners" (keyup)="searchAction($event)" [(ngModel)]="searchText" />
              <img src="../../../assets/images/search.svg" alt="">
          </div>
        </div>
    </div>

    <p-table class="table-shopping" [(selection)]="selectedProducts" [dataKey]="dataSelectionKey" [rows]="2" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem'}" [value]="products">
        <ng-template pTemplate="header" class="text-center">
            <tr *ngIf="headerData">
                <ng-container *ngFor="let head of headerData">
                    <th *ngIf="head.tableHeaderCheckbox">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th *ngIf="!head.tableHeaderCheckbox && (head.Name && head.SortableColumn)" [pSortableColumn]="head.SortableColumn" class="td-name">
                        {{head.Name}}
                         <p-sortIcon *ngIf="head.sortIcon == true" [field]="head.SortableColumn"></p-sortIcon>
                    </th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>

            <tr (click)="hidecard( )"  style="cursor: pointer;" >
                <!-- <td>
                    <p-tableCheckbox [value]="product" (click)="GetSelectedItem()"></p-tableCheckbox>
                </td> -->
                <!-- in owner case -->
                <!-- <td *ngIf="pagePerson =='Owners'"  routerLink="/owners/owner-profile/{{product?.owner_ID}}">

                    <img *ngIf="product.owner_Photo" [src]="product?.owner_Photo" style="height: 50px;width: 50px;border-radius: 50%;cursor: pointer;" class="me-2">
                    <img *ngIf="!product.owner_Photo" src="../../../assets/images/Frame 1261154899.svg" style="height: 50px;width: 50px;border-radius: 50%;cursor: pointer;" class="me-2">
                    {{product.owner_FirstName}} {{product.owner_LastName }}

                </td> -->
                <td *ngIf="pagePerson =='Owners'" routerLink="/owners/owner-profile/{{product?.owner_ID}}" class="owner-cell">
                  <div class="owner-info">
                      <img *ngIf="product.owner_Photo" [src]="product?.owner_Photo" alt="Owner Photo" class="owner-photo">
                      <img *ngIf="!product.owner_Photo" src="../../../assets/images/Frame 1261154899.svg" alt="Default Photo" class="owner-photo">
                      <div class="owner-name" [title]="product.owner_FirstName + ' ' + product.owner_LastName">
                          {{product.owner_FirstName}} {{product.owner_LastName }}
                      </div>
                  </div>
              </td>
                <td routerLink="/owners/owner-profile/{{product?.owner_ID}}"  *ngIf="pagePerson =='Owners' && product.owner_BankAccoun">{{product.owner_BankAccoun}}</td>
                <!-- <td *ngIf="pagePerson=='Owners'  ">{{product.owner_CreatedBy}}</td> -->
                <td routerLink="/owners/owner-profile/{{product?.owner_ID}}"  *ngIf="pagePerson =='Owners' && product.owner_Mail">{{product.owner_Mail}}</td>
                <td  routerLink="/owners/owner-profile/{{product?.owner_ID}}" *ngIf="pagePerson=='Owners'  ">{{product.owner_Phone}}</td>
                <!-- <td *ngIf="pagePerson=='Owners'">{{product.gender}}</td> -->
                <td routerLink="/owners/owner-profile/{{product?.owner_ID}}"   *ngIf="pagePerson=='Owners'&&product.owner_About.length>25">{{product.owner_About | slice:0:25}} ...</td>
                <td routerLink="/owners/owner-profile/{{product?.owner_ID}}"   *ngIf="pagePerson=='Owners'&&product.owner_About.length<25">{{product.owner_About}} </td>
                <!-- <td  *ngIf="pagePerson=='Owners'">{{product.owner_Status}}</td> -->
                <td *ngIf="pagePerson=='Owners' " class="cursor position-relative">
                    <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,product.owner_ID)">
                  <img src="assets/images/edit.svg">
              </button>
                    <div *ngIf="showEdit[product.owner_ID]" class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                        <button *ngIf="OwnersRole?.p_View==true || is_Super==true" routerLink="/owners/owner-profile/{{product.owner_ID}}" class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100">View Profile</button>

                        <button *ngIf="OwnersRole?.p_Update==true || is_Super==true" routerLink="/owners/edit_owner/{{product.owner_ID}}" class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100">Edit</button>

                        <button *ngIf="OwnersRole?.p_Delete==true || is_Super==true" (click)="deleteModal(product)" class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100">Delete</button>

                    </div>
                    <!-- <div class="flex align-items-center justify-content-center gap-2">
                        <button routerLink="/owner/edit_owner/{{product.owner_ID}}" pButton pRipple type="button"
                            icon="pi pi-pencil" class="p-button-rounded p-button-text"></button>

                        <button pButton pRipple type="button" icon="pi pi-times"
                            class="p-button-rounded p-button-text p-button-danger"
                            (click)="confirmDelete($event,product)"></button>
                        <p-confirmPopup></p-confirmPopup>

                    </div> -->
                </td>

                <td *ngIf="pagePerson =='Users' && product.name">{{product.name}}</td>
                <td *ngIf="pagePerson =='Users' && product.img">{{product.img}}</td>
                <td *ngIf="pagePerson =='Users' && product.Registered_time">{{product.Registered_time}}</td>
                <td *ngIf="pagePerson =='Users' && product.email">{{product.email}}</td>
                <td *ngIf="pagePerson =='Users' && product.Number_of_rented">{{product.Number_of_rented}}</td>
                <td *ngIf="pagePerson=='Users'&& product.Behaviour">
                    <div>
                        <span class="rounded-5 px-3 py-1" [class.good]="product.Behaviour=='Good'" [class.bad]="product.Behaviour=='Bad'" [class.average]="product.Behaviour=='Average'">
                            <i class="fa fa-circle" aria-hidden="true" style="font-size: .6rem;"></i>
                            {{product.Behaviour}}
                        </span>
                    </div>
                </td>

                <td *ngIf="pagePerson =='Reports'">{{product.Report_ID}}</td>
                <td *ngIf="pagePerson =='Reports'">{{product.Requested_by}}</td>
                <td *ngIf="pagePerson =='Reports'">{{product.Table_header}}</td>
                <td *ngIf="pagePerson =='Reports'">{{product.Date_Reported}}</td>
                <td *ngIf="pagePerson =='Reports'">{{product.Descriptions}}</td>
                <td *ngIf="pagePerson =='Reports'">{{product.owner_Mail}}</td>
                <!-- <td   *ngIf="pagePerson!='Users' "
                    >
                    <div>
                        <span class="rounded-5 px-3 py-1" [class.active]="product.Status=='Active'"
                        [class.offline]="product.Status=='Offline'"
                        [class.Deactivated]="product.Status=='Deactivated'"
                        [class.scheduled]="product.Status=='Scheduled'"
                        [class.InProgress]="product.Status=='In Progress'"
                        [class.completed]="product.Status=='Completed'"
                        >
                            <i class="fa fa-circle" aria-hidden="true" style="font-size: .6rem;"></i> {{product.Status}}
                        </span>
                    </div>
                </td> -->

                <td class="cursor position-relative" *ngIf="product.Name == 'action'">
                    <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,product.id)">
                        <img src="assets/images/edit.svg">
                    </button>
                    <div *ngIf="showEdit[product.id]" class="position-absolute z-3 rounded-3 bg-white border edit-button">
                        <button class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100">Edit</button>
                        <!-- <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100" *ngIf="pagePerson=='Reports'">Share</button> -->
                        <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100">Delete</button>
                        <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" *ngIf="pagePerson=='Users'">Suspend User</button>
                    </div>
                </td>

            </tr>

        </ng-template>


        <ng-template pTemplate="emptymessage">
            <div class="container text-center mt-4">
                <p class="mb-0">No data found.</p>
            </div>
        </ng-template>
    </p-table>

    <p-paginator *ngIf="products.length >0" (onPageChange)="tiggerPageChange($event)" [rows]="rows" [first]="first" [totalRecords]="fullRespone?.totalRecords"></p-paginator>

</div>


<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}" >
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 400px;">
        <div class="modal-content">
              <div class="modal-header pb-0 mb-0 d-flex justify-content-end">
                <button type="button" class="close border-0" aria-label="Close" #closebutton (click)="onCloseHandled()">
                  <img src="../../../assets/images/close2.svg" alt="">
                </button>
                  </div>
           <div class="modal-body text-center my-0 py-0">
                  <span><img src="../../../assets/images/delete2.svg" alt=""></span>
                  <h5>Confirm Deletion</h5>
                  <p class="text-muted ">Are you want to delete the owner? </p>
            </div>
                  <div class="modal-footer text-center mx-auto mt-0 pt-0 mb-2">
                    <button type="button" class="btn btn-delete mx-2" data-bs-dismiss="modal" (click)="onCloseHandled()">Cancel</button>
                    <button type="button" class="btn btn-color mx-2" id="closeModal" (click)="deleteItem()" >Delete</button>
                  </div>
     </div>
      </div>
</div>
