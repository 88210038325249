<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'booking'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'booking'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10">
        <div class="d-md-flex justify-content-between">
            <div class="d-flex">
                <p class="fw-bold me-2" style="color: #6c6a6a"><a style="color: black" class="removeUnderLine">Booking Details </a></p>
                <!--
                <p class="fw-bold me-2">></p>
                <p class="fw-bold" style="color: black">booking</p> -->
            </div>
            <!-- <div>
              <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold" style="background-color: #EBEBEB; color:#1F4068"><img src="assets/images/user/share.png"> Share</button>
              <button class="outline-0 border-0 rounded-3 py-2 px-3 me-2 fw-bold" style="background-color: #EBEBEB; color:#1F4068"><img src="assets/images/user/delete.svg"> Delete</button>
              <button class="outline-0 border-0 rounded-3 py-2 px-3 text-white fw-bold" style="background-color:#1F4068"><img
          src="assets/images/user/edit.svg"> Edit</button>
          </div> -->
        </div>

        <div class="mt-3 row border bg-white rounded-3 py-3 ">
            <!-- <aside class="col-2 p-0 ps-1 position-fixed d-none d-md-block">
                <a class="d-block text-decoration-none fw-bold px-2 py-3 text-black mb-1 active" *ngFor="let listAnchor of listAnchors ; let index=index" style="color: #605D62 !important; cursor: pointer;" (click)="onClick(listAnchor.id)" [class.active]="link[index]==true"
                    (click)="changeAnchor(index)">{{listAnchor.link}}</a>
            </aside> -->

            <div class="col-md-10 content-details mnbvcx ">
                <!--General info-->
                <!-- <app-general-info-user></app-general-info-user> -->
                <div id="Generalinfo">
                    <p class="fw-bold mb-0">General info</p>
                    <p style="color: #605D62 !important;">Update your photo and personal details.</p>
                </div>
                <div class="d-flex mb-5 d-md-block d-flex justify-content-center justify-md-content-start ">
                    <div style="justify-content: center;" class=" d-md-flex  align-items-center justify-md-content-start ">
                        <div class="rounded-circle imageOfPerson me-4" style="box-shadow: 0 2px 6px #d1d1d1;">
                            <img class="imageOfPerson rounded-circle" style="border: 3px solid white;" [src]="tReqHistory.user_Photo">
                        </div>
                        <div class="d-flex mt-4">
                            <div class="d-flex  justify-content-between ">

                                <!-- <div class="btn-container" *ngIf="titlePage!='Owner details'">
                                  <p-button class="btn-style sendDataPButton" label="Upload picture" [loading]="loadingButton" type="button"></p-button>
                                  <input type="file" #fileInput (change)="uploadPic($event)">
                              </div> -->

                                <!-- <button type="button" class="outline-none border-0 py-2 px-3 rounded-3 delete" *ngIf="titlePage!='Owner details'" (click)="uploadPic('delete')">Delete</button> -->
                                <!-- <button class="me-3 outline-none border-0 text-white py-2 px-3 rounded-3" *ngIf="titlePage=='Owner details'" style="background-color: #1f4068;">Message</button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!--start General info form-->
                <!-- <form> -->
                <p-toast></p-toast>
                <div class="row">
                    <div style="width: 100%; height: 48px; justify-content: center; align-items: flex-start; gap: 40px; display: inline-flex">
                        <div style="width: 100px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
                            <div style="justify-content: center; align-items: flex-start; gap: 12px; display: inline-flex">
                                <div style="color: #787579; font-size: 14px; font-family: Montserrat; font-weight: 500; text-transform: capitalize; line-height: 20px; word-wrap: break-word">Booking ID</div>
                            </div>
                            <div style="width: 100px; height: 20px; color: #605D62; font-size: 16px; font-family: Montserrat; font-weight: 600; line-height: 20px; word-wrap: break-word">{{tReqHistory.booking_ID}}</div>
                        </div>
                        <div style="width: 0px; height: 46.50px; border: 1px #CECECE solid"></div>
                        <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
                            <div style="justify-content: center; align-items: flex-start; gap: 12px; display: inline-flex">
                                <div style="width: 20px; height: 20px; position: relative">
                                    <div style="width: 20px; height: 20px; left: 0px; top: 0px; position: absolute;"> <img src="../../../../assets/images/uiw_date.svg"> </div>
                                </div>
                                <div style="color: #787579; font-size: 14px; font-family: Montserrat; font-weight: 500; text-transform: capitalize; line-height: 20px; word-wrap: break-word">check in date</div>
                            </div>
                            <div style="width: 139px; height: 20px; color: #787579; font-size: 16px; font-family: Montserrat; font-weight: 400; line-height: 20px; word-wrap: break-word">{{tReqHistory.checkin_Date}}</div>
                        </div>
                        <div style="width: 0px; height: 46.50px; border: 1px #CECECE solid"></div>
                        <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
                            <div style="justify-content: center; align-items: flex-start; gap: 12px; display: inline-flex">
                                <div style="width: 20px; height: 20px; position: relative">
                                    <div style="width: 20px; height: 20px; left: 0px; top: 0px; position: absolute; "><img src="../../../../assets/images/uiw_date.svg"></div>
                                </div>
                                <div style="color: #787579; font-size: 14px; font-family: Montserrat; font-weight: 500; text-transform: capitalize; line-height: 20px; word-wrap: break-word">check out date</div>
                            </div>
                            <div style="width: 139px; height: 20px; color: #787579; font-size: 14px; font-family: Montserrat; font-weight: 500; text-transform: capitalize; line-height: 20px; word-wrap: break-word">{{tReqHistory.checkout_Date}}</div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px;margin-bottom: 20px;   height: 1px; background: #EBEBEB"></div>


                <!--Other Details-->
                <div id="OtherDetails">
                    <p class="fw-bold mb-0">Contract Details</p>
                    <p style="color: #605D62 !important;">Documents, issue reports, and payment behaviour</p>
                </div>
                <div class="row">
                    <div class="  position-relative h-124px mb-3" style="margin-top: 22px;padding:0px">
                        <!-- start single img upload and preview -->
                        <div>
                            <ng-container>

                                <div class="row rounded-3 my-4 p-2 pt-3 pb-0" style="border: 1px solid #8fa0b4">
                                    <div class="col-12 col-md-5">
                                        <h6> rental contract</h6>
                                        <p>This contract includes all the necessary information.</p>
                                    </div>
                                    <div class="col-12 col-md-7 d-flex align-items-center">
                                        <div class="mb-3">
                                            <!-- <a class="me-3 cursor" routerLink="/apartments/contract/create-owner-contract"><img src="assets/images/apartmentImages/edit1.svg" /></a> -->
                                            <!-- <a class="me-3 cursor" [href]="contract_Main?.contract_Path"><img src="assets/images/apartmentImages/delete.svg" /></a> -->
                                            <!-- <a class="me-3 cursor"><img src="assets/images/apartmentImages/delete.svg" /></a> -->
                                            <!-- <a class="me-3 cursor" routerLink=""><img src="assets/images/apartmentImages/share.svg" /></a> -->
                                            <a *ngIf="tReqHistory.contract_Path !=null ||tReqHistory.contract_Path !=''" class="me-3 cursor" target="_blank" href="{{tReqHistory.contract_Path}}">

                                                <img src="../../../../assets/images/download.svg" style="width:32px"></a>
                                            <a *ngIf="tReqHistory.contract_Path ==null ||tReqHistory.contract_Path ==''" class="me-3 cursor">

                                                <img src="../../../../assets/images/download.svg" style="width:32px"></a>
                                        </div>
                                    </div>
                                </div>
                                <!-- <input type="file" accept="image/*" multiple="multiple" (change)="selectFile($event)" class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                              <img *ngIf="!selectedContractImg" src="../../../../assets/images/apartmentImages/11.png" class="position-absolute" width="100%" style="    border: 1px solid #c3bcbcc9;border-radius: 5px;">
                         -->

                            </ng-container>
                        </div>
                        <div>
                            <ng-container>
                                <!-- <div>
                                  <input type="file" accept=".pdf, image/*" (change)="selectFile($event)" class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                                  <a *ngIf="inquire_details?.contract_Type == 'pdf'" class="position-absolute imgPreviewUploader" [href]="selectedContractImg" target="_blank" style="font-size: large;z-index: 999999;    margin-left: 160px;">View PDF</a>

                                  <img *ngIf="inquire_details?.contract_Type != 'pdf'" [src]="selectedContractImg" style=" margin-left: 150px;" class="position-absolute imgPreviewUploader" width="100%">

                              </div> -->
                                <!-- <div *ngIf="selectedContractImg=== 'application/pdf'">
                                  <a [href]="selectedContractImg" target="_blank" style="font-size: large;">View PDF</a>
                                  <input type="file" accept=".pdf, image/*" (change)="selectFile($event)" class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                                  <img src="assets/images/apartmentImages/_File upload base.svg" class="position-absolute h-100" width="90%" style="margin-left: 21px;">

                              </div> -->

                            </ng-container>
                        </div>
                        <!-- end single img upload and preview -->
                    </div>
                </div>
                <hr style="margin-top :30px; margin-bottom :30px;color: #00000030;">
                <!--start Other Details form-->
                <div id="OtherDetails">
                    <p class="fw-bold mb-0">Document Details</p>
                    <p style="color: #605D62 !important;">Documents, issue reports, and payment behaviour</p>
                </div>
                <div class="accordion accordion-flush mb-5" id="accordionFlushExample">

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button ps-0  collapsed text-black fw-bold bg-transparent border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1transport" aria-expanded="false" aria-controls="flush-collapseOne1transport">
                              Passport of tenant
                  </button>
                        </h2>
                        <div id="flush-collapseOne1transport" *ngFor="let item of tReqHistory?.passport_Tenant " class="accordion-collapse collapse mt-3" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="w-96 h-96   pb-4 flex-col justify-start items-center gap-4 inline-flex" style="margin-top:10px;width: 50%;">

                                <div class="bg-white rounded shadow flex-col justify-start items-start flex">
                                    <div class="w-96 p-4 bg-white border-b border-gray-200 justify-start items-center gap-16 inline-flex">
                                        <div class="w-80 text-zinc-800 text-sm font-semibold font-['Montserrat'] leading-tight"> {{item?.tenant_Name}}</div>
                                    </div>
                                    <div class="w-96 h-44 flex-col justify-start items-start gap-4 flex">
                                        <img style="width:100%;height: 200px;" class="self-stretch grow shrink basis-0 border border-stone-300" *ngIf="item.passport_Path" [src]="item?.passport_Path" />
                                        <img style="width:100%;height: 200px;" class="self-stretch grow shrink basis-0 border border-stone-300" *ngIf="item.passport_Path==''||item.passport_Path==null" src="../../../../assets/images/passport not uploaded.png" />

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne1">
                            <button class="accordion-button ps-0  collapsed text-black fw-bold bg-transparent border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1transport1" aria-expanded="false" aria-controls="flush-collapseOne1transport1">
                              Problem reports (2)
                </button>
                        </h2>
                        <div id="flush-collapseOne1transport1" class="accordion-collapse collapse mt-3" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample1">

                            <table class="table">
                                <thead class="thead-light" style="background-color: #f7f7f7;">
                                    <tr>
                                        <th scope="col"> Code</th>
                                        <th scope="col"> Description</th>
                                        <th scope="col"> Status</th>
                                        <th scope="col"> </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let problem of tReqHistory?.problems_Report">
                                        <td>{{problem.problem_Code}}</td>
                                        <td>{{problem.problem_Desc}}</td>
                                        <td>{{problem. problem_Status}}</td>
                                        <td>
                                            <a *ngIf="problem.problem_URL !=null ||problem.problem_URL !=''" target="_blank" href="{{problem.problem_URL}}"> <img src="../../../../assets/images/download.svg" style="width:25px"> </a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>




                        </div>
                    </div>







                </div>

                <div class="d-flex justify-content-between">
                    <div>
                        <p class="fw-bold mb-0" id="Rentalhistory">Rental history </p>
                        <p style="color: #605D62 !important;">Past user rental histories</p>
                    </div>
                    <!-- <div>
                        <button class="px-3 py-2 fw-bold bg-transparent outline-0 border-0 rounded-3 " style="border: 1px solid #939094 !important;color: #1F4068;"><img src="assets/images/user/download.svg">
            Download all</button>
                    </div> -->

                </div>
                <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="tReqHistory.rental_History">
                    <ng-template pTemplate="header" class="text-center">
                        <tr>
                            <ng-container>


                                <th class="td-name">
                                    Invoice

                                </th>
                                <th class="td-name">
                                    Duration

                                </th>
                                <th class="td-name">
                                    Amount

                                </th>
                                <!-- <th class="td-name">
                                  Behaviour

                              </th> -->


                                <th class="td-name">


                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-inv>

                        <tr style="cursor: pointer;">

                            <td>
                                {{inv?.inv_Code}}

                            </td>
                            <td>
                                {{inv?.inv_Date}}
                            </td>
                            <td>
                                $ {{inv?.inv_Amount}}
                            </td>


                            <td>
                                <a *ngIf="inv.inv_URL !=null ||inv.inv_URL !=''" target="_blank" href="{{inv.inv_URL}}"> <img src="../../../../assets/images/download.svg" style="width:25px"> </a>

                            </td>

                            <!-- <td class="cursor position-relative">
                              <button class="border-0 outlin-0 bg-transparent">
                <img src="assets/images/edit.svg">
            </button>


                          </td> -->

                        </tr>

                    </ng-template>


                    <ng-template pTemplate="emptymessage">
                        <div class="container text-center mt-4">
                            <p class="mb-0">No data found.</p>
                        </div>
                    </ng-template>

                </p-table>
                <div id="OtherDetails" style="  margin-top: 30px;">
                    <p class="fw-bold mb-0">Check out sheet details</p>
                    <p style="color: #605D62 !important;">check out details </p>
                </div>
                <!--table-->
                <div class="row ">
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">Paid Security Deposit </label>
                        <br>
                        <label class="fw-bold" style="color: #000000; margin-top: 10px;"> $ {{tReqHistory.paid_SecDeposit}} </label>
                    </div>

                </div>
                <div id="OtherDetails" style="  margin-top: 30px;">
                    <p class="fw-bold mb-0"> Apartment expenses during the rental period</p>
                </div>

                <div class="frame" style="margin-top: 60px;" *ngFor="let item of tReqHistory?.checkout_Details ;let i=index">
                    <div class="label">#{{i+1}} {{item.expense_Title}}</div>
                    <div class="div">
                        <div class="div-2">
                            <div class="input-field">
                                <div class="input-with-label">
                                    <div class="text-wrapper">expense type</div>
                                    <div class="input">
                                        <div class="content">
                                            <div class="text">{{item.expense_Type}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="input-field">
                                <div class="input-with-label">
                                    <div class="text-wrapper">cost of expense</div>
                                    <div class="input">
                                        <div class="content">
                                            <p class="text"><span class="span">{{item.expense_Cost}}€ </span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input-field-2">
                            <div class="input-with-label-2">
                                <div class="text-wrapper">Description of the expenses</div>
                                <div class="overlap-group-wrapper">
                                    <div class="overlap-group">
                                        <div class="div-wrapper">
                                            <p class="p">
                                                {{item.expense_Desc}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row " style="margin-top: 34px;">
                    <div class="col-12 col-md-6 mb-4">

                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <label class="fw-bold" style="color: #605D62;">The remaining refundable security deposit </label>
                        <br>
                        <label class="fw-bold" style="color: #000000; margin-top: 10px;"> $ {{tReqHistory.remain_SecDeposit}} </label>
                    </div>

                </div>
                <!-- <div class="d-flex justify-content-between">
                    <button (click)="gotopage2()" class="outlet-0 bg-transparent rounded-3 px-4 py-2 fw-bold downloadAll">Cancel</button>
                    <button class="outlet-0 text-white border-0 px-4 py-2 rounded-3" style="background-color: #1f4068;" type="submit">Save</button>
                </div> -->
                <!-- end user information form-->


            </div>
        </div>
    </main>
    <!-- end content page  -->
</div>