<!-- start header  -->
<header>
  <app-head (newItemEvent)="addItem($event)" [titleModule]="'Messages'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
  <!-- start sidebar  -->
  <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Messages'"></app-sidebar>
  <!-- end sidebar  -->
  <!-- start content page  -->
  <main class="p-4 col-lg-10">
    <p-toast></p-toast>

    <!-- <p class="fw-bold" style="color: #6c6a6a"></p> -->
    <div class="fw-inquiries d-flex justify-content-between align-items-center"
      style="color: #6c6a6a;margin-top: 7px; margin-bottom: 23px;">
      <p class="fw-bold ms-1" style="color: #6c6a6a;">Messages</p>
      <div class="d-flex justify-content-lg-between  ">
        <div class="d-flex justify-content-lg-between flex-md-row flex-column">

          <div class="d-flex mb-2">
            <!-- start dropdown button  -->
            <app-dropdown class="me-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown>
          </div>
          <div class="d-flex mb-2 me-3 mb-2">
            <button class="week rounded-start px-3 py-2 bg-w outline-none" (click)="FilterButtons('This Month')"
              [ngClass]="{'activeButton': monthButton == true}">This Month</button>
            <button class="week px-3 py-2 outline-none rounded-end" (click)="FilterButtons('Last Week')"
              [ngClass]="{'activeButton': weekButton == true}">Last Week</button>
          </div>
          <!-- <app-add-new-button *ngIf="StatisticsRole?.p_Add==true || is_Super==true" [id]="'/messages/create-new'"
            class="mt-2"></app-add-new-button> -->
          <a *ngIf="StatisticsRole?.p_Add==true || is_Super==true" routerLink="/messages/create-new" class="px-3 py-2 rounded border text-white text-decoration-none mb-2"
            style="background-color:#1F4068;">
            <img src="assets/images/plus.svg" class="me-2 ">Send Message
          </a>
        </div>
      </div>
      <!-- start add button (add new apartment) in page  -->
      <!-- end add button (add new apartment) in page  -->

    </div>
    <div class="card">

      <div class="row d-flex justify-content-between align-items-center px-3">
        <!-- length Of person -->
        <div class="col-md-4">
          <div class="d-flex mx-2 mt-4">
            <p class="me-2 fs-5 fw-bold ml-8">Messages</p>
          </div>
        </div>
        <!-- search input -->
        <div class="col-md-5">
          <div class="input">
            <input type="text" class="form-control py-2 w-100" placeholder="Search Messages" (keyup)="searchAction($event)"
              [(ngModel)]="searchText" />
            <img src="../../../assets/images/search.svg" alt="">
          </div>
        </div>
      </div>

      <!-- <p-table #dt [value]="_tickets" [(selection)]="_tickets" dataKey="id" [filterDelay]="0" /> -->


      <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}"
        [value]="_tickets">
        <ng-template pTemplate="header" class="text-center">
          <tr *ngIf="headerData">
            <ng-container>

              <th class="td-name">
                Message NO
              </th>
              <th class="td-name">
                Tenant name
              </th>
              <th class="td-name">
                Apartment Name

              </th>
              <th class="td-name">
                Date

              </th>
              <th class="td-name">
                Assignee

              </th>
              <th class="td-name">
                Status
              </th>


              <th class="td-name">


              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-inquire>

          <tr (click)="hidecard(inquire.request_ID)" style="cursor: pointer;">
            <td routerLink="/messages/message-tiket/{{inquire.chat_ID}}">

              {{inquire?.chat_Code}}
            </td>
            <td routerLink="/messages/message-tiket/{{inquire.chat_ID}}">
              {{inquire?.tenant_Name}}
            </td>

            <td routerLink="/messages/message-tiket/{{inquire.chat_ID}}">
              {{inquire?.apartment_Name}}
            </td>
            <td routerLink="/messages/message-tiket/{{inquire.chat_ID}}">
              {{inquire?.date_Applied}}
            </td>
            <td routerLink="/messages/message-tiket/{{inquire.chat_ID}}">
              {{inquire?.asigned_To}}
            </td>

            <!-- <td>
                      <p class="d-flex align-items-center fw-bold mt-2">{{admin?.status}}</p>
                  </td> -->
            <td routerLink="/messages/message-tiket/{{inquire.chat_ID}}">
              <div>
                <span class="rounded-5 px-3 badge Opened" *ngIf="inquire?.chat_Status=='Opened'">
                  <span>
                    <img src="assets/images/greenDot.svg" alt="">
                  </span>
                  {{inquire?.chat_Status}}
                </span>
                <span class="rounded-5 px-3 badge Closed" *ngIf="inquire?.chat_Status=='Closed'">
                  <span>
                    <img src="assets/images/redDot.svg" alt="">
                  </span>
                  {{inquire?.chat_Status}}
                </span>
                <span class="rounded-5 px-3 badge Resolved" *ngIf="inquire?.chat_Status=='Resolved'">
                  <span>
                    <img src="assets/images/yallowDot.svg" alt="">
                  </span>
                  {{inquire?.chat_Status}}
                </span>
              </div>
            </td>
            <td class="cursor position-relative" style="text-align: center;">
              <button class="border-0 outlin-0 bg-transparent" *ngIf="inquire?.chat_Status=='Opened'"
                (click)="detailperson( $event,inquire.chat_ID)">
                <img src="assets/images/edit.svg">
              </button>
              <div *ngIf="showEdit[inquire.chat_ID] "
                class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                <button *ngIf="inquire?.assigned_To=='Not Assigned'"
                  class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100"
                  routerLink="/messages/assgin-tiket/{{inquire.chat_ID}}">Assign Chat to employee </button>
                <button *ngIf="inquire?.assigned_To!='Not Assigned'"
                  class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100"
                  routerLink="/messages/assgin-tiket/{{inquire.chat_ID}}">Reassign Chat to employee </button>

                <button style="margin-top:0px !important"
                  class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100"
                  routerLink="/messages/message-tiket/{{inquire.chat_ID}}">Pickup Chat</button>
                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2"
                  (click)="CloseTicket('Closed')">Close Chat </button>



              </div>




            </td>

          </tr>

        </ng-template>


        <ng-template pTemplate="emptymessage">
          <div class="container text-center mt-4">
            <p class="mb-0">No data found.</p>
          </div>
        </ng-template>

      </p-table>
      <p-paginator *ngIf="_tickets.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
        [totalRecords]="totalRecords" [first]="first"></p-paginator>

    </div>
    <!-- persons active -->
    <!-- <div class="col-12 col-md-4 border-end">
        <div class="d-flex justify-content-between">

          <div class="d-flex mx-2 mt-4">
            <p class="me-2 fs-5 fw-bold">Messages</p>
            <p class="mt-1 bg-gray rounded-5 px-1 d-flex align-items-center">
              16
            </p>
          </div>

          <div class="d-flex align-items-center">
            <img src="assets/images/message/Vector.svg" />
          </div>
        </div>
         <div class="p-input-icon-left w-100">
          <i *ngIf="value == ''" class="me-1"><img src="assets/images/message/search-lg.svg" /></i>
          <input class="form-control border rounded-3 mt-2 ps-35px" [class.ps-35px]="value == ''" type="text" pInputText
            placeholder="Search" [(ngModel)]="value" />
        </div>
         <table class="table">
          <tbody>
            <tr>
              <th scope="row">
                <button class="outline-0 border-0 bg-transparent ">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <img *ngIf="activePerson" src="assets/images/message/Dot.svg" class="me-2" />
                      <img src="assets/images/Avatar.svg" class="me-3" />
                      <div class="ml-3 d-flex justify-content-between">
                        <div class="mt-3">
                          <p class="line-height fw-bold mb-0">Umar</p>
                          <p class="color-gray fw-light">@umar</p>
                        </div>
                      </div>
                    </div>
                    <p class="d-flex align-items-center fw-light">5min ago</p>
                  </div>
                  <p class="container fw-light">
                    Hey Loram, Ipsum sent me over the latest doc. I just have a
                    quick question about the...
                  </p>
                </button>

              </th>
            </tr>
            <tr>
              <th scope="row">
                <button class="outline-0 border-0 bg-transparent ">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <img *ngIf="!activePerson" src="assets/images/message/Dot.svg" class="me-2" />
                      <img src="assets/images/Avatar.svg" class="me-3" />
                      <div class="ml-3 d-flex justify-content-between">
                        <div class="mt-3">
                          <p class="line-height fw-bold mb-0">Umar</p>
                          <p class="color-gray fw-light">@umar</p>
                        </div>
                      </div>
                    </div>
                    <p class="d-flex align-items-center fw-light">5min ago</p>
                  </div>
                  <p class="container fw-light">
                    Hey Loram, Ipsum sent me over the latest doc. I just have a
                    quick question about the...
                  </p>
                </button>
              </th>
            </tr>
          </tbody>
        </table>
      </div> -->
    <!-- start the chat -->
    <!-- <div class="col-12 col-md-8 px-0">
         <div class="border py-2 px-3">

          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <img src="assets/images/message/Avatar.svg" class="me-3" />
              <div class="ml-3 d-flex justify-content-between">
                <div class="mt-3">
                  <p class="line-height fw-bold mb-0">Umar</p>
                  <p class="color-gray fw-light">@umar</p>
                </div>
                <span class="d-flex align-items-center m-0 ms-4 rounded-4 px-2 mt-3"
                  style="background-color: #ecfdf3; height: 20px; color: green">
                  <img src="assets/images/message/_Dot.svg" class="me-2" />
                  Online</span>
              </div>
            </div>
            <div class="d-flex">
              <a routerLink=""
                class="text-decoration-none bg-blue text-white h-35 d-none d-md-flex align-items-center rounded mt-3 py-2 px-3 text-center"
                style="color: #6c6a6a">View profile</a>
              <button class="bg-transparent mb-2 border-0">
                <img src="assets/images/more_vert_FILL.svg " />
              </button>
            </div>
          </div>

        </div>
         <div class="px-4" style="height: 50%">
           <div class="d-flex justify-content-between reciver mb-2 py-1">
            <div class="d-flex">
              <img src="assets/images/Avatar.svg" class="me-3 mb-3" />
              <div>
                <div class="ml-3 d-flex justify-content-between">
                  <div>
                    <p class="mb-0">Umar</p>
                  </div>
                  <p class="mb-0 fw-light">Thursday 10:16am</p>
                </div>
                <p class="p-2 rounded-end rounded-bottom" style="background-color: #f6f6f6">
                  Hey Ben, can you please share the Wi-Fi password?
                </p>
              </div>
            </div>
          </div>
           <div class="d-flex justify-content-end">
            <div>
              <div class="ml-3 d-flex justify-content-between">
                <div>
                  <p class="mb-0">You</p>
                </div>
                <p class="mb-0 fw-light">Thursday 10:16am</p>
              </div>
              <p class="p-2 rounded-start rounded-bottom text-white bg-blue">
                Hey Ben, can you please share the Wi-Fi password?
              </p>
            </div>
          </div>
        </div>
         <div class="position-relative mb-2 h-25 m-auto" style="width: 95%">
          <textarea id="w3review" name="w3review" class="w-100 h-100 p-3 border rounded" placeholder="Send a message"
            style="resize: none; outline: none"></textarea>
          <div class="position-absolute d-flex justify-content-between" style="right: 6%; bottom: 15%">
            <button class="border-0 bg-transparent">
              <img src="assets/images/message/attach_file.svg" />
            </button>
            <button class="border-0 bg-transparent">
              <img src="assets/images/message/more.svg" />
            </button>
            <button class="text-center px-3 py-1 rounded bg-blue text-white">
              <img src="assets/images/message/sendFill.svg" /> Send
            </button>
          </div>
        </div>
       </div> -->
  </main>
</div>
