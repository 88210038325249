<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="' contract'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="' contract'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
        <!-- start part 1 in page  -->
        <div class="fw-bold d-flex justify-content-between" style="color: #6c6a6a;">
            <p class="fw-bold ml-8"> <a style="color: black" class="removeUnderLine" routerLink="/inquiries">Inqueirs </a> > create tenant contract</p>

        </div>
        <!-- end part 1 in page  -->
        <!-- start part 2 in page  -->

        <!-- start part 3 to display paroduct Apartment details in page  -->
        <div class="row paroductApartment  px-1">
            <div class="bg-white p-3">
                <div>
                    <p class="fw-bold mb-0">create contract</p>
                    <p class="gray">this is a legally contract between buyer and seller</p>
                </div>
                <p-toast></p-toast>


                <div class="row">
                    <div class="mb-3 col-12 col-md-6">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold"> landlord name  </label>
                        <input type="text" [(ngModel)]="contract_details.rC_LandLord" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />
                    </div>
                    <div class="mb-3 col-12 col-md-6">
                        <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold"> tenant name  </label>
                        <input type="text" [(ngModel)]="contract_details.rC_Tenant" class=" form-control rounded-2 py-2 fs-6" id="exampleInputSecuritydeposit" />

                    </div>

                </div>
                <div class="row">
                    <div class="mb-3 col-12 col-md-6">
                        <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">price per month </label>
                        <input type="number" [(ngModel)]="contract_details.rC_RentPrice" class=" form-control rounded-2 py-2 fs-6" id="exampleInputSecuritydeposit" />

                    </div>
                    <div class="mb-3 col-12 col-md-6">
                        <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">  deposit </label>
                        <input type="number" [(ngModel)]="contract_details.rC_RentDeposit" class=" form-control rounded-2 py-2 fs-6" id="exampleInputSecuritydeposit" />

                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-12 col-md-6">
                        <label for="exampleInputSecuritydeposit" style="width: 100%;" class="form-label gray fw-bold">Contract start date</label>
                        <p-calendar   [showIcon]="true"  [readonlyInput]="true"  dateFormat="dd/mm/yy" [(ngModel)]="contract_details.rC_StartRent" id="exampleInputSecuritydeposit"></p-calendar>

                    </div>
                    <div class="mb-3 col-12 col-md-6">
                        <label class="form-label gray fw-bold" style="width: 100%;">Contract end date</label>
                        <p-calendar  [showIcon]="true" [readonlyInput]="true"  dateFormat="dd/mm/yy"[(ngModel)]="contract_details.rC_EndRent"></p-calendar>
                    </div>
                </div>
                <div>
                    <div *ngFor="let data of contract_details.rC_Sections;let index=index">
                        <app-create-contract-sections [contractDetails]="data" [index]="index+1"></app-create-contract-sections>
                        <div class=" col-md-12 " style="margin-bottom: 50px;">
                            <div class="d-flex" style="float:right">
                                <button class="backcancel outline-0 bg-white rounded-2 py-2 px-3 fw-bold me-4" (click)="removeSection(index)">
          Remove
        </button>

                            </div>
                        </div>

                    </div>
                    <app-create-contract-sections [index]="contract_details.rC_Sections.length+1" [contractDetails]="contract_details.rC_Sections[contract_details.rC_Sections.length+1]" [ActionButtonField]="!ActionButtonContractSection"></app-create-contract-sections>
                    <div style="margin-bottom: 30px;">
                        <a class="bg-transparent cursor text-decoration-none outline-0 border-0 blue fw-bold mb-4" (click)="ActionButtonContractSectionbutton()">
                            <img src="assets/images/apartmentImages/ic_baseline-add.svg" /> create new section
                        </a>
                    </div>
                </div>



                <div class="div-4">
                    <div class="div-5">
                        <a routerLink="/view-inquire/{{param}}" style="border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                        Back
          </a>
                        <div class="div-6">
                            <!-- <button class="auto-layout-button"><div class="label-2">Cancel</div></button> -->
                            <a (click)="gotopage2()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                      Cancel
        </a>

                            <button (click)="CreateAptContract()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                                save
                  </button>
                            <!-- <button class="label-wrapper"><div class="label-3">save</div>
                    </button> -->
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!-- end part 3 to display paroduct Apartment details in page  -->
    </main>
    <!-- end content page  -->
</div>
