<!-- <app-edite-user-details></app-edite-user-details> -->
<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'workers'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'workers'"></app-sidebar>
    <!-- end sidebar  -->

    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
        <div class="d-md-flex justify-content-between dashboard align-items-center" style="margin-top: 6px; margin-bottom: 24px;">
            <p class="fw-bold ms-1" style="color: #6c6a6a;">workers</p>

            <div class="d-flex justify-content-lg-between ">

                <div class="d-flex justify-content-between ">
                    <!-- <button class="month rounded-start px-3 py-1 outline-none">This Month</button>
                  <button class="week rounded-end px-3 py-1 outline-none">Last Week</button> -->
                </div>

                <!-- start dropdown button  -->
                <app-dropdown class="ms-3" style=" margin-right: 14px;margin-top: 2px;" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown>
                <!-- end dropdown button  -->
                <!-- start addUserButton  -->
                <!-- <app-add-new-button class="ms-3 mt-2" [id]="'app-user-details'"></app-add-new-button> -->
                <!-- end addUserButton  -->
                <a *ngIf="workersRole?.p_Add==true || is_Super==true" class="px-3 py-2 rounded border text-white text-decoration-none" routerLink="/workers/add-workers" style="background-color:#1F4068; cursor: pointer;    width: 53%;">
                    <img src="assets/images/plus.svg" class="me-2 ">Add New
                </a>
            </div>
        </div>

        <p-toast></p-toast>
        <div class="card">
          <div class="row d-flex justify-content-between align-items-center px-3">
            <!-- length Of person -->
           <div class="col-md-4">
            <div class="d-flex mx-2 mt-4">
              <p class="me-2 fs-5 fw-bold ml-8">Workers</p>
              <p class="mb-3 bg-gray rounded-5 blue px-2 d-flex align-items-center">
                  {{numberworkers}} Workers
              </p>
          </div>
           </div>
            <!-- search input -->
          <div class="col-md-5 col-8">
              <div class="input">
                <input type="text" class="form-control py-2 w-100" (keyup)="searchAction($event)" [(ngModel)]="searchText" placeholder="Search Worker"/>
                  <img src="../../../assets/images/search.svg" alt="">
              </div>
          </div>
        </div>

            <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="workers">
                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData">
                        <ng-container>


                            <th class="td-name">
                                Name

                            </th>
                            <th class="td-name">
                                Email

                            </th>
                            <th class="td-name">
                                Phone number

                            </th>
                            <th class="td-name">
                                WhatsApp

                            </th>
                            <th class="td-name">
                                job

                            </th>
                            <th class="td-name">
                                user type

                            </th>
                            <th class="td-name">


                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-workers>

                    <tr (click)="hidecard()" style="cursor: pointer;" >


                        <td routerLink="/workers/worker-profile/{{workers?.worker_ID}}">
                            <img *ngIf="workers.worker_Img" [src]="workers?.worker_Img" style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px; cursor: pointer;">
                            <img *ngIf="!workers.worker_Img" src="../../../assets/images/Frame 1261154899.svg" style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px;cursor: pointer;"> {{workers?.worker_FName}}
                            {{workers?.worker_LName}}

                        </td>
                        <td routerLink="/workers/worker-profile/{{workers?.worker_ID}}">
                            {{workers?.worker_Email}}
                        </td>
                        <td routerLink="/workers/worker-profile/{{workers?.worker_ID}}">
                            {{workers?.worker_PhoneNum}}
                        </td>

                        <td routerLink="/workers/worker-profile/{{workers?.worker_ID}}">
                            {{workers?.worker_WANum}}
                        </td>
                        <td routerLink="/workers/worker-profile/{{workers?.worker_ID}}">
                            {{workers?.worker_Job}}
                        </td>
                        <td routerLink="/workers/worker-profile/{{workers?.worker_ID}}">
                            {{workers?.worker_Type}}
                        </td>


                        <td class="cursor position-relative" style="text-align: center;">
                            <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,workers.worker_ID)">
            <img src="assets/images/edit.svg">
        </button>
                            <div *ngIf="showEdit[workers.worker_ID] " class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100" *ngIf="workersRole?.p_Update==true || is_Super==true" routerLink="/workers/edit-workers/{{workers?.worker_ID}}">update</button>

                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" routerLink="/workers/worker-profile/{{workers?.worker_ID}}"> View Profile  </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" *ngIf="workersRole?.p_Delete==true || is_Super==true" (click)="deleteModal(workers?.worker_ID)">Delete </button>

                            </div>

                        </td>

                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>

            </p-table>
            <p-paginator *ngIf="workers.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
              [totalRecords]="totalRecords" [first]="first"></p-paginator>
        </div>
    </main>
    <!-- end content page  -->
</div>


<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}" >
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 400px;">
        <div class="modal-content">
              <div class="modal-header pb-0 mb-0 d-flex justify-content-end">
                <button type="button" class="close border-0" aria-label="Close" #closebutton (click)="onCloseHandled()">
                  <img src="../../../assets/images/close2.svg" alt="">
                </button>
                  </div>
           <div class="modal-body text-center my-0 py-0">
                  <span><img src="../../../assets/images/delete2.svg" alt=""></span>
                  <h5>Confirm Deletion</h5>
                  <p class="text-muted ">Are you want to delete the worker? </p>
            </div>
                  <div class="modal-footer text-center mx-auto mt-0 pt-0 mb-2">
                    <button type="button" class="btn btn-delete mx-2" data-bs-dismiss="modal" (click)="onCloseHandled()">Cancel</button>
                    <button type="button" class="btn btn-color mx-2" id="closeModal" (click)="deleteworker()" >Delete</button>
                  </div>
     </div>
      </div>
</div>
