<!-- <app-edite-user-details></app-edite-user-details> -->
<!-- start header  -->
<header>
  <app-head (newItemEvent)="addItem($event)" [titleModule]="'Messages'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

  <!-- start sidebar  -->
  <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Users'"></app-sidebar>
  <!-- end sidebar  -->

  <!-- start content page  -->
  <main class="p-4 col-lg-10 ">
      <p-toast></p-toast>
      <div class="card">

          <div class="row d-flex justify-content-between align-items-center px-3">
              <!-- length Of person -->
             <div class="col-md-4">
              <div class="d-flex mx-2 mt-4">
                <p class="me-2 fs-5 fw-bold ml-8">
                  <a routerLink="/messages">
                    <img src="./../../../../assets/images/arrow_back.svg" alt="" class="me-2">
                  </a>
                  Select Tenants </p>
            </div>
             </div>
              <!-- search input -->
            <div class="col-md-5 col-8">

                <div class="input">
                  <input type="text" class="form-control py-2 w-100" placeholder="Search Messages" (keyup)="searchAction($event)" [(ngModel)]="searchText" />
                    <img src="../../../assets/images/search.svg" alt="">
                </div>
            </div>
          </div>
          <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="Tenants">
              <ng-template pTemplate="header" class="text-center">
                  <tr *ngIf="headerData" class="tableHead">
                      <ng-container>

                          <th class="td-name">
                            <input type="checkbox" name="selectedContent" class="me-2" (change)="selectAll($event)" />
                              Name

                          </th>
                          <th class="td-name">
                            Apartment Name

                          </th>
                          <th class="td-name">
                              Email address

                          </th>
                          <th class="td-name">
                            Phone number
                          </th>
                          <th class="td-name">
                            WhatsApp
                          </th>
                      </ng-container>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-Tenant>

                  <tr>

                      <td class="d-flex align-items-center">
                        <input type="checkbox" id="selectedUser-{{Tenant.tenant_ID}}" value="{{Tenant.tenant_ID}}"
                        name="selectedUser" (change)="onCheckboxChange($event)" class="me-2"/>
                           {{Tenant?.tenant_Name}}

                      </td>
                      <td>
                       {{Tenant?.apartment_Name}}
                      </td>
                      <td>
                          {{Tenant?.tenant_Mail}}
                      </td>
                      <td >
                       {{Tenant?.tenant_Phone}}
                      </td>
                      <td >
                      {{Tenant?.tenant_WA}}
                       </td>
                  </tr>

              </ng-template>


              <ng-template pTemplate="emptymessage">
                  <div class="container text-center mt-4">
                      <p class="mb-0">No data found.</p>
                  </div>
              </ng-template>

          </p-table>

          <p-paginator *ngIf="Tenants.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
            [totalRecords]="totalRecords" [first]="first"></p-paginator>
      </div>
        <div class="d-flex justify-content-between">
            <button  class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2" routerLink="/messages">
              Cancel
    </button>
            <button type="submit" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important"
       [disabled]="selectedUsersIds.length < 1"  (click)="NextButtons()">
              Next
    </button>
        </div>

  </main>
  <!-- end content page  -->
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}" style=" background-color: rgb(23 22 22 / 50%);;">
  <div class="modal-dialog" role="document" style="    max-width: 800px;">
      <div class="modal-content" style="    margin-top:  100px;">
          <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
              <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                  <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                     Message For Multiple User
          </span>
              </p>
              <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal1()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>

          </div>

          <div class="modal-body">

              <div class="row">
                  <div class="mb-3 col-12 col-md-12">
                      <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Message</label>
                      <textarea class="border p-3 pt-3 rounded-2 w-100" rows="4" style="resize: inherit" placeholder="Please Enter Message"  [(ngModel)]="reply_Desc" ></textarea>

                  </div>

              </div>
              <div class="row px-3">
                <p class="gray fw-bold mb-2">Upload Attchement</p>

                <div class="image-upload mainimage">
                  <input type="file" accept="image/*" multiple="multiple" (change)="selectFile($event)">
                    <div class="image-uploads">
                      <div class="d-flex align-items-center justify-content-center text-center h-100" >
                        <div *ngIf="!apt_imgs" class="text-center imageData pt-2" >
                          <div class="icon m-auto">
                            <img src="/assets/images/upload 1.svg" alt="img">
                           </div>
                           <p class="mb-0">Click to upload <span class="text-muted fw-normal"> or drag and drop</span></p>
                           <p class="text-muted fw-normal">PNG, JPG, GIF, or MP4 (max. 8MB)</p>
                        </div>
                        <ng-container *ngIf="apt_imgs">
                          <img  *ngIf="apt_imgs"  [src]="apt_imgs" alt="img"  style="height: 124px;width:150px;">
                        </ng-container>
                      </div>
                    </div>
                </div>

            </div>





          </div>




          <div class="modal-footer"style="display: flex; justify-content: space-between;">

                        <a (click)=" onCloseModal1()" style="  cursor: pointer;   border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                    Cancel
      </a>
                      <button (click)="SendMsgtoMultiUsers()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                    Send
      </button>
                <!-- Confirm -->

          </div>


      </div>
      <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
