 <!-- start header  -->
 <header >
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Add Contract'"></app-head>
</header>
 <!-- end header  -->

<div class="row m-0">
   <!-- start sidebar  -->
   <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Add Contract'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10 " >

        <div class="d-flex pt-2">
            <p class="me-4 fw-bold gray">Apartments</p>
            <p class="fw-bold me-4">></p>
            <p class="fw-bold gray">{{paramintab}}</p>
        </div>
        <div>
            <app-third-step [createcontractpage]="binary"></app-third-step>
        </div>

    </main>
    <!-- end content page  -->
</div>
