<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Inquiries'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Inquiries'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10">
        <div class="d-md-flex justify-content-between" style="margin-bottom:24px">
            <div class="d-flex" style="align-items: center;">
                <p class="fw-bold me-2" style="color: #6c6a6a;margin-bottom: 0px;"><a style="color: #6c6a6a;" class="removeUnderLine"
                        routerLink="/cancel-inquire">cancelation Inquiries </a></p>

                <p class="fw-bold me-2" style="margin-bottom: 0px; color: #605D62;">></p>
                <p class="fw-bold" style="color: #6c6a6a;margin-bottom: 0px;"> Cancelation Details </p>


            </div>

        </div>


        <p-toast></p-toast>


        <div class="bg-white border rounded-3 p-4">
            <div class="row ">
                <div class="col-12 col-md-6">

                    <ng-container>
                        <div class="container testimonial-group">
                            <div class="row mb-3 vcrr " *ngIf="inquire_details">

                                <!--  <div class="col-md-4  " *ngFor="let img of inquire_details?.apt_Imgs">

                                  <a (click)="viewImage(img?.apt_imgs)" style="cursor: pointer;">
                                      <img [src]="img?.apt_imgs && img?.apt_imgs.includes('https') ? img?.apt_imgs : '../../../assets/images/apartmentImages/default_apartment.jpg'" alt="Apartment Image" class="w-100 h-100 maxImg">
                                  </a>
                              </div> -->
                                <p-galleria [value]="prop_imgs" [containerStyle]="{ 'max-width': '640px' }" [numVisible]="5" class="px-0">
                                    <ng-template pTemplate="item" let-item>
                                        <a (click)="viewImage(item)" style="cursor: pointer;">

                                            <img [src]="item" style="width: 640px; max-height: 480px;max-width:640px" />
                                        </a>
                                    </ng-template>


                                    <ng-template pTemplate="thumbnail" let-item>
                                        <div class="grid  grid-nogutter justify-content-center">
                                            <img [src]="item" style="height:80px;width: 100px;" />
                                        </div>
                                    </ng-template>

                                </p-galleria>
                            </div>
                        </div>
                    </ng-container>

                    <div class="d-flex">
                        <p class="d-lg-inline-block me-3 fw-bold">Rating</p>
                        <div class="mt-0">
                            <p-rating [(ngModel)]="inquire_details.apt_Rating" [readonly]="true" [cancel]="false"></p-rating>
                        </div>
                    </div>

                    <div>
                        <p class="d-lg-inline-block me-3 fw-bold">Descriptions</p>
                        <div class="mt-0">
                            <p class="d-lg-inline-block me-3  ">{{ inquire_details?.apt_Desc }} </p>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="col-12 col-md-5">
                    <div class="mt-0 pt-0">
                        <h4 class="fw-bold my-0">{{ inquire_details?.apt_Name }} </h4>
                        <!-- <p class="gray mt-0">{{ inquire_details?.apt_StName }}</p> -->
                        <h5 class="blue fw-bold my-0">
                            {{ inquire_details?.apt_Price }} / month
                        </h5>
                        <div class="d-flex">
                            <div class="d-flex justify-content-between me-3">
                                <img src="assets/images/apartmentImages/location.svg" />
                                <p class="mt-3 ms-2 gray">{{ inquire_details?.apt_Location }} </p>
                            </div>
                            <div class="d-flex justify-content-between me-3">
                                <img src="assets/images/apartmentImages/room_bed.svg" />
                                <p class="mt-3 gray ms-2">{{ inquire_details?.apt_Rooms }} </p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <img src="assets/images/apartmentImages/user.svg" />
                                <p class="gray mt-3 ms-2">{{ inquire_details?.apt_Guests }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-0">
                        <img src="assets/images/apartmentImages/schedule time.svg" />
                        <p class="gray mt-3 ms-2">Duration: {{ inquire_details?.apt_Period }} </p>
                    </div>
                    <div class="d-flex mt-0">
                        <p class="fw-bold my-0">Requested Appartment</p>

                        <!-- <p class="gray mt-3 ms-2"> {{ inquire_details?.requested_By }} </p> -->
                    </div>
                    <div style="display: flex; align-items: center;cursor: pointer;"
                        routerLink="/users/app-edite-user-details/{{ inquire_details?.user_ID }}"
                        class="w-96 h-16 px-6 py-4 bg-white justify-start items-center gap-3 inline-flex">

                        <img style="width: 64px; height: 64px;  border-radius: 50%;" class="w-10 h-10 rounded-full"
                            [src]="inquire_details?.user_Img!=''?inquire_details?.user_Img:'assets/images/Frame 1261154899.svg'" />
                        <div class="flex-col justify-start items-start inline-flex">
                            <div class="text-zinc-800 text-sm font-semibold font-['Montserrat'] leading-tight">
                                {{ inquire_details?.user_Name }}
                            </div>
                            <div class="text-neutral-400 text-sm font-normal font-['Montserrat'] leading-tight"></div>
                        </div>
                    </div>
                    <div class="accordion accordion-flush mb-5" id="accordionFlushExample">

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button ps-0  collapsed text-black fw-bold bg-transparent border-0" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1transport" aria-expanded="false"
                                    aria-controls="flush-collapseOne1transport">
                                    Cancelation Details
                                </button>
                            </h2>
                            <div id="flush-collapseOne1transport" class="accordion-collapse collapse mt-3"
                                aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="mb-3">
                                    <p class="fw-bold" style="color: #605D62 !important;">Reason of request cancelation</p>
                                    <div class=" w-100 fs-6 pt-2" style="    padding: 5px;
                                  border: 1px solid #cccccca1;
                                  border-radius: 5px;
                                  display: inline-block;
                                  text-wrap: balance;
                                  word-break: break-word;">
                                        <p> {{ inquire_details?.cancel_Reason }}
                                        </p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <p class="fw-bold" style="color: #605D62 !important;">check out Date</p>
                                    <div class="form-control w-100 fs-6 pt-2">{{ inquire_details?.checkout_Date }}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style="display: flex; justify-content: space-between;">
                        <button [disabled]="inquire_details?.checkout_Approval"
                            (click)="onOpenModal2( ) " class="btn btn-color">
                            Accept
                        </button>
                        <button [disabled]="inquire_details?.checkout_Approval"
                            (click)="onOpenModal1( ) " class="btn btn-outline">

                            Reject

                        </button>

                    </div>


                </div>
            </div>

        </div>
    </main>
    <!-- end content page  -->
</div>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document" style="    max-width: 800px;">
        <div class="modal-content" style="    margin-top:  100px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title"
                    style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                        Rejection reason
                    </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close"
                    aria-label="Close" (click)="onCloseModal1()">
                    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
                </button>


            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Reason</label>
                        <textarea class="border p-3 pt-3 rounded-2 w-100" rows="4" style="resize: inherit"
                            placeholder="please enter reason  " [(ngModel)]="Reason"></textarea>

                    </div>

                </div>


            </div>


            <div class="modal-footer" style="display: flex; justify-content: space-between;">

                <a (click)=" onCloseModal1()" style="  cursor: pointer;   border: 1px solid;"
                    class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                    Cancel
                </a>
                <button (click)="TerminationNotApproval()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4"
                    style="background-color: #1f4068 !important">
                    confirm
                </button>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document" style="    max-width: 500px;">
        <div class="modal-content" style="    margin-top:  100px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title"
                    style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">

                    </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close"
                    aria-label="Close" (click)="onCloseModal2()">
                    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
                </button>


            </div>

            <div class="modal-body" style="text-align: center;padding-top: 0px;">
                <img style="    border: 20px solid #FCEEEE;border-radius: 36px;" src="../../../../assets/images/mingcute_warning-line.svg">
                <h5 style="font-weight: 600;
margin-top: 10px;">Accept Cancelation inquire</h5>
                <p>Are you sure you want to accept cancel this inquire? This action is irreversible and will permanently effect all data
                    associated with booking.</p>


            </div>


            <div class="modal-footer" style="display: flex; justify-content: center;">

                <a (click)=" onCloseModal2()" style="  cursor: pointer;   border: 1px solid;    width: 133px;
                          text-align: center;"
                    class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                    NO
                </a>
                <button (click)="TerminationApproval()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4"
                    style="background-color: #1f4068 !important;    width: 140px;">
                    Yes
                </button>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display3}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document" style="max-width: 416px;">
        <div class="modal-content" style="margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;    padding-bottom: 0px;position: relative;">
                <p class="modal-title"
                    style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                    </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close"
                    aria-label="Close" (click)="onCloseModal3()">
                    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;padding-top:0px">
                <img src="../../../assets/images/confirmm.png">


                <p style="margin-bottom: 0px;font-weight: 500;">sent successfully!</p>
                <p style="margin-bottom: 0px;"><b>check out sheet </b>has been sent successfully</p>
                <button (click)="gotoListCancel()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4 " style="background-color: #1f4068 !important ;    margin-top: 15px;
    margin-bottom: 15px;">
                    ← Back
                </button>
            </div>

        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display4}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document" style="max-width: 416px;">
        <div class="modal-content" style="margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;    padding-bottom: 0px;position: relative;">
                <p class="modal-title"
                    style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                    </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close"
                    aria-label="Close" (click)="onCloseModal4()">
                    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;padding-top:0px">
                <img src="../../../assets/images/confirmm.png">


                <p style="margin-bottom: 0px;font-weight: 500;">Rejected successfully!</p>
                <p style="margin-bottom: 0px;"><b>cancelation request</b> has been rejected successfully</p>
                <button (click)="gotoListCancel()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4 " style="background-color: #1f4068 !important ;    margin-top: 15px;
    margin-bottom: 15px;">
                    ← Back
                </button>
            </div>

        </div>
    </div>
</div>
