import { Component, EventEmitter, Input ,Output} from '@angular/core';

@Component({
  selector: 'app-add-new-field-apartment',
  templateUrl: './add-new-field-apartment.component.html',
  styleUrls: ['./add-new-field-apartment.component.scss']
})
export class AddNewFieldApartmentComponent {

}
