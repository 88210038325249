<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Apartments'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Apartments'"></app-sidebar>
    <!-- end sidebar  -->

    <!-- start content page  -->
    <main class="p-4 col-lg-10">

        <div class="d-md-flex justify-content-between dashboard mt-2">
            <div class="d-flex">
                <p routerLink="/apartments" class="fw-bold me-2" style="color: #6c6a6a; cursor: pointer;">Apartments</p>
                <img src="assets/images/user/right-arrow.svg" class="me-2" style="margin-top: -15px">
                <p class="fw-bold" style="color: #6c6a6a">Booking history</p>
            </div>
            <!-- <p  class="fw-bold ms-1" style="color: #6c6a6a;">Apartments </p> -->
            <!-- <p routerLink="/apartments" class="fw-bold me-2" style="color: #6c6a6a">Apartments</p> -->
            <!-- <img src="assets/images/user/right-arrow.svg" class="me-2" style="margin-top: -15px"> -->
            <!-- <p class="fw-bold" style="color: #6c6a6a">Booking history</p> -->
        </div>
        <div class="text-center">

            <div class="card mt-2 bvngd">

                <div class="row d-block d-md-flex justify-content-between my-2 px-3 py-2">


                <div class="col-md-4">
                  <div class="d-flex align-items-center" style="font-size: 15px;">
                    <label for="" style="color: #313331;font-weight: 500;font-size: 16px;">Booking history</label>

                  </div>
                </div>
                 <div class="col-md-5 col-8">
                  <div class="input">
                    <input type="text" class="form-control py-2" placeholder=" Search by appartment location" (keyup)="searchAction($event)" [(ngModel)]="searchText" />
                      <img src="../../../assets/images/search.svg" alt="">
                  </div>
                 </div>

                  <p-toast></p-toast>

                  <!-- end apartmentLists button  -->
              </div>

                <p-table class="table-shopping" [(selection)]="selectedProducts" [dataKey]="dataSelectionKey" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="booking">
                    <ng-template pTemplate="header" class="text-center">
                        <tr *ngIf="headerData">
                            <ng-container *ngFor="let head of headerData">
                                <th *ngIf="head.tableHeaderCheckbox">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th *ngIf="!head.tableHeaderCheckbox && (head.Name && head.SortableColumn)" [pSortableColumn]="head.SortableColumn" class="td-name">
                                    {{head.Name}}
                                    <p-sortIcon *ngIf="head.sortIcon == true" [field]="head.SortableColumn"></p-sortIcon>
                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="header" class="text-center">
                        <tr>
                            <ng-container>

                                <th class="td-name">
                                    booking ID

                                </th>
                                <th class="td-name">
                                    apartment name

                                </th>
                                <th class="td-name">
                                    tenant email

                                </th>
                                <th class="td-name">
                                    check in date

                                </th>
                                <th class="td-name">
                                    check out date
                                </th>


                                <th class="td-name">


                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-book>

                        <tr (click)="hidecard(book.request_ID)">
                            <td>

                                {{book?.req_Code}}
                            </td>

                            <td>
                                {{book?.apt_Name}}
                            </td>
                            <td>
                                {{book?.email}}
                            </td>
                            <td>
                                {{book?.checkIn}}
                            </td>
                            <td>
                                {{book?.checkOut}}
                            </td>
                            <!-- <td>
                      <p class="d-flex align-items-center fw-bold mt-2">{{admin?.status}}</p>
                  </td> -->

                            <td class="cursor position-relative">
                                <button class="border-0 outlin-0 bg-transparent" (click)="detailperson( $event,book.req_ID)">
                <img src="assets/images/edit.svg">
            </button>
                                <div *ngIf="showEdit[book.req_ID] " style="width: 216px;z-index: 999;right: 62px;" class="position-absolute z-3 rounded-3 bg-white border edit-button">
                                    <button class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100" routerLink="/apartments/view-booking/{{book.req_ID}}">view Booking details </button>

                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <div class="container text-center mt-4">
                            <p class="mb-0">No data found.</p>
                        </div>
                    </ng-template>
                </p-table>
              <p-paginator *ngIf="booking.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
                [totalRecords]="totalRecords" [first]="first"></p-paginator>

            </div>

        </div>
    </main>
    <!-- end content page  -->
</div>
