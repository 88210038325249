<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Roles'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Roles'"></app-sidebar>

    <main class="p-4 col-lg-10">

        <div class="fw-inquiries d-flex justify-content-between align-items-center" style="color: #6c6a6a;margin-top: 7px; margin-bottom: 15px;">
          <p class="fw-bold ms-1" style="color: #6c6a6a;">Roles</p>
          <div class="d-flex justify-content-lg-between  ">
            <div class="d-flex justify-content-lg-between flex-md-row flex-column">
              <div class="d-flex align-items-center">
                  <!-- start dropdown button  -->
                  <app-dropdown class="me-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown>
              </div>
                <a *ngIf="AdminRole?.p_Add==true || is_Super==true" (click)="addOpenRole()" class="px-3 py-2 rounded border text-white text-decoration-none" style="background-color:#1F4068; cursor: pointer;">
                    <img src="assets/images/plus.svg" class="ms-2 ">Add New
                </a>
            </div>
          </div>


      </div>


        <p-toast></p-toast>
        <div class="card">

            <!-- <p-table #dt [value]="roles" [(selection)]="roles" dataKey="id" [filterDelay]="0" /> -->
            <div class="row d-flex justify-content-between align-items-center px-3">
                <!-- length Of person -->
                <div class="col-md-4">
                  <div class="d-flex mx-2 mt-4">
                    <p class="me-2 fs-5 fw-bold ml-8"> Roles</p>
                    <p class="mb-3 bg-gray rounded-5 blue px-2 d-flex align-items-center">
                        {{numberRoles}}
                    </p>
                </div>
                </div>
                <!-- search input -->
                <!-- <div class="col-md-5">
                  <div class="input">
                    <input type="text" class="form-control py-2 w-100" placeholder="Global Search" (keyup)="searchAction()" [(ngModel)]="searchText" />
                      <img src="../../../assets/images/search.svg" alt="">
                  </div>
                </div> -->
            </div>

            <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="roles">
                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData">
                        <ng-container>

                            <th class="td-name">
                                ID

                            </th>
                            <th class="td-name">
                                Name

                            </th>
                            <th class="td-name">

                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-role>

                    <tr style="cursor: pointer;" (click)="hidecard()">

                        <td>
                            {{role.id}}
                        </td>
                        <td>{{role.name}}</td>


                        <td class="cursor position-relative" style="text-align: center;">
                            <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,role.id)">
                      <img src="assets/images/edit.svg">
                  </button>
                            <div *ngIf="showEdit[role.id]" class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100" *ngIf="AdminRole?.p_Update==true || is_Super==true" (click)="UpdateOpenRole(role)">update Role</button>
                                <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100" routerLink="/roles/authorization/{{role.id}}">Give authorization</button>
                                <!-- <button class="d-block border-0 outlin-0 bg-transparent my-2 button text-start py-2 w-100">Assigned users</button> -->
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" *ngIf="AdminRole?.p_Delete==true || is_Super==true" (click)="DeleteRole(role.id)"> Delete  </button>
                            </div>
                        </td>

                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>
            </p-table>

            <!-- <p-paginator *ngIf="roles" (onPageChange)="tiggerPageChange($event)" [rows]="10" [totalRecords]="fullRespone?.totalRecords"></p-paginator> -->

        </div>

    </main>
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
                      update Role
                </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal1()">
    <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
  </button>



            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Role name</label>
                        <input type="text" class="form-control rounded-2 py-2 fs-6" [(ngModel)]="updataRoleName" />

                    </div>
                    <!-- <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">Role discerption</label>
                        <textarea class="border p-3 pt-3 rounded-2 w-100" rows="5" style="resize: inherit"></textarea>

                    </div> -->
                </div>

            </div>




            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between">
                        <a (click)="onCloseModal1()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                          Cancel
            </a>
                        <button class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                          confirm
            </button>
                    </div>
                </div>
                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
Add New Role
              </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal2()">
  <span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Role name</label>
                        <input type="text" [(ngModel)]="data" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>
                    <!-- <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">Role discerption</label>
                        <textarea class="border p-3 pt-3 rounded-2 w-100" rows="5" style="resize: inherit"></textarea>

                    </div> -->
                </div>

            </div>




            <div class="modal-footer">

                    <div class="d-flex justify-content-between w-100">
                        <a (click)="onCloseModal2()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold xcvdd">
                        Cancel
          </a>
                        <button (click)="createRole()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                        confirm
          </button>
                    </div>

                <!-- Confirm -->

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
