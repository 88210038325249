<div class="bg-white p-3">
    <!--Backup information-->
    <div>
        <p class="fw-bold mb-0">Backup information</p>
        <p class="gray">Add backup information to your property.</p>
    </div>
    <p-toast></p-toast>

    <form [formGroup]="PostBackupInfo" (ngSubmit)="Create_PostBackupInfo(PostBackupInfo)" [autocomplete]="false">

        <div>
            <div class="row">
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label gray fw-bold">Electricity meter number</label>
                    <input formControlName='apartment_Electricity_Meter_No' type="string" [class.is-invalid]="PostBackupInfo.get('apartment_Electricity_Meter_No')!.invalid && PostBackupInfo.get('apartment_Electricity_Meter_No')!.touched " class="form-control rounded-2 py-2 fs-6">
                    <span class="text-danger d-block m-2 " *ngIf="PostBackupInfo.get('apartment_Electricity_Meter_No')!.invalid && PostBackupInfo.get('apartment_Electricity_Meter_No')!.touched ">
                        this field is required
                    </span>
                </div>
                <div class="mb-3 col-12 col-md-6">
                    <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">Electricity meter
                        consumption</label>
                    <input formControlName='apartment_Electricity_Meter_Consumption' type="string" [class.is-invalid]="PostBackupInfo.get('apartment_Electricity_Meter_Consumption')!.invalid && PostBackupInfo.get('apartment_Electricity_Meter_Consumption')!.touched " class="form-control rounded-2 py-2 fs-6" id="exampleInputSecuritydeposit">
                    <span class="text-danger d-block m-2 " *ngIf="PostBackupInfo.get('apartment_Electricity_Meter_Consumption')!.invalid && PostBackupInfo.get('apartment_Electricity_Meter_Consumption')!.touched ">
                        this field is required
                    </span>
                </div>

            </div>
            <div class="row">
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label gray fw-bold">Water meter number</label>
                    <input formControlName='apartment_Water_Meter_No' type="string" [class.is-invalid]="PostBackupInfo.get('apartment_Water_Meter_No')!.invalid && PostBackupInfo.get('apartment_Water_Meter_No')!.touched " class="form-control rounded-2 py-2 fs-6">
                    <span class="text-danger d-block m-2 " *ngIf="PostBackupInfo.get('apartment_Water_Meter_No')!.invalid && PostBackupInfo.get('apartment_Water_Meter_No')!.touched ">
                        this field is required
                    </span>
                </div>
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label gray fw-bold">Water meter consumption</label>
                    <input formControlName='apartment_Water_Meter_Consumption' type="string" [class.is-invalid]="PostBackupInfo.get('apartment_Water_Meter_Consumption')!.invalid && PostBackupInfo.get('apartment_Water_Meter_Consumption')!.touched " class="form-control rounded-2 py-2 fs-6">
                    <span class="text-danger d-block m-2 " *ngIf="PostBackupInfo.get('apartment_Water_Meter_Consumption')!.invalid && PostBackupInfo.get('apartment_Water_Meter_Consumption')!.touched ">
                        this field is required
                    </span>
                </div>

            </div>
            <!-- <div class="row" *ngIf="addApartment != 'Edit Apartment Name' "> -->
            <div class="row">
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label gray fw-bold">Gas meter number</label>
                    <input formControlName='apartment_Gas_Meter_No' type="string" [class.is-invalid]="PostBackupInfo.get('apartment_Gas_Meter_No')!.invalid && PostBackupInfo.get('apartment_Gas_Meter_No')!.touched " class="form-control rounded-2 py-2 fs-6">
                    <span class="text-danger d-block m-2 " *ngIf="PostBackupInfo.get('apartment_Gas_Meter_No')!.invalid && PostBackupInfo.get('apartment_Gas_Meter_No')!.touched ">
                        this field is required
                    </span>
                </div>
                <div class="mb-3  col-12 col-md-6">
                    <label class="form-label gray fw-bold">Gas meter consumption</label>
                    <input formControlName='apartment_Gas_Meter_Consumption' type="string" [class.is-invalid]="PostBackupInfo.get('apartment_Gas_Meter_Consumption')!.invalid && PostBackupInfo.get('apartment_Gas_Meter_Consumption')!.touched " class="form-control rounded-2 py-2 fs-6">
                    <span class="text-danger d-block m-2 " *ngIf="PostBackupInfo.get('apartment_Gas_Meter_Consumption')!.invalid && PostBackupInfo.get('apartment_Gas_Meter_Consumption')!.touched ">
                        this field is required
                    </span>
                </div>

            </div>
            <!-- <div class="row" *ngIf="addApartment == 'Edit Apartment Name' ">
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label gray fw-bold">Wi-Fi Name</label>
                    <input type="number" disabled [(ngModel)]="wifi.wifi_Name" [ngModelOptions]="{standalone: true}" class="form-control rounded-2 py-2 fs-6">
                </div>
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label gray fw-bold">Wi-Fi password</label>
                    <input type="text" disabled [(ngModel)]="wifi.wifi_Password" [ngModelOptions]="{standalone: true}" class="form-control rounded-2 py-2 fs-6">
                </div>

            </div> -->
            <!-- <div class="row" *ngIf="addApartment == 'Edit Apartment Name' ">
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label gray fw-bold">Contract start date</label>
                    <p-calendar></p-calendar>
                </div>
                <div class="mb-3  col-12 col-md-6">
                    <label class="form-label gray fw-bold">Contract end date</label>
                    <p-calendar></p-calendar>
                </div>

            </div> -->

            <!-- create new field-->
            <ng-container>
                <div class="row" *ngFor="let data  of inputField ">
                    <div class="mb-3 col-12 col-md-6">
                        <label class="form-label gray fw-bold">{{data.field_Name}} </label>
                        <input type="text" value="{{data.field_Content}}" class="form-control rounded-2 py-2 fs-6" />
                    </div>


                </div>
            </ng-container>
            <!-- <app-input-field [labelinputs]="Labelfield" (inputField)="saveInputField($event)" [textButton]="'Create New Field'"></app-input-field> -->
            <app-input-create-field [labelinputs]="Labelfield" (inputField)="saveInputField($event)" [textButton]="'Create new field'"></app-input-create-field>


        </div>
        <hr>




        <div class="room" *ngFor="let room of Rooms_Devices ; let i = index">
          <div class="d-flex justify-content-between">
            <p class="fw-bold">{{room.room_Name}}</p>
            <div class="buttons d-flex align-items-center">
              <div class="form-check me-3">
                <input class="form-check-input" type="checkbox" id="selectAll{{i}}" (change)="toggleSelectAll($event, i)" />
                <label class="text-dark fs-6" [for]="'selectAll' + i">Select all</label>
              </div>
              <a class="bg-transparent outline-0 text-decoration-none border-0 blue fw-bold" style="cursor: pointer;" (click)="createNewField(i)">
                <img src="assets/images/apartmentImages/ic_baseline-add.svg"> Create new field
              </a>
              <a (click)="addRoom(room.room_Name)" *ngIf="room.room_Name==='Kitchen' || room.room_Name==='Bathroom' " class="bg-transparent outline-0 text-decoration-none border-0 blue fw-bold" style="cursor: pointer;" (click)="createNewField(i)">
                <img src="assets/images/apartmentImages/ic_baseline-add.svg"> Add new {{room.room_Name}}
              </a>
            </div>
          </div>

          <div class="row g-4 py-3">
            <div class="col-md-4" *ngFor="let item of Rooms_Devices[i].room_Devices; let j = index">
              <div class="box">
                <div class="header d-flex justify-content-between align-items-center">
                  <div class="form-check me-3">
                    <input class="form-check-input" type="checkbox" [id]="'checkbox' + i + '-' + j" [(ngModel)]="item.is_Checked" [ngModelOptions]="{standalone: true}" (change)="updateCheckedItems(item, i,j)" />
                    <label class="text-dark fs-6" [for]="'checkbox' + i + '-' + j">{{ item.device_Name }}</label>
                  </div>
                  <div (click)="removeField(i, j)" style="cursor: pointer;">
                    <img src="/assets/images/remove.svg" alt="">
                    <span class="text-muted small">Remove</span>
                  </div>
                </div>
                <div class="data">  <textarea name="Description" [id]="'description' + i + '-' + j" placeholder="Description" class="form-control" rows="4" [(ngModel)]="item.device_Description"  (ngModelChange)="onDescriptionChange(item.device_Description)"  [ngModelOptions]="{standalone: true}"></textarea>

                </div>
              </div>
            </div>

          </div>

          <hr>
        </div>

        <div class="d-flex justify-content-between" *ngIf="shouldDisplay()" >
          <p class="fw-bold"> </p>
          <div class="buttons d-flex align-items-center">

            <a (click)="addRoom('Bathroom')" class="bg-transparent outline-0 text-decoration-none border-0 blue fw-bold" style="cursor: pointer;" >
              <img src="assets/images/apartmentImages/ic_baseline-add.svg">Add New Bathroom
            </a>
            <a (click)="addRoom('Kitchen')" class="bg-transparent outline-0 text-decoration-none border-0 blue fw-bold" style="cursor: pointer;" >
              <img src="assets/images/apartmentImages/ic_baseline-add.svg">Add New Kitchen
            </a>
          </div>
          </div>

        <div *ngIf="Qdamage">
            <p class="d-md-inline-block gray fw-bold mb-0 me-3">Does apartment currently have any existing damage?</p>
            <div class="form-check d-inline-block mb-2" *ngFor="let data of apartmentCurrentlyExisting">
                <input class="form-check-input " [checked]="CreateapartmentCurrentlyExisting === data" type="radio" name="createContract" value="{{data}}" id="createContract" (change)="DoyouapartmentCurrentlyExisting($event)">
                <label class="fw-bold me-2" for="createContract"> {{data}} </label>
            </div>
        </div>
        <div *ngIf="CreateapartmentCurrentlyExisting == 'Yes'">
            <p class="gray fw-bold">Upload pictures for current damage</p>

            <div class="row">
                <!-- <p-progressSpinner *ngIf="spinner == true"></p-progressSpinner> -->

                <div>
                    <div class="position-relative h-124px mb-3">
                        <!-- start single img upload and preview -->
                        <ng-container>
                            <input type="file" accept="image/*" multiple="multiple" (change)="selectFile($event)" class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                            <img src="assets/images/apartmentImages/_File upload base.svg" class="position-absolute" width="100%">
                        </ng-container>

                        <!-- end single img upload and preview -->
                    </div>
                </div>
            </div>
            <!-- <div class="row" style="margin-bottom: 13px;">
                <div class="col-8 bomar">

                    <button type="button" style="background-color: #1f4068 !important;margin-left: 10px;" class="rounded-2 text-white rounded-2 border-0 py-2 px-4  " [disabled]="!selectedFiles" (click)="upload()">
  Confirm
</button>
                </div>
            </div> -->
            <div class="card1">
                <div class="row">
                    <div class="col-md-2 card33" *ngFor="let image of apt_imgs;  index as i ">
                        <a (click)="removeItem(image)" style="      border: none;   margin-bottom: 10px; position: absolute;">
                            <img class="imageRemove" src="../../../../../../assets/images/delete.svg" width="32px" height="32px;">
                        </a>
                        <!-- <input type="checkbox" id="st{{i}}" (change)="checkValue($event,image)" /> -->
                        <!-- <input type="radio" name="color" id="st{{i}}" (change)="checkValue($event,image.pic_URL)"> -->

                        <!-- <label for="st{{i}}"></label> -->
                        <a (click)="opencloseModal(image)">
                            <img [src]="image" alt="Avatar" style="width:339px;height:205px ;" class="cursor">
                        </a>

                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-2 card33" *ngFor="let image of ListFiles;  index as i ">
                        <a (click)="removeItem(image.name)" style="      border: none;   margin-bottom: 10px; position: absolute;">
                            <img class="imageRemove" src="../../../../../../assets/images/delete.svg" width="32px" height="32px;">
                        </a>

                        <a (click)="opencloseModal(urls[i])">
                            <img [src]="urls[i]" alt="Avatar" style="width:339px;height:205px ; ">

                        </a>

                    </div>
                </div> -->
                <div class="row" *ngIf="storedImages!=null||storedImages!=''">
                    <div class="col-md-2 card33" *ngFor="let image of storedImages;  index as i ">

                        <a (click)="opencloseModal(image)">
                            <img [src]="image" alt="Avatar" style="width:339px;height:205px ; " class="cursor">

                        </a>

                    </div>
                </div>
                <div class="row" *ngIf="edit=='EditForm'">
                    <div class="col-md-2 card33" *ngFor="let image of aprt_details_Edit?.apartment_Damages_Imgs;  index as i ">

                        <a (click)="opencloseModal(image)">
                            <img [src]="image" alt="Avatar" style="width:339px;height:205px ; " class="cursor">

                        </a>

                    </div>
                </div>
            </div>
            <!-- <div>
                <p class="gray fw-bold">Upload pictures for current damage</p>
                <div *ngIf="addApartment != 'Edit Apartment Name'">
                    <div class="position-relative h-124px mb-3">
                        <ng-container *ngIf="!selectedContract">
                            <input type="file" accept="image/*" multiple="onUploadContract($event)" class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                            <img src="assets/images/apartmentImages/_File upload base.svg" class="position-absolute" width="100%">
                        </ng-container>
                        <ng-container *ngIf="selectedContract">
                            <input type="file" accept="image/*" (change)="onUploadContract($event)" class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                            <img [src]="selectedContract ? selectedContract.url : null" class="position-absolute imgPreviewUploader" width="100%">
                        </ng-container>
                    </div>
                </div>
                <div class="mb-4" *ngIf="addApartment == 'Edit Apartment Name'">
                    <div>
                        <div class="d-flex">
                            <div class="w-145px me-2 h-124px">
                                <img class="w-100 h-100" src="assets/images/apartmentImages/photo.svg" />
                            </div>
                            <div class="w-145px me-2 h-124px">
                                <img class="w-100 h-100" src="assets/images/apartmentImages/photo.svg" />
                            </div>
                            <div class="w-145px me-2 h-124px">
                                <img class="w-100 h-100" src="assets/images/apartmentImages/photo.svg" />
                            </div>
                            <div class="w-145px me-2 h-124px">
                                <img class="w-100 h-100" src="assets/images/apartmentImages/photo.svg" />
                            </div>
                            <div class="w-145px me-2 h-124px">
                                <div class="position-relative h-124px mb-3">
                                    <input type="file" accept="image/*" class="ng-star-inserted d-block w-100 h-124px file position-absolute">
                                    <img src="assets/images/apartmentImages/File upload1.svg" class="position-absolute">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div>
                <div class="mb-3 mt-4">
                    <label for="exampleFormControlTextarea1" class="form-label gray">Description</label>
                    <textarea formControlName="apartment_Description" class=" border p-3 rounded-2 w-100" rows="5" style="resize: inherit;"></textarea>
                </div>

            </div>
        </div>

        <hr>
        <!--payments-->
        <div>
            <div class="row mb-4">
                <div class="col-12 col-md-9 mb-2">
                    <p class="fw-bold blue mb-0">Payments</p>
                    <div class="col-12 blue text-muted">
                        Select payment methods to Receive money when rental income or security deposit payments are made
                    </div>
                </div>
                <div class="col-12 pt-3">
                  <div class="d-flex">
                    <p class="me-3 mt-1 fw-bold gray">Security Deposit</p>
                    <p-inputSwitch  formControlName="apartment_Is_Security_Payment" ></p-inputSwitch>

                </div>
                    <div class="d-flex">
                      <p class="me-3 mt-1 fw-bold gray">Online</p>
                        <p-inputSwitch formControlName="apartment_Is_Online_Payment"></p-inputSwitch>
                    </div>
                    <!-- <div class="d-flex">
                        <p-inputSwitch [(ngModel)]="checkedstripe" [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">stripe</p>
                    </div>
                    <div class="d-flex">
                        <p-inputSwitch [(ngModel)]="checkedPayPal" [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                        <p class="ms-2 mt-1 fw-bold gray">PayPal</p>
                    </div> -->
                    <div class="d-flex">
                      <p class="me-3 mt-1 fw-bold gray">Cash</p>
                        <p-inputSwitch formControlName="apartment_Is_Cash_Payment"></p-inputSwitch>
                    </div>

                </div>
            </div>
            <hr>
            <!-- General comment -->
            <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label gray">General comment</label>
                <textarea formControlName='apartment_General_Description' class=" border p-3 rounded-2 w-100" rows="5" style="resize: inherit;"></textarea>
            </div>
        </div>

        <!--cancel or publish button-->
        <div class="d-flex justify-content-between">
            <button (click)="PrevPage()" class="backcancel bg-white rounded-2 py-2 px-3 fw-bold">Back</button>
            <div class="d-flex justify-content-between">
                <!-- <button class="backcancel bg-white rounded-2 py-2 px-3 fw-bold me-4">save and close</button> -->
                <button class="rounded-2 text-white outline-0 border-0  rounded-2 py-2 px-4" style="background-color:#1F4068 !important;">Publish</button>
            </div>
        </div>
    </form>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display22}" style="background-color: rgba(90,90,90,0.5);">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document" style="width:900px;">
      <div class="modal-content position-relative">
        <div class="modal-header p-0 m-0 d-flex justify-content-start">
          <button type="button" class="close" aria-label="Close" (click)="oncloseModal()">
            <img src="../../../../assets/images/ic_round-close.svg" alt="">
          </button>
            </div>
          <div class="modal-body p-0 m-0">
              <img [src]="imageSize" style="width:100%;height: 500px" />

          </div>

      </div>
  </div>
</div>
