<!-- <app-edite-user-details></app-edite-user-details> -->
<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'Users'"></app-head>
</header>
<!-- end header  -->

<div class="row m-0">

    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'Users'"></app-sidebar>
    <!-- end sidebar  -->

    <!-- start content page  -->
    <main class="p-4 col-lg-10 ">
      <div class="d-md-flex justify-content-between align-items-center dashboard" style="margin-top: 8px; margin-bottom: 15px;">
        <p class="fw-bold ms-1" style="color: #6c6a6a;">Users</p>
        <div class="d-flex justify-content-lg-between flex-md-row flex-column">

          <div class="d-flex mb-2">
              <!-- start dropdown button  -->
              <app-dropdown class="me-3" [list]="listDropDown" (selected)="selectedfromDropDown($event)"></app-dropdown>
              <!-- end dropdown button  -->
              <!-- start add button (add new owner) in page  -->
              <!-- <app-add-new-button  [id]="'/user/create_new'" class="mt-2"></app-add-new-button> -->
              <!-- end add button (add new owner) in page  -->
          </div>
          <div class="d-flex mb-2 me-3 mb-2">
            <button class="week rounded-start px-3 py-2 bg-w outline-none" (click)="FilterButtons('This Month')" [ngClass]="{'activeButton': monthButton == true}">This Month</button>
            <button class="week px-3 py-2 outline-none rounded-end" (click)="FilterButtons('Last Week')" [ngClass]="{'activeButton': weekButton == true}">Last Week</button>
          </div>
        </div>
    </div>


        <p-toast></p-toast>
        <div class="card">

            <!-- <p-table #dt [value]="Tenants" [(selection)]="Tenants" dataKey="id" [filterDelay]="0" /> -->
            <div class="row d-flex justify-content-between align-items-center px-3">
                <!-- length Of person -->
               <div class="col-md-4">
                <div class="d-flex mx-2 mt-4">
                  <p class="me-2 fs-5 fw-bold ml-8">Users</p>
                  <p class="mb-3 bg-gray rounded-5 blue px-2 d-flex align-items-center">
                      {{numberTenants}} Users
                  </p>
              </div>
               </div>
                <!-- search input -->
              <div class="col-md-5 col-8">
                  <div class="input">
                    <input type="text" class="form-control py-2 w-100" placeholder="Search Users" (keyup)="searchAction($event)" [(ngModel)]="searchText" />
                      <img src="../../../assets/images/search.svg" alt="">
                  </div>
              </div>
            </div>
            <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem'}" [value]="Tenants">
                <ng-template pTemplate="header" class="text-center">
                    <tr *ngIf="headerData" class="tableHead">
                        <ng-container>


                            <th class="td-name">
                                Name

                            </th>
                            <th class="td-name">
                                Registered time

                            </th>
                            <th class="td-name">
                                Email address

                            </th>
                            <th class="td-name">
                                Number of Rented
                                <img src="./assets/images/arrow_downward.svg" alt="">

                            </th>
                            <th class="td-name">
                                Behaviour
                                <img src="./assets/images/arrow_downward.svg" alt="">

                            </th>
                            <!-- <th class="td-name">
                                Status

                            </th> -->
                            <th class="td-name">
                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-Tenant>

                    <tr (click)="hidecard(Tenant.request_ID)"   style="cursor: pointer;">

                        <td class="d-flex align-items-center">

                            <img *ngIf=" Tenant.user_Photo  " routerLink="/users/app-edite-user-details/{{Tenant?.user_ID}}" [src]="Tenant?.user_Photo" style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px;cursor: pointer;">


                            <img *ngIf="!Tenant.user_Photo" routerLink="/users/app-edite-user-details/{{Tenant?.user_ID}}" src="../../../assets/images/Frame 1261154899.svg" style="height: 50px;width: 50px;border-radius: 50%;margin-right: 9px;cursor: pointer;"> {{Tenant?.user_Name}}

                        </td>
                        <td routerLink="/users/app-edite-user-details/{{Tenant?.user_ID}}">
                            {{Tenant?.reg_Time}}
                        </td>
                        <td routerLink="/users/app-edite-user-details/{{Tenant?.user_ID}}">
                            {{Tenant?.email}}
                        </td>
                        <td routerLink="/users/app-edite-user-details/{{Tenant?.user_ID}}">
                          <span class="ms-3"> {{Tenant?.rent_No}} </span>
                        </td>

                        <!-- <td>
                    <p class="d-flex align-items-center fw-bold mt-2">{{admin?.status}}</p>
                </td> -->
                        <td routerLink="/users/app-edite-user-details/{{Tenant?.user_ID}}">
                          <div>
                              <span class="rounded-5 px-3 badge excellent" *ngIf="Tenant?.user_Behavior=='Excellent'">
                                <span>
                                  <img src="assets/images/greenDot.svg" alt="">
                                </span>
                                {{Tenant?.user_Behavior}}
                            </span>
                            <span class="rounded-5 px-3 badge bad" *ngIf="Tenant?.user_Behavior=='Bad'">
                              <span>
                                <img src="assets/images/redDot.svg" alt="">
                              </span>
                              {{Tenant?.user_Behavior}}
                          </span>
                          <span class="rounded-5 px-3 badge good"  *ngIf="Tenant?.user_Behavior=='Good'">
                            <span>
                              <img src="assets/images/yallowDot.svg" alt="">
                            </span>
                            {{Tenant?.user_Behavior}}
                        </span>
                        <span class="rounded-5 px-3  badge average"  *ngIf="Tenant?.user_Behavior=='Average'">
                          <span>
                            <img src="assets/images/purbleDot.svg" alt="">
                          </span>
                          {{Tenant?.user_Behavior}}
                      </span>
                          </div>
                      </td>
                        <!-- <td>
                            <div>
                                <p-tag severity="success" value="Active" [rounded]="true " *ngIf="Tenant?.status==true"> </p-tag>
                                <p-tag severity="danger" value="Suspended" [rounded]="true " *ngIf="Tenant?.status==false"> </p-tag>
                            </div>
                        </td> -->

                         <td class="cursor position-relative" style="text-align: center;">
                            <button class="border-0 outlin-0 bg-transparent" (click)="detailperson($event,Tenant.user_ID)">
              <img src="assets/images/edit.svg">
          </button>
                            <div *ngIf="showEdit[Tenant.user_ID] " class="Actions position-absolute z-3 rounded-3 bg-white border edit-button">
                                <button class="d-block border-0 outlin-0 bg-transparent button text-start py-2 w-100" *ngIf="TantsRole?.p_Update==true || is_Super==true" routerLink="/users/app-edite-user-details/{{Tenant?.user_ID}}">Edit</button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" (click)="deleteModal(Tenant.user_ID)" *ngIf="TantsRole?.p_Delete==true || is_Super==true"> Delete </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" *ngIf="Tenant?.status!=false" (click)="SuspendModal(Tenant.user_ID)">Suspend User </button>
                                <button class="d-block border-0 outlin-0 bg-transparent button w-100 text-start py-2" *ngIf="Tenant?.status==false" (click)="UnSuspendModal(Tenant.user_ID)">UnSuspend User </button>

                            </div>

                        </td>

                    </tr>

                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <div class="container text-center mt-4">
                        <p class="mb-0">No data found.</p>
                    </div>
                </ng-template>

            </p-table>

            <p-paginator *ngIf="Tenants.length > 0" (onPageChange)="tiggerPageChange($event)" [rows]="rows"
              [totalRecords]="totalRecords" [first]="first"></p-paginator>
        </div>


    </main>
    <!-- end content page  -->
</div>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}" *ngIf="model1 && !model2 && !model3">
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 400px;">
        <div class="modal-content">
              <div class="modal-header pb-0 mb-0 d-flex justify-content-end">
                <button type="button" class="close border-0" aria-label="Close" #closebutton (click)="onCloseHandled()">
                  <img src="../../../assets/images/close2.svg" alt="">
                </button>
                  </div>
           <div class="modal-body text-center my-0 py-0">
                  <span><img src="../../../assets/images/delete2.svg" alt=""></span>
                  <h5>Confirm Deletion</h5>
                  <p class="text-muted ">Are you want to delete the user? </p>
            </div>
                  <div class="modal-footer text-center mx-auto mt-0 pt-0 mb-2">
                    <button type="button" class="btn btn-delete mx-2" data-bs-dismiss="modal" (click)="onCloseHandled()">Cancel</button>
                    <button type="button" class="btn btn-color mx-2" id="closeModal" (click)="DeleteUser()" >Delete</button>
                  </div>
     </div>
      </div>
</div>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}"  *ngIf="model2 &&!model1 && !model3">
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 400px;">
        <div class="modal-content">
              <div class="modal-header pb-0 mb-0 d-flex justify-content-end">
                <button type="button" class="close border-0" aria-label="Close" #closebutton (click)="onCloseHandled()">
                  <img src="../../../assets/images/close.svg" alt="">
                </button>
                  </div>
           <div class="modal-body text-center my-0 py-0">
                  <span><img src="../../../assets/images/block.svg" alt=""></span>
                  <h5>Confirm Suspension</h5>
                  <p class="text-muted ">Are you want to suspend the user? </p>
            </div>
                  <div class="modal-footer text-center mx-auto mt-0 pt-0 mb-2">
                    <button type="button" class="btn btn-delete mx-2" data-bs-dismiss="modal" (click)="onCloseHandled()">Cancel</button>
                    <button type="button" class="btn btn-color mx-2"
                        id="closeModal" (click)="SuspendUser()" >Suspend
                  </button>
                  </div>
     </div>
      </div>
</div>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}"  *ngIf="model3 &&!model1 && !model2">
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 400px;">
        <div class="modal-content">
              <div class="modal-header pb-0 mb-0 d-flex justify-content-end">
                <button type="button" class="close border-0" aria-label="Close" #closebutton (click)="onCloseHandled()">
                  <img src="../../../assets/images/close.svg" alt="">
                </button>
                  </div>
           <div class="modal-body text-center my-0 py-0">
                  <span><img src="../../../assets/images/block.svg" alt=""></span>
                  <h5>Confirm UnSuspension</h5>
                  <p class="text-muted ">Are you want to un-suspend the user? </p>
            </div>
                  <div class="modal-footer text-center mx-auto mt-0 pt-0 mb-2">
                    <button type="button mx-2" class="btn btn-delete" data-bs-dismiss="modal" (click)="onCloseHandled()">Cancel</button>
                    <button type="button mx-2" class="btn btn-color"
                        id="closeModal" (click)="unSuspendUser()" >UnSuspend
                  </button>
                  </div>
     </div>
      </div>
</div>
