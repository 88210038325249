<!-- start header  -->
<header>
    <app-head (newItemEvent)="addItem($event)" [titleModule]="'partners'"></app-head>
</header>
<!-- end header  -->

<p-toast></p-toast>

<div class="row m-0">
    <!-- start sidebar  -->
    <app-sidebar class="col-lg-2" [showSide]="showSide" [titlepage]="'partners'"></app-sidebar>
    <!-- end sidebar  -->
    <!-- start content page  -->
    <main class="p-4 col-lg-10">
        <div class="d-md-flex justify-content-between">
            <div class="d-flex">

                <p class="fw-bold me-2" style="color: #6c6a6a"><a style="color: black" class="removeUnderLine" routerLink="/partner">partners </a></p>
                <p class="fw-bold me-2">></p>
                <p class="fw-bold" style="color: black">view partners</p>
            </div>

        </div>
        <!-- start header  -->

        <!-- end header  -->

        <p-toast></p-toast>

        <!-- start sidebar  -->
        <!-- end sidebar  -->
        <!-- start content page  -->

            <div class="profile1 w-100">
                <div style="color: #605D62; font-size: 20px; font-family: Poppins; font-weight: 600; line-height: 24px; word-wrap: break-word">Partner profile</div>
                <!-- style="width: 1082px; height: 833px; justify-content: center; align-items: flex-start; gap: 32px; display: inline-flex" -->

                <div class="row w-100 d-flex mx-0">
                    <div class="col-lg-4 px-0">
                        <div style="width: 350px; padding-top: 1px; padding-bottom: 87px; background: #F9F9F9; border-radius: 8px; overflow: hidden; flex-direction: column; justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                            <img *ngIf="detil_part?.partner_Photo"  style="height: 260px; position: relative; background: linear-gradient(0deg, white 0%, white 100%)" class="w-100" [src]="detil_part?.partner_Photo" />
                            <img *ngIf="!detil_part?.partner_Photo"  style="height: 260px; position: relative; background:#F9F9F9" class="w-100" src="../../../assets/images/Frame 1261154899.svg" />
                            <div style="align-self: stretch; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 32px; display: inline-flex;padding-left: 15px;">
                                <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: flex">
                                    <div style="color: #484649; font-size: 20px; font-family: Poppins; font-weight: 600; line-height: 24px; word-wrap: break-word">{{detil_part?.partner_Name}} </div>
                                    <div style="color: #787579; font-size: 14px; font-family: Poppins; font-weight: 400; line-height: 16px; word-wrap: break-word">{{detil_part?.partner_Type}}</div>
                                </div>
                                <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                    <div style="color: #313033; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 16px; word-wrap: break-word">about Partner</div>
                                    <div style="width: 322px;height: 200px; color: #484649; font-size: 16px; font-family: Poppins; font-weight: 400; text-transform: capitalize; line-height: 23px; word-wrap: break-word">{{detil_part?.partner_About}} </div>
                                </div>
                                <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                    <div style="color: #313033; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 16px; word-wrap: break-word">contact information</div>
                                    <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                        <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                                            <div style="width: 24px; height: 24px; position: relative">
                                                <div style="width: 20px; height: 16px; left: 2px; top: 4px; position: absolute; "> <img src="../../../../assets/images/ic_outline-email.svg"></div>
                                            </div>
                                            <div style="color: #484649; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 16px; word-wrap: break-word">{{detil_part?.partner_Email}}</div>
                                        </div>
                                        <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                                            <div style="width: 24px; height: 24px; position: relative">
                                                <div style="width: 17px; height: 17px; left: 3px; top: 4px; position: absolute;"><img src="../../../../assets/images/tabler_phone.svg"></div>
                                            </div>
                                            <div style="color: #484649; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 16px; word-wrap: break-word">{{detil_part?.partner_Phone}}</div>
                                        </div>
                                        <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                                            <div style="width: 24px; height: 24px; position: relative">
                                                <div style="width: 19.90px; height: 20px; left: 2.05px; top: 2px; position: absolute; "> <img src="../../../../assets/images/ic_outline-whatsapp.svg"></div>
                                            </div>
                                            <div style="color: #484649; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 16px; word-wrap: break-word">+{{detil_part?.partner_WANum}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 px-0">
                        <div class="ptof11 w-100">
                            <div class="ptof12 w-100" style="align-self: stretch; justify-content: flex-start; align-items: flex-end; gap: 24px; display: inline-flex">
                                <div style="flex: 1 1 0; height: 174px; padding-left: 24px; padding-right: 24px; padding-top: 16px; padding-bottom: 16px; background: #F9F9F9; border-radius: 12px; border: 1px #EBEBEB solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: inline-flex">
                                    <div style="align-self: stretch; flex: 1 1 0; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                                        <div style="width: 48px; height: 48px; position: relative">
                                            <div style="width: 48px; height: 48px; left: 0px; top: 0px; position: absolute;   border-radius: 9999px"></div>
                                            <div style="width: 24px; height: 24px; left: 12px; top: 12px; position: absolute">
                                                <div style="width: 44px; height: 18px; left: -7px; top: -7px; position: absolute; font-size: 18px;"><img src="../../../../assets/images/user/Group 68.svg"></div>
                                            </div>
                                        </div>
                                        <div style="flex: 1 1 0; color: #060D15; font-size: 16px; font-family: Poppins; font-weight: 600; line-height: 24px; word-wrap: break-word">Total Earning</div>
                                    </div>
                                    <div style="align-self: stretch; height: 40px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                        <div style="align-self: stretch; color: #1F4068; font-size: 32px; font-family: Poppins; font-weight: 600; line-height: 40px; word-wrap: break-word">{{detil_part?.total_Earning}}</div>
                                    </div>
                                </div>
                                <div style="flex: 1 1 0; height: 174px; padding-left: 24px; padding-right: 24px; padding-top: 16px; padding-bottom: 16px; background: #F9F9F9; border-radius: 12px; border: 1px #EBEBEB solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: inline-flex">
                                    <div style="align-self: stretch; flex: 1 1 0; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                                        <div style="width: 48px; height: 48px; position: relative">
                                            <div style="width: 48px; height: 48px; left: 0px; top: 0px; position: absolute;   border-radius: 9999px"></div>
                                            <div style="width: 24px; height: 24px; left: 12px; top: 12px; position: absolute">
                                                <div style="width: 44px; height: 18px; left: -7px; top: -7px; position: absolute; font-size: 18px;"><img src="../../../../assets/images/user/Group 68.svg"></div>
                                            </div>
                                        </div>
                                        <div style="flex: 1 1 0; color: #060D15; font-size: 16px; font-family: Poppins; font-weight: 600; line-height: 24px; word-wrap: break-word">Month Earning
                                        </div>
                                    </div>
                                    <div style="align-self: stretch; height: 40px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                        <div style="align-self: stretch; color: #1F4068; font-size: 32px; font-family: Poppins; font-weight: 600; line-height: 40px; word-wrap: break-word">{{detil_part?.month_Earning}}</div>
                                    </div>
                                </div>
                                <div style="flex: 1 1 0; height: 174px; padding-left: 24px; padding-right: 24px; padding-top: 16px; padding-bottom: 16px; background: #F9F9F9; border-radius: 12px; border: 1px #EBEBEB solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: inline-flex">
                                    <div style="width: 158px; flex: 1 1 0; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                                        <div style="width: 48px; height: 48px; position: relative">
                                            <div style="width: 48px; height: 48px; left: 0px; top: 0px; position: absolute;  border-radius: 9999px"></div>
                                            <div style="width: 24px; height: 24px; left: 12.33px; top: 12px; position: absolute">
                                                <div style="width: 44px; height: 16px; left: -7px; top: -7px; position: absolute;   "><img src="../../../../assets/images/user/apartments number.svg"></div>
                                            </div>
                                        </div>
                                        <div style="flex: 1 1 0; color: #060D15; font-size: 16px; font-family: Poppins; font-weight: 600; line-height: 24px; word-wrap: break-word">No of apartments
                                        </div>
                                    </div>
                                    <div style="height: 40px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                        <div style="align-self: stretch; color: #1F4068; font-size: 32px; font-family: Poppins; font-weight: 600; line-height: 40px; word-wrap: break-word"> {{detil_part?.no_Apartments}} </div>
                                    </div>
                                </div>
                            </div>
                            <div style="align-self: stretch; height: 635px; padding: 24px; background: #F9F9F9; border-radius: 8px; border: 1px #EBEBEB solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: flex">
                                <div style="align-self: stretch; height: 587px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 32px; display: flex">
                                    <!-- <div style="align-self: stretch; height: 80px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                        <div style="color: #484649; font-size: 16px; font-family: Poppins; font-weight: 600; line-height: 24px; word-wrap: break-word">Skills</div>
                                        <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 16px; display: inline-flex">

                                            <div *ngFor="let skill of detil_part?.Partner_Skills" style="padding-top: 8px; padding-bottom: 12px; padding-left: 30px; padding-right: 30px; background: white; border-radius: 4px; overflow: hidden; border: 1px #CECECE solid; justify-content: center; align-items: center; display: flex">
                                                <div style="color: #605D62; font-size: 16px; font-family: Poppins; font-weight: 500; line-height: 20px; word-wrap: break-word"> {{skill?.skill_Name}}</div>
                                            </div>


                                        </div>
                                    </div> -->
                                    <div style="align-self: stretch; height: 196px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                        <div style="color: #484649; font-size: 16px; font-family: Poppins; font-weight: 600; text-transform: capitalize; line-height: 24px; word-wrap: break-word">bank account Details</div>
                                        <div style="align-self: stretch; height: 156px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 24px; display: flex">
                                            <div style="align-self: stretch; justify-content: flex-start; align-items: center; gap: 24px; display: inline-flex">
                                                <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: inline-flex">
                                                    <div style="align-self: stretch; height: 66px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: flex">
                                                        <div style="color: #605D62; font-size: 14px; font-family: Poppins; font-weight: 600; line-height: 20px; word-wrap: break-word">Bank Name</div>
                                                        <div style="align-self: stretch; height: 40px; padding-left: 14px; padding-right: 14px; padding-top: 10px; padding-bottom: 10px; background: white; box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); border-radius: 8px; overflow: hidden; border: 1px #CECECE solid; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                                                            <div style="flex: 1 1 0; height: 20px; justify-content: flex-start; align-items: center; gap: 8px; display: flex">
                                                                <div style="flex: 1 1 0; color: #313033; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 20px; word-wrap: break-word"> {{detil_part?.bank_Name}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: inline-flex">
                                                    <div style="align-self: stretch; height: 66px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: flex">
                                                        <div style="color: #605D62; font-size: 14px; font-family: Poppins; font-weight: 600; line-height: 20px; word-wrap: break-word">Account Number</div>
                                                        <div style="align-self: stretch; height: 40px; padding-left: 14px; padding-right: 14px; padding-top: 10px; padding-bottom: 10px; background: white; box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); border-radius: 8px; overflow: hidden; border: 1px #CECECE solid; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                                                            <div style="flex: 1 1 0; height: 20px; justify-content: flex-start; align-items: center; gap: 8px; display: flex">
                                                                <div style="flex: 1 1 0; color: #313033; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 20px; word-wrap: break-word">{{detil_part?.account_Num}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="align-self: stretch; height: 66px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: flex">
                                                <div style="align-self: stretch; height: 66px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: flex">
                                                    <div style="color: #605D62; font-size: 14px; font-family: Poppins; font-weight: 600; line-height: 20px; word-wrap: break-word">Swift Number</div>
                                                    <div style="align-self: stretch; height: 40px; padding-left: 14px; padding-right: 14px; padding-top: 10px; padding-bottom: 10px; background: white; box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); border-radius: 8px; overflow: hidden; border: 1px #CECECE solid; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                                                        <div style="flex: 1 1 0; height: 20px; justify-content: flex-start; align-items: center; gap: 8px; display: flex">
                                                            <div style="flex: 1 1 0; color: #313033; font-size: 16px; font-family: Poppins; font-weight: 400; line-height: 20px; word-wrap: break-word">{{detil_part?.swift_Num}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="align-self: stretch; height: 247px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                                        <div style="color: #484649; font-size: 16px; font-family: Poppins; font-weight: 600; text-transform: capitalize; line-height: 24px; word-wrap: break-word">Passport or national ID</div>
                                        <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 24px; display: inline-flex">
                                          <img *ngIf="!detil_part?.partner_Attachments"  style="height: 230px; border-radius: 4px" class="w-50" src="../../../../assets/images/passport.png" />
                                            <img *ngIf="detil_part?.partner_Attachments" style="height: 230px; border-radius: 4px" class="w-50" [src]="detil_part?.partner_Attachments" />
                                            <!-- <img style="flex: 1 1 0; height: 207px; border-radius: 4px" src="https://via.placeholder.com/314x207" /> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div style="align-self: stretch;  background: white; box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06); border-radius: 8px; overflow: hidden; border: 1px #EBEBEB solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">

                    <div style="color: #000000;font-size: 20px;font-weight: 600;" class="p-3">Commission List</div>

                    <div class="card mt-2">

                        <p-table class="table-shopping" [selectionPageOnly]="true" [tableStyle]="{'min-width': '50rem' ,'min-': '50rem','height':' 161px'}" [value]="detil_part?.partner_Requests">
                            <ng-template pTemplate="header" class="text-center">
                                <tr>
                                    <ng-container>


                                        <th class="td-name">
                                            Customer Name

                                        </th>
                                        <th class="td-name">
                                            Apartment Name

                                        </th>

                                        <th class="td-name">
                                            Agency Code

                                        </th>
                                        <!-- <th class="td-name">
                                maintenance cost

                              </th> -->
                                        <th class="td-name">
                                            Date

                                        </th>
                                        <th class="td-name">

                                            Commission
                                        </th>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-issue style="height: 200px !important;">

                                <tr style="cursor: pointer;">


                                    <td>
                                        {{issue?.customer_Name}}
                                    </td>
                                    <td>
                                        {{issue?.apt_Name}}
                                    </td>


                                    <td>
                                        {{issue?.agency_Code}}
                                    </td>
                                    <td>
                                        {{issue?.req_Date| date :'medium'}}
                                    </td>
                                    <td>
                                        {{issue?.req_Commission}}
                                    </td>




                                </tr>

                            </ng-template>


                            <ng-template pTemplate="emptymessage">
                                <div class="container text-center mt-4">
                                    <p class="mb-0">No data found.</p>
                                </div>
                            </ng-template>

                        </p-table>
                        <!-- <p-paginator (onPageChange)="tiggerPageChange($event)" [rows]="pagesize" [totalRecords]="totalRecords"></p-paginator> -->


                    </div>
                </div>
            </div>

        </main>
        <!-- end content page  -->

    <!-- end content page  -->
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}" style=" background-color: rgb(23 22 22 / 50%);;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    margin-top: 150px;">
            <div class="modal-header" style="background-color:#ffffff; border-bottom: none !important;position: relative;">
                <p class="modal-title" style="color: #1C1B1F; font-size: 20px;   font-weight: 600; line-height: 24px; word-wrap: break-word">
                    <span class="form-label gray fw-bold" style="vertical-align: middle; padding-left:8px">
Add New Job
        </span>
                </p>
                <button type="button" style="border:none;background-color: #ffffff;width: 68px;height: 50px;" class="close" aria-label="Close" (click)="onCloseModal2()">
<span aria-hidden="true" style="color: #000000;font-size: 35px;">&times;</span>
</button>



            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="mb-3 col-12 col-md-12">
                        <label for="exampleInputPricepermonth" class="form-label gray fw-bold">Job name</label>
                        <input type="text" [(ngModel)]="Jobname" class="form-control rounded-2 py-2 fs-6" id="exampleInputPricepermonth" />

                    </div>
                    <!-- <div class="mb-3 col-12 col-md-12">
                  <label for="exampleInputSecuritydeposit" class="form-label gray fw-bold">Role discerption</label>
                  <textarea class="border p-3 pt-3 rounded-2 w-100" rows="5" style="resize: inherit"></textarea>

              </div> -->
                </div>

            </div>




            <div class="modal-footer">

                <div class="d-flex justify-content-between">
                    <div class="py-2 px-3 fw-bold"></div>
                    <div class="d-flex justify-content-between">
                        <a (click)="onCloseModal2()" style="    border: 1px solid;" class="backcancel text-decoration-none cursor bg-white rounded-2 py-2 px-3 fw-bold mx-2 xcvdd">
                  Cancel
    </a>
                        <button (click)="PostJob()" class="rounded-2 text-white rounded-2 border-0 py-2 px-4" style="background-color: #1f4068 !important">
                  confirm
    </button>
                    </div>
                </div>

            </div>


        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
